import { WFFields } from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";
export const columnConfig = [
  {
    headerName: " ",
    cellRenderer: "moreMenuCellRenderer",
    field: "iconButton",
    minWidth: 43,
    width: 43,
    pinned: "left",
    lockPosition: true,
    //sortable: false,
    cellClass: "update-client-size-cell-renderer",
  },
  {
    headerName: t.admin_cps_form_title,
    field: WFFields.title,
   // sortable: true,
    width: 300,
    sort: "asc",
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_workflow_type,
    field: WFFields.workflowType,
    width: 200,
   // sortable: true,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_workflow_category,
    field: WFFields.workflowCategory,
   // sortable: true,
    width: 200,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_workflow_code_file,
    field: WFFields.codeFile,
   // sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_workflow_variables,
    field: WFFields.workflowDynamicVars,
   // sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_is_active,
    field: WFFields.isActive,
   // sortable: true,
    width: 200,
    filter: "customFilter",
    filterType: "boolean",
    valueGetter: (params) => {
      if (params.data.wfr_IsActive === true) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
  {
    headerName: t.admin_cps_form_key,
    field: WFFields.key,
   //sortable: false,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
];
