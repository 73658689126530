export const columnConfig = [
  {
    headerName: 'Region',
    field: 'name',
   // sortable: true,
    width: 320,
    sort: "asc",
    filter: "customFilter",
    lockPosition: true
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 573,
    cellRenderer: 'dataHostingLocationDescriptionCellRender',
    cellClass: "description-cell-renderer",
    wrapText: true,
    lockPosition: true
  },
]