import { WFFields } from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";
export const columnConfig = [
  {
    headerName: " ",
    cellRenderer: "moreMenuCellRenderer",
    field: "iconButton",
    minWidth: 43,
    width: 43,
    pinned: "left",
    lockPosition: true,
    sortable: false,
    cellClass: "update-client-size-cell-renderer",
  },
  {
    headerName: t.admin_cps_form_title,
    field: WFFields.title,
   // sortable: true,
    width: 300,
    sort: "asc",
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_communication_workflow,
    field: WFFields.workflowLookupTitle,
    width: 300,
   // sortable: true,
    valueGetter: "data.wfr_WorkflowLookup.title",
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_schedule_type,
    field: WFFields.scheduleType,
   // sortable: true,
    width: 200,
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_no_of_repeats,
    field: WFFields.numberOfRepeats,
   // sortable: true,
    width: 200,
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_is_running,
    field: WFFields.isRunning,
   // sortable: true,
    width: 200,
    filter: "customFilter",
    filterType: "boolean",
    valueGetter: (params) => {
      if (params.data.wfr_IsRunning === true) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
  {
    headerName: t.admin_cps_form_is_active,
    field: WFFields.isActive,
   // sortable: true,
    width: 200,
    filter: "customFilter",
    filterType: "boolean",
    valueGetter: (params) => {
      if (params.data.wfr_IsActive === true) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
  {
    headerName: t.admin_cps_form_last_run_time,
    field: WFFields.lastRunTime,
   // sortable: true,
    width: 300,
    wrapText: true,
    cellRenderer: "cellRenderDateRows",
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_no_of_executions,
    field: WFFields.numberOfRuns,
    //sortable: true,
    width: 200,
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_parameters,
    field: WFFields.parameters,
   // sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_recurring_schedule_end_type,
    field: WFFields.recurringScheduleEndType,
   // sortable: true,
    width: 200,
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_repeat_frequency,
    field: WFFields.repeatFrequency,
   // sortable: true,
    width: 200,
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_repeat_interval,
    field: WFFields.repeatInterval,
    //sortable: true,
    width: 200,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_schedule_end_date,
    field: WFFields.scheduleEndDate,
   // sortable: true,
    width: 300,
    wrapText: true,
    cellRenderer: "cellRenderDateRows",
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_schedule_start_date,
    field: WFFields.scheduleStartDate,
   // sortable: true,
    width: 300,
    wrapText: true,
    cellRenderer: "cellRenderDateRows",
    filter: "customFilter",
    filterType: "none",
  },
];
