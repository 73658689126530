import React from "react";
import "./index.scss";
const Crash = () => <div
  className={"crash-boundary-info"}>
  <div>The application experienced an error that
    caused it to crash.
  </div>
  <div>Please reload the page or contact support if
    you continue to experience errors on this page.
  </div>
</div>;

export class CrashBoundary extends React.Component<any, { hasCrash: boolean }> {
  state = {
    hasCrash: false
  }
  unlisten?: () => void;

  static getDerivedStateFromError() {
    return {hasCrash: true};
  }

  componentDidMount() {
    this.unlisten = this.props.history?.listen(() => {
      this.setState({hasCrash: false});
    });
  }

  componentWillUnmount() {
    this.unlisten && this.unlisten();
  }

  render() {
    return (
      this.state.hasCrash? <div style={{height: "100%"}}> <Crash/></div>:
          this.props.children
    );
  }
}