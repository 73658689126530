import * as type from "../action-types";

type load = { id: string }

export const getApps = (payload?: load) => ({
    type: type.GET_ALL_APPS
});

export const getBundles = (payload?: load) => ({
    type: type.GET_ALL_BUNDLES
});

export const getAppCategories = (payload?: load) => ({
    type: type.GET_ALL_APP_CATEGORIES
});

export const getAppPlatforms = (payload?: load) => ({
    type: type.GET_ALL_APP_PLATFORMS
});

export const getAppPlatformRoles = (payload: load) => ({
    type: type.GET_ALL_PLATFORM_ROLES_REQUEST,
    payload
});

export const getAppPlatformRolesSuccess = (payload: load) => ({
    type: type.GET_ALL_PLATFORM_ROLES_SUCCESS,
    payload,
});

export const getAppPlatformRolesFailure = () => ({
    type: type.GET_ALL_PLATFORM_ROLES_FAILURE,
});

export const getSppPlatformRoles = (payload: load) => ({
    type: type.GET_SSP_ROLES_REQUEST,
    payload
});

export const getSppPlatformRolesSuccess = (payload: any) => ({
    type: type.GET_SSP_ROLES_SUCCESS,
    payload,
});

export const getSppPlatformRolesFailure = () => ({
    type: type.GET_SSP_ROLES_FAILURE,
});

export const getProjectIcons = () => ({
    type: type.GET_PROJECT_ICONS_REQUEST
});

export const getProjectIconsSuccess = (payload: any) => ({
    type: type.GET_PROJECT_ICONS_SUCCESS,
    payload,
});

export const getProjectIconsFailure = () => ({
    type: type.GET_PROJECT_ICONS_FAILURE,
});

export const getServiceLines = (payload: load) => ({
    type: type.GET_SERVICE_LINES_REQUEST,
    payload
});

export const getServiceLinesSuccess = (payload: any) => ({
    type: type.GET_SERVICE_LINES_SUCCESS,
    payload,
});

export const getServiceLinesFailure = () => ({
    type: type.GET_SERVICE_LINES_FAILURE,
});

export const getSubServiceLines = (payload: load) => ({
    type: type.GET_SUB_SERVICE_LINES_REQUEST,
    payload
});

export const getSubServiceLinesSuccess = (payload: any) => ({
    type: type.GET_SUB_SERVICE_LINES_SUCCESS,
    payload,
});

export const getSubServiceLinesFailure = () => ({
    type: type.GET_SUB_SERVICE_LINES_FAILURE,
});

export const getLookupData = (payload: load) => ({
    type: type.GET_LOOK_UP_DATA_REQUEST,
    payload
});

export const getLookupDataSuccess = (payload: any) => ({
    type: type.GET_LOOK_UP_DATA_SUCCESS,
    payload,
});

export const getLookupDataFailure = () => ({
    type: type.GET_LOOK_UP_DATA_FAILURE,
});