import { WFFields, CommunicationFields } from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";
export const columnConfig = [
  {
    headerName: " ",
    cellRenderer: "moreMenuCellRenderer",
    field: "iconButton",
    minWidth: 43,
    width: 43,
    pinned: "left",
    lockPosition: true,
    //sortable: false,
    cellClass: "update-client-size-cell-renderer",
  },
  {
    headerName: t.admin_cps_form_title,
    field: WFFields.title,
   // sortable: true,
    width: 300,
    sort: "asc",
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_workflow_category,
    field: CommunicationFields.communicationTemplateCategoryTitle,
  //  sortable: true,
    width: 200,
    wrapText: true,
    valueGetter: "data.communicationTemplateCategory.title",
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_communication_template_description,
    field: CommunicationFields.communicationTemplateDescription,
   // sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_communication_send_from_mail,
    field: CommunicationFields.sendFromEmail,
    width: 200,
  },
  {
    headerName: t.admin_cps_form_communication_send_to_mail,
    field: CommunicationFields.sendToEmail,
    width: 200,
  },
  {
    headerName: t.admin_cps_form_communication_subject,
    field: CommunicationFields.communicationEmailSubject,
  //  sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_communication_message_body,
    field: CommunicationFields.communicationEmailBody,
  //  sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_communication_workflow_name,
    field: CommunicationFields.cwfiworkflowName,
   // sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_communication_workflow_parameters,
    field: CommunicationFields.cwfiworkflowParameters,
   // sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_communication_workflow,
    field: CommunicationFields.wfr_WorkflowLookupTitle,
   // sortable: true,
    width: 300,
    wrapText: true,
    valueGetter: "data.wfr_WorkflowLookup.title",
    filter: "customFilter",
  },
];
