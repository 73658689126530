import React, { FC, ReactNode } from "react";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Dialog, Typography } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { AppButton, AppIconButton } from "..";
import themeConfig from "../config";
import "./appModal.scss";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: themeConfig.textButtonColor,
    },
    title: {
      fontFamily: themeConfig.fontFamilyBold
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" className={classes.title}>{children}</Typography>
      {onClose ? (
        <AppIconButton aria-label="close" className={classes.closeButton} onClick={() => onClose()}>
          <CloseIcon />
        </AppIconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

type IProps = {
  showModal: boolean;
  onModalClose?: () => void;
  fullWidth?: boolean;
  onConfirm?: () => void;
  children: ReactNode;
  title?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  disabledValue?: boolean;
  confirmBtnClass?: string;
  showCloseBtnToModal?: boolean;
  modalClass?: string;
  hideConfirmButton?: boolean;
  additionalBtns?: ReactNode;
  footerAdditionalContent?: ReactNode;
  size?: any;
  onModalScroll?: Function,
  showCloseModelIcon?:boolean,
  cancelBtnVariant?: any;
  hideFooterSection?: boolean;
  showDividers?: boolean
}

const AppModal: FC<IProps> = ({
  showModal,
  fullWidth,
  onModalClose,
  onConfirm,
  children,
  title = "",
  confirmBtnText,
  cancelBtnText,
  disabledValue,
  confirmBtnClass,
  modalClass,
  hideConfirmButton,
  additionalBtns,
  footerAdditionalContent,
  size,
  showCloseBtnToModal = true,
  onModalScroll,
  showCloseModelIcon=true,
  cancelBtnVariant = 'contained',
  hideFooterSection,
  showDividers = true
}) => {

  const handleOnModalClose = () => {
    if (onModalClose && showCloseBtnToModal) {
      onModalClose()
    }
  }

  const handleModalScroll = (e) => {
    if(!e.target.classList.contains('MuiDialogContent-root')) {
      return
    }
    onModalScroll && onModalScroll(e.target.scrollTop, e.target.scrollHeight, e.target.offsetHeight)
  }

  const onCloseModal = (event, reason) => {
      if (reason !== 'backdropClick' && onModalClose) {
        onModalClose()
      }
  }
    
  return (
    <Dialog
      fullWidth={fullWidth??true}
      maxWidth={size || "sm"}
      open={showModal}
      onClose={onCloseModal}
      className={`app-modal ${modalClass || ''} ${(title && 'default-close') || 'custom-close'}`}
      disableEscapeKeyDown={true}
      onScroll={handleModalScroll}
    >
      {
      (title &&
        <DialogTitle
          id="app-dialog-title"
          onClose={(showCloseBtnToModal && onModalClose) || null}
        >{title}</DialogTitle>
      ) || 
      
      ( showCloseModelIcon &&
          <div className="modal-close">
            <AppIconButton aria-label="close" className="modal-close-icon" onClick={() => handleOnModalClose()}>
             <CloseIcon />
            </AppIconButton>
          </div>
      )
}
      <DialogContent dividers={showDividers}>
        {children}
      </DialogContent>
      
      {!hideFooterSection && (confirmBtnText || cancelBtnText || additionalBtns || footerAdditionalContent) && <DialogActions>
        <div className="footer-container">
          {footerAdditionalContent || null}
          <div className="footer-btns">
            {onConfirm && !hideConfirmButton && (
              <AppButton
                disabled={disabledValue}
                type="button"
                className={confirmBtnClass || ''}
                onClick={onConfirm}
              >
                {confirmBtnText}
              </AppButton>
            )}
            {onModalClose && (
              <AppButton variant={cancelBtnVariant} color="secondary" type="button" onClick={onModalClose}>
                {cancelBtnText}
              </AppButton>
            )}
            {(additionalBtns && (<div className="additional-btns">{additionalBtns}</div>)) || null}
          </div>
        </div>
      </DialogActions>}
    </Dialog>
  );
};

export default AppModal;
