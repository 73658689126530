import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import {
  deleteAdminProjectsAction,
  exportProjectList,
  flushAdminProjectAction,
  flushExportProjectListStatus,
  flushUpdatedAdminProjectAction,
  getAdminProjectsAction,
  reactivateAdminProjectsAction,
  searchAdminProjectAction,
  softDeleteAdminProjectsAction,
  updateAdminProjectAction,
  setProjectAccessRestriction,
} from "../../store/actions/admin-projects-actions";
import { getProjectCategoriesAction } from "../../store/actions/project-categories-actions";
import { updatefilterState } from "../../store/actions/list-actions";
import { ISSPPlatformRole } from "../../models/IRoles";
import { IProjectDetails } from "../../models/IPlatformCustomizationInfo";
import {
  IExportProjectList,
  IExportStatus,
} from "../../models/IExportProjectList";
import { showPreviewModal } from "../../store/actions/preview-video-actions";
import {
  DEFAULT_SORT,
  IS_CUSTOMIZED,
  MORE_MENU_ACTION_NAME,
} from "./adminProjectsConstants";
import {
  EProjectStatuses,
  ProjectModel,
  ProjectRowDataModel,
} from "./adminProjectsModel";
import { AppClass } from "../../components/appCard/models/appClass";
import {
  getDefaultPaginationModel,
  getPaginationModel,
  IPagination,
} from "../../models/IPagination";
import { Fields, getColumnConfig } from "./columnConfig";
import { IAccessRight } from "../../models/user";
import AppNotification from "../../components/appNotification/appNotification";
import AdminProjectsAppsModal from "../../components/adminProjectsAppsModal/adminProjectsAppsModal";
import AdminProjectsLogsModal from "../../components/adminProjectsLogsModal/adminProjectsLogsModal";
import AdminProjectsIsCustomizedModal from "../../components/adminProjectsIsCustomizedModal/adminProjectsIsCustomizedModal";
import t from "../../localization/en/translation.json";
import moment from "moment";
import {
  fileDownLoadUtility,
  getAdminConsoleAccess,
  isProjectAdmin,
  timeZoneAbbreviated,
  isEyUser,
} from "../../utils/helper-utility";
import List from "../../components/editableGrid/list/list";
import { AppButton, AppIcon, AppTooltip } from "../../theme";
import { check, cross, search, caretRight } from "../../theme/icons";
import { MoreMenuOption } from "../../components/moreMenu/moreMenuOptions";
import MoreMenu from "../../components/moreMenu/moreMenu";
import _ from "lodash";
import "./adminProjects.scss";
import CloseProjectPopup from "../../components/CloseProjectPopup/CloseProjectPopup";
import { EProjectCategory } from "../createProject/formStateModel";
import { EProjectStatuses as EProjectStatus } from "../../utils/Constants";
import AppModal from "../../theme/appModal/appModal";
import { getProjectStatusAction } from "../../store/actions/project-status-actions";
import { lookupModel } from "../createProject/lookupModel";
import { CrashBoundary } from "../../components/CrashBoundary";
import { AdditionalPaceIDsPillEditor } from "./additionalPaceIDsPillEditor";
import { TruncateForToolTip } from "./truncateForToolTip";
import { EProjectCategoryKey } from "../createProject/formStateModel";

type IProps = RouteComponentProps<any> & {
  getAdminProjectsAction: Function;
  softDeleteProjectAction: Function;
  reactivateProjectAction: Function;
  getProjectStatus: Function;
  projectStatus: lookupModel[];
  getProjectCategories: Function;
  projectCategories: lookupModel[];
  projects: ProjectModel[];
  deleteAdminProjectsAction: Function;
  deleteProject: { success?: boolean; error: boolean } | undefined;
  softDeleteProject: { success?: boolean; error?: boolean } | undefined;
  reactivateProject: { success?: boolean; error?: boolean } | undefined;
  flushAdminProjectAction: Function;
  showPreviewModal: Function;
  pagination: IPagination;
  searchLoader: boolean;
  fetchSearchData: Function;
  searchAdminProject: ProjectModel[];
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
  updatefilterState: Function;
  updateProject: Function;
  updatedProjectData: {
    data?: { id: string };
    success?: boolean;
    error?: boolean;
  };
  setProjectAccessRestrictionStatus: Function;
  flushUpdatedProjectData: Function;
  isCustomizationInfoUpdated: boolean;
  exportProjectList: Function;
  exportedProjectList: IExportProjectList;
  exportStatus: IExportStatus;
  flushExportProjectListStatus: Function;
};

interface IState {
  showDeleteModal: boolean;
  searchString: string;
  selectedProjectDetails: {
    selectedProjectId: string;
    selectedProjectName: string;
    selectedProjectCreatedBy: string;
    selectedProjectCategoryId: string;
    isSelectedProjectInDraft?: boolean;
    isPricingIncluded?: boolean;
  };
  selectedProjectAppDetails: AppClass | {};
  renderTable: boolean;
  showNotification: boolean;
  showAppsModal: boolean;
  showLogsModal: boolean;
  isProjectSearched: boolean;
  searchQuery: ProjectModel[];
  paginationPayload: IPagination;
  rowData: ProjectRowDataModel[];
  showIsCustomizedModal: boolean;
  isCustomizedData: IProjectDetails | null;
  showSoftCloseAndReactivateActionModal: boolean;
  selectedProject: ProjectModel | null;
}

class AdminProjects extends Component<IProps, IState> {
  state: Readonly<IState> = {
    showDeleteModal: false,
    selectedProject: null,
    searchString: "",
    showSoftCloseAndReactivateActionModal: false,
    selectedProjectDetails: {
      selectedProjectId: "",
      selectedProjectName: "",
      selectedProjectCreatedBy: "",
      selectedProjectCategoryId: "",
      isSelectedProjectInDraft: undefined,
      isPricingIncluded: undefined,
    },
    selectedProjectAppDetails: {},
    renderTable: false,
    showNotification: false,
    showAppsModal: false,
    showLogsModal: false,
    showIsCustomizedModal: false,
    isProjectSearched: false,
    searchQuery: [],
    paginationPayload: getPaginationModel({
      ...getDefaultPaginationModel(),
      sortDirection: DEFAULT_SORT.sort,
      sortColumn: DEFAULT_SORT.colId,
      totalRecords: 0,
      filters: [],
    }),
    rowData: [],
    isCustomizedData: null,
  };
  count = 0;
  notificationVariant = "";
  notificationMessage = "";
  gridApi;
  typeahead;

  componentDidMount() {
    this.refreshGrid();
    this.updateFilterState();
    !this.props.projectStatus?.length && this.props.getProjectStatus();
    !this.props.projectCategories?.length && this.props.getProjectCategories();
  }

  updateFilterState = () => {
    const {
      filters = [],
      sortDirection,
      sortColumn,
    } = this.state.paginationPayload;
    const isDefaultSort =
      sortDirection === DEFAULT_SORT.sort && sortColumn === DEFAULT_SORT.colId;
    const isClearAllDisabled = isDefaultSort && !filters.length;

    this.props.updatefilterState({
      filters,
      sortDirection,
      sortColumn,
      isClearAllDisabled,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      projects,
      deleteProject,
      softDeleteProject,
      reactivateProject,
      projectStatus,
      updatedProjectData,
      flushUpdatedProjectData,
      isCustomizationInfoUpdated,
      exportedProjectList,
      exportStatus,
      searchAdminProject,
      flushExportProjectListStatus,
    } = this.props;
    const { paginationPayload, searchQuery, rowData } = this.state;
    ////// TODO - require full refactoring of admin projects pages to handle notification universally
    if (deleteProject?.success) {
      this.manageDeleteNotification(
        true,
        t.admin_portal_delete_project_success
      );
      this.refreshGrid();
    } else if (deleteProject?.error) {
      this.manageDeleteNotification(false, t.admin_portal_delete_project_error);
    }

    if (softDeleteProject?.success) {
      this.manageDeleteNotification(
        true,
        t.admin_portal_soft_delete_project_success
      );
      this.refreshGrid();
    } else if (softDeleteProject?.error) {
      this.manageDeleteNotification(
        false,
        t.admin_portal_soft_delete_project_error
      );
    }

    if (reactivateProject?.success) {
      this.manageDeleteNotification(
        true,
        t.admin_portal_reactivate_project_success
      );
      this.refreshGrid();
    } else if (reactivateProject?.error) {
      this.manageDeleteNotification(
        false,
        t.admin_portal_reactivate_project_error
      );
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (
      (projects && projects !== prevProps.projects) ||
      projectStatus?.length !== prevProps.projectStatus?.length
    ) {
      if (rowData.length === 1 && searchQuery.length && projects.length) {
        // @ts-ignore
        const searchQueryProject = projects.find(
          (project) => project.name === searchQuery[0].name
        );
        searchQueryProject && this.generateProjectRowData([searchQueryProject]);
      } else {
        this.generateProjectRowData(
          (searchQuery.length && searchQuery) || projects
        );
      }
    }
    if (
      searchQuery.length &&
      !_.isEqual(searchAdminProject, prevProps.searchAdminProject)
    ) {
      const updatedSearchData = searchAdminProject.filter(
        (project) => project.id === searchQuery[0].id
      );
      this.setState({ searchQuery: updatedSearchData });
      this.generateProjectRowData(updatedSearchData);
    }

    if (prevState.paginationPayload !== paginationPayload) {
      this.refreshGrid();
      this.updateFilterState();
    }
    if (updatedProjectData?.success) {
      flushUpdatedProjectData();
      this.refreshGrid();
    } else if (updatedProjectData?.error) {
      flushUpdatedProjectData();
      this.generateProjectRowData(projects);
    }

    if (
      prevProps.isCustomizationInfoUpdated !== isCustomizationInfoUpdated &&
      isCustomizationInfoUpdated
    ) {
      this.refreshGrid();
    }

    if (prevProps.exportedProjectList !== exportedProjectList) {
      exportedProjectList.fileContents &&
        fileDownLoadUtility(exportedProjectList);
    }

    if (
      prevProps.exportStatus !== exportStatus &&
      (exportStatus.success || exportStatus.error)
    ) {
      setTimeout(flushExportProjectListStatus, 3000);
    }
  }

  refreshGrid = () => {
    const { getAdminProjectsAction } = this.props;
    const { paginationPayload } = this.state;
    getAdminProjectsAction(paginationPayload);
  };

  manageDeleteNotification = (success: boolean, message: string) => {
    this.notificationVariant = success ? "success" : "error";
    this.notificationMessage = message;
    this.props.flushAdminProjectAction();
    this.setState({ showNotification: true });
    setTimeout(() => {
      if (this.state.showNotification) {
        this.setState({ showNotification: false });
      }
    }, 3000);
  };

  getProjectName = (project) => {
    if (project.name) {
      if (project.curentContextUserProjectAccess) {
        return (
          <AppButton
            variant="text"
            onClick={() => this.onProjectNameClick(project)}
          >
            {project.name}
          </AppButton>
        );
      } else {
        return <span>{project.name}</span>;
      }
    } else {
      return "-";
    }
  };

  getProjectApps = (project) => {
    const appsText =
      project.appDetails?.length > 1
        ? t.admin_portal_project_apps_in
        : t.admin_portal_project_app_in;
    const appPlatformsText =
      project.appPlatformDetails?.length > 1
        ? t.admin_portal_project_platforms
        : t.admin_portal_project_platform;

    if (!(project.appDetails || []).length) {
      return;
    }

    return `${project.appDetails.length} ${appsText} ${project.appPlatformDetails.length}  ${appPlatformsText}`;
  };

  getProjectNoOfUsers = (project) => {
    if (project.noOfProjectUsers) {
      if (project.curentContextUserProjectAccess) {
        return (
          <AppButton
            variant="text"
            onClick={() => this.onNoOfUsersClick(project)}
          >
            {project.noOfProjectUsers}
          </AppButton>
        );
      } else {
        return <span>{project.noOfProjectUsers}</span>;
      }
    } else {
      return "-";
    }
  };

  getProjectStatus = (project) =>
    project.status ? (
      <span
        className={`status-pill 
        ${
          project.status.toLowerCase() === EProjectStatuses.PND &&
          "pending-state"
        }
        ${
          project.status.toLowerCase() === EProjectStatuses.DRFT &&
          "draft-state"
        }
        ${
          (project.status.toLowerCase() === EProjectStatuses.ACTV ||
            project.status.toLowerCase() === EProjectStatuses.RDY) &&
          "ready-state"
        }
        ${
          project.status.toLowerCase() === EProjectStatuses.EROR &&
          "error-state"
        }
        ${
          project.status.toLowerCase() === EProjectStatuses.CLSD &&
          project.deleted !== true &&
          "softdelete-state"
        }
        `}
      >
        {project.status.toLowerCase() === EProjectStatuses.CLSD &&
        project.deleted !== true
          ? t.admin_portal_status_soft_delete
          : project.status}
      </span>
    ) : (
      "-"
    );

  getProjectCreator = (project) =>
    project.createdBy ? (
      <a className="mail" href={`mailto:${project.createdBy}`}>
        {project.createdBy}
      </a>
    ) : (
      "-"
    );

  getPpeddApprover = (project) =>
    project.ppeddApprover ? (
      <a className="mail" href={`mailto:${project.ppeddApprover}`}>
        {project.ppeddApprover}
      </a>
    ) : (
      "-"
    );

  getCloseProjectContent = (hasDoNotDelete) => {
    return hasDoNotDelete ? (
      <span className="tooltip-no-delete">
        <AppTooltip placement="left" title={t.admin_portal_project_dont_delete}>
          <span className="do-not-delete">{t.home_project_card_menu_mark}</span>
        </AppTooltip>
      </span>
    ) : (
      <span>{t.home_project_card_menu_mark}</span>
    );
  };

  isStandardDemoProject = (categoryId) => {
    const { projectCategories } = this.props;
    const projectCategory = projectCategories.find(
      (item) => item.id === categoryId
    );

    return projectCategory?.key === EProjectCategoryKey.STDDEMO;
  };

  generateProjectRowData = (projects) => {
    const localTimeZoneAbbr = timeZoneAbbreviated();
    const rowData = projects.map((project, key) => {
      const projectNameVal = this.getProjectName(project);
      const appsColVal = this.getProjectApps(project);
      const noOfUsersVal = this.getProjectNoOfUsers(project);
      const statusVal = this.getProjectStatus(project);
      const hasDoNotDelete = project.description?.includes("#donotdelete");
      const createdByVal = this.getProjectCreator(project);
      const ppeddApprover = this.getPpeddApprover(project);
      const moreMenuContent = this.renderMoreMenu(project, hasDoNotDelete);

      return {
        id: project.id,
        iconButton: moreMenuContent,
        name: projectNameVal,
        clientName: project.clientName || "-",
        projectFriendlyId: project.projectFriendlyId || "-",
        description: (
          <span
            key={key}
            title={project.description || "-"}
            className={`${hasDoNotDelete && "do-not-delete-desc"}`}
          >
            {project.description || "-"}
          </span>
        ),
        apps: appsColVal ? (
          <AppButton key={key} onClick={() => this.onAppsClick(project)} variant="text">
            {appsColVal}
          </AppButton>
        ) : (
          "-"
        ),
        projectCategoryName: project.projectCategoryName || "-",
        projectCategoryId: project.projectCategoryId,
        projectTypeName: project.projectTypeName || "-",
        bundleName: project.bundleName || "-",
        paceId: project.paceId || "-",
        serviceLine: project.serviceLine || "-",
        additionalPaceIds: (
          <TruncateForToolTip
            columnText={project.additionalPaceIds}
            nullOrEmptyText={"-"}
          />
        ),
        raw_additionalPaceIds: project.additionalPaceIds,
        regionName: project.geoLocation?.region?.name || "-",
        locationName: project.locationName || "-",
        noOfUsers: noOfUsersVal,
        createdBy: createdByVal,
        raw_createdBy: project.createdBy,
        ppeddApprover: ppeddApprover,
        raw_ppeddApprover: project.ppeddApprover,
        createdOn: `${moment(project.createdOn).format(
          "MMM D, YYYY h:mm:ss a"
        )} ${localTimeZoneAbbr}`,
        status: statusVal,
        raw_status: project.status?.toLowerCase(),
        deleted: project.deleted,
        softCloseDate: project.softCloseDate,
        softClosedBy: project.softClosedBy,
        expirationDate: this.isStandardDemoProject(project.projectCategoryId)
          ? "N/A"
          : project.expirationDate
          ? `${moment(project.expirationDate).format(
              "MMM D, YYYY h:mm:ss a"
            )} ${localTimeZoneAbbr}`
          : "",
        isCustomized: this.renderIsCustomizedCell(
          project.isCustomized,
          project.customizationInfo,
          project.name,
          project.id
        ),
      };
    });
    this.setState({ renderTable: true, rowData });
  };

  onDeleteClick = (selectedProject: ProjectModel) => {
    this.setState({
      showDeleteModal: true,
      selectedProjectDetails: {
        selectedProjectId: selectedProject.id,
        selectedProjectName: selectedProject.name,
        selectedProjectCreatedBy: selectedProject.createdBy || "-",
        selectedProjectCategoryId: selectedProject.projectCategoryId,
        isSelectedProjectInDraft:
          selectedProject.status?.toLowerCase() === EProjectStatuses.DRFT,
        isPricingIncluded: selectedProject.isPricingIncluded,
      },
    });
  };

  onProjectNameClick = (selectedProject: ProjectModel) => {
    window.open(
      `${window.location.origin}/project/${selectedProject.id}`,
      "_blank"
    );
  };

  onNoOfUsersClick = (selectedProject: ProjectModel) => {
    window.open(
      `${window.location.origin}/project/${selectedProject.id}/manage-users`,
      "_blank"
    );
  };

  onAppsClick = (selectedProject: ProjectModel) => {
    this.setState({
      showAppsModal: true,
      selectedProjectAppDetails: selectedProject.appDetails,
      selectedProjectDetails: {
        selectedProjectId: selectedProject.id,
        selectedProjectName: selectedProject.name,
        selectedProjectCreatedBy: selectedProject.createdBy || "-",
        selectedProjectCategoryId: selectedProject.projectCategoryId,
        isSelectedProjectInDraft: selectedProject.status === "Draft",
        isPricingIncluded: selectedProject.isPricingIncluded,
      },
    });
  };

  onAppsModalClose = () => {
    this.setState({ showAppsModal: false });
  };

  onViewLogClick = (selectedProject: ProjectModel) => {
    this.setState({
      showLogsModal: true,
      selectedProjectDetails: {
        selectedProjectId: selectedProject.id,
        selectedProjectName: selectedProject.name,
        selectedProjectCreatedBy: selectedProject.createdBy || "-",
        selectedProjectCategoryId: selectedProject.projectCategoryId,
        isSelectedProjectInDraft: selectedProject.status === "Draft",
        isPricingIncluded: selectedProject.isPricingIncluded,
      },
    });
  };

  handleLogsModalClose = () => {
    this.setState({ showLogsModal: false });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  closeNotification = () => {
    this.setState({ showNotification: false });
  };

  handleGridReady = ({ api }) => {
    this.gridApi = api;
  };

  handlePagination = (paginationParams) => {
    const { paginationPayload } = this.state;
    const updatedPaginationPayload = {
      ...paginationPayload,
      ...paginationParams,
    };

    this.setState({ paginationPayload: updatedPaginationPayload });
  };

  handlePageNumberChange = (pageNumber) => {
    this.handlePagination({ pageNumber });
  };

  handlePageSizeChange = (pageSize) => {
    this.handlePagination({ pageSize, pageNumber: 1 });
  };

  handleSearchChange = (query) => {
    this.setState({ searchString: query });
    this.props.fetchSearchData(query);
  };

  handleSelectValue = (selectedArray) => {
    this.setState(
      {
        ...this.state,
        searchQuery: selectedArray,
      },
      () => {
        if (selectedArray.length) {
          this.setState({ isProjectSearched: true });
          this.generateProjectRowData(selectedArray);
        } else {
          this.setState({ isProjectSearched: false });
          this.generateProjectRowData(this.props.projects);
        }
      }
    );
  };

  renderMenuItem = (option, props) => {
    return (
      <div className="async-dropdown">
        <div className="async-dropdown-name">{option.name}</div>
        <div>{option.clientName}</div>
        <div>{option.createdBy}</div>
      </div>
    );
  };

  getUserAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";
    return getAdminConsoleAccess(portalRole, sspRoles);
  };

  getUserProjectAdminAccess = (projectId: string) => {
    const { accessRight, sspRoles } = this.props;
    return isProjectAdmin(projectId || "", accessRight, sspRoles);
  };

  handleSorting = (
    sortColumn = DEFAULT_SORT.colId,
    sortDirection = DEFAULT_SORT.sort
  ) => {
    const { paginationPayload } = this.state;

    if (
      paginationPayload.sortColumn === sortColumn &&
      paginationPayload.sortDirection === sortDirection
    ) {
      return;
    }

    const updatedPaginationPayload = {
      ...paginationPayload,
      sortColumn,
      sortDirection,
    };
    this.setState({ paginationPayload: updatedPaginationPayload });
  };

  handleFilterChange = (
    filters,
    sortColumn = DEFAULT_SORT.colId,
    sortDirection = DEFAULT_SORT.sort
  ) => {
    const { paginationPayload } = this.state;
    const updatedPayload = {
      ...paginationPayload,
      filters,
      sortColumn,
      sortDirection,
      pageNumber: 1,
    };

    this.setState({ paginationPayload: updatedPayload });
  };

  onExpiryDateChange = (e) => {
    const payload = {
      id: e.projectId,
      expirationDate: moment(e.value).endOf("day").format("x"),
    };
    this.props.updateProject(payload);
  };

  getCellClass = ({ colDef, rowIndex }) => {
    const { rowData } = this.state;
    const cellBuffer = 2;

    if (rowIndex < cellBuffer || colDef.field !== "expirationDate") {
      return "";
    }

    return rowData.length - cellBuffer <= rowIndex
      ? "bottom-expiration-cell"
      : "";
  };

  getIsCustomizedTooltip = (details) => {
    const customizedPlatforms = details.filter((d) => d.isCustomized);

    return customizedPlatforms.map((cp, key) => (
      <div key={key}>{`${cp.appPlatformName} customized by ${cp.customizedBy}`}</div>
    ));
  };

  handleIsCustomizedCellClick = (customizationInfo, name, id) => {
    const isCustomizedData = {
      name,
      id,
      customizationInfo: _.cloneDeep(customizationInfo),
    };
    this.setState({ isCustomizedData, showIsCustomizedModal: true });
  };

  handleExportProjectList = () => {
    const { exportProjectList } = this.props;
    const payload = timeZoneAbbreviated(true);

    exportProjectList(payload);
  };

  renderIsCustomizedCell = (value, customizationInfo, name, id) => {
    if (!value) {
      const cellValue =
        value === false ? IS_CUSTOMIZED.no : IS_CUSTOMIZED.default;

      return (
        <AppButton
          onClick={() =>
            this.handleIsCustomizedCellClick(customizationInfo, name, id)
          }
          variant="text"
        >
          {cellValue}
        </AppButton>
      );
    }

    return (
      <AppTooltip
        placement="top"
        title={this.getIsCustomizedTooltip(customizationInfo)}
      >
        <AppButton
          onClick={() =>
            this.handleIsCustomizedCellClick(customizationInfo, name, id)
          }
          variant="text"
        >
          {IS_CUSTOMIZED.yes}
        </AppButton>
      </AppTooltip>
    );
  };

  handleMoreMenuOptionClick = (project: ProjectModel, actionName: string) => {
    this.setState({ selectedProject: project });
    if (actionName === MORE_MENU_ACTION_NAME.VIEWLOG) {
      this.onViewLogClick(project);
    } else if (actionName === MORE_MENU_ACTION_NAME.CLOSEPROJECT) {
      this.onDeleteClick(project);
    } else if (
      actionName === MORE_MENU_ACTION_NAME.SOFT_CLOSE ||
      actionName === MORE_MENU_ACTION_NAME.REACTIVATE
    ) {
      this.setState({ showSoftCloseAndReactivateActionModal: true });
    } else if (actionName === MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONALL) {
      this.setAccessRestrictions(
        MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONALL,
        project.id
      );
    } else if (actionName === MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONNONE) {
      this.setAccessRestrictions(
        MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONNONE,
        project.id
      );
    } else if (
      actionName === MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONPROJECTADMIN
    ) {
      this.setAccessRestrictions(
        MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONPROJECTADMIN,
        project.id
      );
    }
  };

  setAccessRestrictions = (actionName: string, projectId: string) => {
    const { paginationPayload, isProjectSearched, searchString } = this.state;
    const payload = {
      accessRestrictionKey: actionName,
      id: projectId,
      paginationPayload,
      isProjectSearched: isProjectSearched,
      searchString: searchString,
    };
    this.props.setProjectAccessRestrictionStatus(payload, paginationPayload);
  };

  renderMoreMenu = (project, hasDoNotDelete) => {
    const { accessRight } = this.props;
    const adminConsoleAccess = this.getUserAdminConsoleAccess();
    const userProjectAdminAccess = this.getUserProjectAdminAccess(project.id);
    const isEYUser = isEyUser(accessRight);
    const showCloseButton =
      process.env.REACT_APP_CLOSE_PROJ_ADM_SWITCH?.toUpperCase() === "ON";

    const isProvisioning = project.appPlatformDetails?.some(
      (platformDetail) =>
        platformDetail.statusId ===
        this.props.projectStatus.find(
          (status) => status.key === EProjectStatus.PND
        )?.id
    );

    const isInfrapipelinePending =
      project.infrastructureStatusId ===
      this.props.projectStatus.find(
        (status) => status.key === EProjectStatus.PND
      )?.id;

    let options: MoreMenuOption[] = [
      {
        id: "123",
        title: t.admin_portal_project_view_log,
        actionName: MORE_MENU_ACTION_NAME.VIEWLOG,
      },
    ];
    // TODO - later we need to apply soft close feature to other state of project like error!
    if (project?.status?.toLowerCase() === EProjectStatuses.ACTV) {
      options.push({
        id: "126",
        title: t.admin_portal_project_soft_close_action,
        actionName: MORE_MENU_ACTION_NAME.SOFT_CLOSE,
      });
    }
    // Only soft closed project can be reactivated, for that we check deleted props to verify closed project on soft mode
    else if (
      project?.status?.toLowerCase() === EProjectStatuses.CLSD &&
      project.deleted !== true
    ) {
      options.push({
        id: "127",
        title: t.admin_portal_project_reactivate_action,
        actionName: MORE_MENU_ACTION_NAME.REACTIVATE,
      });
    }
    options = [
      ...options,
      {
        id: "124",
        titleHTML: this.getCloseProjectContent.bind(this, hasDoNotDelete),
        title: "",
        actionName: MORE_MENU_ACTION_NAME.CLOSEPROJECT,
      },
      {
        id: "129",
        title: t.admin_projects_more_menu_access_restrictions,
        actionName: MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONS,
        icon: caretRight,
        iconPosition: "right",
        hasSubMenu: true,
        isDisabled: !(
          adminConsoleAccess.portalAdmin ||
          (adminConsoleAccess.portalAdminConsole &&
            userProjectAdminAccess &&
            isEYUser)
        ),
        subMenuOptions: [
          {
            id: "131",
            title: t.admin_projects_more_menu_access_restrictions_all_access,
            actionName: MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONNONE,
            icon: caretRight,
            iconPosition: "right",
            isSelected: project.accessRestrictionKey,
            defaultSelected: true,
          },
          {
            id: "132",
            title: t.admin_projects_more_menu_access_restrictions_project_admin,
            actionName: MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONPROJECTADMIN,
            icon: caretRight,
            iconPosition: "right",
            isSelected: project.accessRestrictionKey,
          },
          {
            id: "133",
            title: t.admin_projects_more_menu_access_restrictions_no_user,
            actionName: MORE_MENU_ACTION_NAME.ACCESSRESTRICTIONALL,
            icon: caretRight,
            iconPosition: "right",
            isSelected: project.accessRestrictionKey,
          },
        ],
      },
    ];
    if (
      !(
        adminConsoleAccess.portalAdmin ||
        (adminConsoleAccess.portalAdminConsole &&
          userProjectAdminAccess &&
          isEYUser)
      ) ||
      !showCloseButton ||
      isProvisioning ||
      isInfrapipelinePending
    ) {
      options = options.filter(
        (option) =>
          option.actionName !== MORE_MENU_ACTION_NAME.CLOSEPROJECT &&
          option.actionName !== MORE_MENU_ACTION_NAME.SOFT_CLOSE &&
          option.actionName !== MORE_MENU_ACTION_NAME.REACTIVATE
      );
    }

    return (
      <MoreMenu
        controlId="more-menu-apps"
        optionClickFn={this.handleMoreMenuOptionClick.bind(this, project)}
        options={options}
        vertical
        leftPlacement
      />
    );
  };

  onClosePopup = () => this.setState({ showDeleteModal: false });
  onProjectClose = (feedback) =>
    this.props.deleteAdminProjectsAction({
      id: this.state.selectedProjectDetails.selectedProjectId,
      feedback,
    });

  render() {
    const {
      showDeleteModal,
      showNotification,
      selectedProject,
      selectedProjectAppDetails,
      selectedProjectDetails: { selectedProjectName },
      showAppsModal,
      showLogsModal,
      isProjectSearched,
      selectedProjectDetails,
      rowData,
      paginationPayload,
      showIsCustomizedModal,
      isCustomizedData,
    } = this.state;
    const { pagination, searchLoader, searchAdminProject, exportStatus } =
      this.props;
    const adminConsoleAccess = this.getUserAdminConsoleAccess();

    if (pagination) {
      paginationPayload.totalRecords = pagination.totalRecords;
    }

    return (
      <CrashBoundary>
        <div className="admin-projects">
          <div>
            <div className="title-section">
              <p className="project-list-heading">
                {t.admin_portal_project_title}
              </p>
              {!(exportStatus.success || exportStatus.error) && (
                <AppButton
                  disabled={exportStatus.loading}
                  className="export-btn"
                  color="primary"
                  size="large"
                  onClick={this.handleExportProjectList}
                >
                  {exportStatus.loading
                    ? t.admin_project_exporting
                    : t.admin_project_export}
                </AppButton>
              )}
              {exportStatus.success && (
                <span className="download-icon download-success">
                  {" "}
                  <AppIcon icon={check} />{" "}
                </span>
              )}
              {exportStatus.error && (
                <span className="download-icon download-failure">
                  {" "}
                  <AppIcon icon={cross} />{" "}
                </span>
              )}
            </div>
            <div className={`project-table`}>
              <div className="search-wrapper">
                <div className="project-form-field">
                  <AppIcon icon={search} />
                  <AsyncTypeahead
                    id="async-search"
                    isLoading={searchLoader}
                    filterBy={["name", "clientName", "createdBy"]}
                    labelKey={(option) => `${option.name}`}
                    minLength={3}
                    onSearch={this.handleSearchChange}
                    options={searchAdminProject}
                    onChange={(selected) => this.handleSelectValue(selected)}
                    placeholder={t.app_drawer_search}
                    ref={(ref) => (this.typeahead = ref)}
                    useCache={false}
                    renderMenuItemChildren={(option, props) =>
                      this.renderMenuItem(option, props)
                    }
                  >
                    {({ onClear, selected }) => (
                      <div className="rbt-aux">
                        {!!selected.length && <ClearButton onClick={onClear} />}
                      </div>
                    )}
                  </AsyncTypeahead>
                </div>
              </div>
              <div className={`projects-table`}>
                <List
                  columnConfig={getColumnConfig(
                    adminConsoleAccess.portalAdmin,
                    this.isStandardDemoProject
                  )}                
                  firstColumnBorderRight={true}
                  rowData={rowData}
                  frameworkComponents={{
                    additionalPaceIDsPillEditor: AdditionalPaceIDsPillEditor,
                  }}
                  paginate={true}
                  withStickyScroll
                  handleGridReady={this.handleGridReady}
                  onSortChanged={this.handleSorting}
                  currentPage={
                    isProjectSearched ? 1 : paginationPayload.pageNumber
                  }
                  pagination={
                    isProjectSearched
                      ? getPaginationModel({
                          ...getDefaultPaginationModel(),
                          ...paginationPayload,
                          pageNumber: 1,
                          totalRecords: rowData.length,
                        })
                      : paginationPayload
                  }
                  setItemsPerPage={this.handlePageSizeChange}
                  onPageChange={this.handlePageNumberChange}
                  onFilterChange={this.handleFilterChange}
                  defaultSort={[DEFAULT_SORT]}
                  onDateEditorChange={(e) => this.onExpiryDateChange(e)}
                  // make soft closed project row font into italica
                  rowClassRules={{
                    "soft-closed-italica": function (params) {
                      return (
                        params.data.raw_status === EProjectStatuses.CLSD &&
                        params.data.deleted !== true
                      );
                    },
                  }}
                  defaultColDef={{
                    resizable: false,
                    cellClass: this.getCellClass,
                    tooltipValueGetter: (params) => {
                      switch (params.colDef.field) {
                        case Fields.ppeddApprover:
                          return params.data.raw_ppeddApprover;
                        case Fields.createdBy:
                          return params.data.raw_createdBy;
                        default:
                          return params.data.raw_status ===
                            EProjectStatuses.CLSD &&
                            params.data.deleted !== true
                            ? `soft closed on ${new Date(
                                params.data.softCloseDate
                              ).toDateString()} by ${params.data.softClosedBy}`
                            : "";
                      }
                    },
                  }}
                />
              </div>
            </div>
          </div>
          {showAppsModal && (
            <AdminProjectsAppsModal
              showModal={showAppsModal}
              onAppsModalClose={this.onAppsModalClose}
              ProjectName={selectedProjectName}
              appDetails={selectedProjectAppDetails}
              showPreviewModal={(value) => this.props.showPreviewModal(value)}
            />
          )}
          {showLogsModal && (
            <AdminProjectsLogsModal
              showModal={showLogsModal}
              onAppsModalClose={this.handleLogsModalClose}
              selectedProjectDetails={selectedProjectDetails}
            />
          )}
          {showDeleteModal && (
            <CloseProjectPopup
              show={showDeleteModal}
              isPricingFeedback={
                this.state.selectedProjectDetails.selectedProjectCategoryId ===
                  EProjectCategory.CLI_ENG &&
                !this.state.selectedProjectDetails.isSelectedProjectInDraft
              }
              priced={this.state.selectedProjectDetails.isPricingIncluded}
              onClosePopup={this.onClosePopup}
              onProjectClose={this.onProjectClose}
            />
          )}
          {showNotification && (
            <AppNotification
              message={`${selectedProject?.name ?? ""} ${
                this.notificationMessage
              }`}
              variant={this.notificationVariant}
              onCloseNotifcation={this.closeNotification}
            />
          )}
          {showIsCustomizedModal && (
            <AdminProjectsIsCustomizedModal
              showModal={showIsCustomizedModal}
              projectDetails={isCustomizedData}
              onModalClose={() =>
                this.setState({
                  showIsCustomizedModal: false,
                  isCustomizedData: null,
                })
              }
            />
          )}
          {
            // soft close and reactivate confirmation dialog
            this.state.showSoftCloseAndReactivateActionModal &&
              selectedProject && (
                <StatusModal
                  title={
                    selectedProject.status?.toLowerCase() ===
                    EProjectStatuses.CLSD
                      ? t.admin_portal_reactivate_project_title
                      : t.close_project_modal_title
                  }
                  content={
                    selectedProject.status?.toLowerCase() ===
                    EProjectStatuses.CLSD
                      ? t.admin_portal_project_activate
                      : t.admin_portal_project_soft_delete
                  }
                  confirmBtnText={
                    selectedProject.status?.toLowerCase() ===
                    EProjectStatuses.CLSD
                      ? t.admin_portal_reactivate_project_button
                      : t.close_project_modal_close_button
                  }
                  onConfirm={() => {
                    // on softdelete or reactivate
                    if (
                      selectedProject.status?.toLowerCase() ===
                      EProjectStatuses.ACTV
                    ) {
                      this.props.softDeleteProjectAction({
                        id: selectedProject.id,
                      });
                    } else if (
                      selectedProject.status?.toLowerCase() ===
                        EProjectStatuses.CLSD &&
                      selectedProject.deleted !== true
                    ) {
                      this.props.reactivateProjectAction({
                        id: selectedProject.id,
                      });
                    }
                    this.setState({
                      showSoftCloseAndReactivateActionModal: false,
                    });
                  }}
                  onClose={() => {
                    this.setState({
                      showSoftCloseAndReactivateActionModal: false,
                    });
                  }}
                />
              )
          }
        </div>
      </CrashBoundary>
    );
  }
}

const StatusModal = ({
  title,
  content,
  onConfirm,
  confirmBtnText,
  onClose,
}: {
  title: string;
  content: string;
  onConfirm: () => void;
  confirmBtnText: string;
  onClose: () => void;
}) => (
  <AppModal
    showModal={true}
    fullWidth={false}
    onModalClose={onClose}
    title={title}
    onConfirm={onConfirm}
    confirmBtnText={confirmBtnText}
    cancelBtnText={t.cancel}
  >
    <div className="modal-content">
      <div
        className="app-title"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  </AppModal>
);

const mapStateToProps = ({
  adminProjectsReducer,
  projectStatusReducer,
  projectCategoriesReducer,
  meReducer,
  meta,
}) => {
  return {
    projects: adminProjectsReducer.projects,
    pagination: adminProjectsReducer.projectsGridPagination,
    searchLoader: adminProjectsReducer.searchLoaderApps,
    projectStatus: meta.projectStatuses || projectStatusReducer.projectStatus,
    projectCategories: projectCategoriesReducer.projectCategories,
    searchAdminProject: adminProjectsReducer.searchAdminProject,
    deleteProject: adminProjectsReducer.deleteProject,
    softDeleteProject: adminProjectsReducer.softDeleteProject,
    reactivateProject: adminProjectsReducer.reactivateProject,
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles,
    updatedProjectData: adminProjectsReducer.updatedProjectData,
    isCustomizationInfoUpdated: adminProjectsReducer.isCustomizationInfoUpdated,
    exportedProjectList: adminProjectsReducer.exportedProjectList,
    exportStatus: adminProjectsReducer.exportStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminProjectsAction: (payload) => {
      dispatch(getAdminProjectsAction(payload));
    },
    softDeleteProjectAction: (payload) => {
      dispatch(softDeleteAdminProjectsAction(payload));
    },
    reactivateProjectAction: (payload) => {
      dispatch(reactivateAdminProjectsAction(payload));
    },
    deleteAdminProjectsAction: (payload) => {
      dispatch(deleteAdminProjectsAction(payload));
    },
    flushAdminProjectAction: () => {
      dispatch(flushAdminProjectAction());
    },
    getProjectStatus: () => {
      dispatch(getProjectStatusAction());
    },
    getProjectCategories: () => {
      dispatch(getProjectCategoriesAction());
    },
    showPreviewModal: (payload) => {
      dispatch(showPreviewModal(payload));
    },
    fetchSearchData: (payload) => {
      dispatch(searchAdminProjectAction(payload));
    },
    updatefilterState: (payload) => {
      dispatch(updatefilterState(payload));
    },
    updateProject: (payload) => {
      dispatch(updateAdminProjectAction(payload));
    },
    flushUpdatedProjectData: (payload) => {
      dispatch(flushUpdatedAdminProjectAction(payload));
    },
    exportProjectList: (payload) => {
      dispatch(exportProjectList(payload));
    },
    flushExportProjectListStatus: () => {
      dispatch(flushExportProjectListStatus());
    },
    setProjectAccessRestrictionStatus: (payload) => {
      dispatch(setProjectAccessRestriction(payload));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminProjects)
);
