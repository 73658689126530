import React, { FC, useCallback, useState } from "react";
import {
  AppCheckbox,
  AppFormErrorText,
  AppInput,
  AppButton,
  AppModal,
  AppTextField,
  AppTooltip,
  AppIconButton,
  AppIcon,
  AppSelect,
} from "../../theme";
import { cross, info, fileDescription, search } from "../../theme/icons";
import { useDropzone } from "react-dropzone";
import t from "../../localization/en/translation.json";
import { IFormState } from "../../models/IAdminApps";
import { lookupModel } from "../../containers/createProject/lookupModel";
import { IUser } from "../../models/user";
import {
  CUSTOM_BUNDLE_NAME,
  VIDEO_FILE_SETTING,
} from "../../containers/adminApps/adminAppConstants";
import { ClearButton, AsyncTypeahead } from "react-bootstrap-typeahead";
import { CE4, DropZoneErrorStatuses } from "../../utils/Constants";
import { validateFormInputForSpecialChar } from "../../utils/helper-utility";
import "./adminAppsModal.scss";

interface IProps {
  isOpen: boolean;
  isEdit: boolean;
  handleAddOrEditApps: Function;
  formState: IFormState;
  isFormValid: boolean;
  appCategories: lookupModel[];
  appPlatforms: lookupModel[];
  onFormChange: Function;
  onModalClose: Function;
  projectRegions: lookupModel[];
  handleRemoveAppOwner: Function;
  handleAddAppOwner: Function;
  appBundles: any;
  getAdminAppsVideo: Function;
  searchLoader: boolean;
  userSearchData: IUser[];
  handleSearchChange: Function;
  filePath: string;
  renderPreviewVideoRows: Function;
  deleteAdminAppsVideo: Function;
}

const AdminAppsModal: FC<IProps> = ({
  isOpen,
  isEdit,
  handleAddOrEditApps,
  formState,
  appCategories,
  appPlatforms,
  isFormValid,
  onFormChange,
  onModalClose,
  projectRegions,
  handleRemoveAppOwner,
  handleAddAppOwner,
  appBundles,
  getAdminAppsVideo,
  searchLoader,
  userSearchData,
  handleSearchChange,
  filePath,
  deleteAdminAppsVideo,
  renderPreviewVideoRows,
}) => {
  const {
    typeId,
    typeName,
    typeKey,
    typeDesc,
    typeAppCategory,
    typeAppRelativeURL,
    typeAppPlatformClassId,
    typeAutoPublish,
    typeAppOwners,
    typeAppBundle,
    typeExternalAppKey,
    typeAppHotlink,
    typeIsLimitedAvailable,
  } = formState;
  let autoPublish = typeAutoPublish.fieldVal === "true";
  let isLimitedAvailable = typeIsLimitedAvailable.fieldVal === "true";
  const [indexRow, setIndexRow] = useState(-1);
  const [indexSearchRow, setSearchIndexRow] = useState(-1);
  const validatedInputClass = "validated-input";

  if (isEdit) {
    typeAppPlatformClassId.disable = true;
    typeKey.disable = true;
  }
  const selectAppPlatform = appPlatforms.find(
    (apps) => apps.id === typeAppPlatformClassId.fieldVal
  );
  typeExternalAppKey.disable =
    selectAppPlatform && selectAppPlatform.key === CE4 ? false : true;
  const filterBundle = appBundles.filter(
    (bundle) => bundle.key !== CUSTOM_BUNDLE_NAME.CUSTOM
  );
  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.forEach((acceptedFile) => {
      formData.append("file", acceptedFile);
    });
    if (acceptedFiles.length > 0) {
      getAdminAppsVideo(formData);
    }
    // eslint-disable-next-line
  }, []);

  const onDragOver = (e) => {
    const event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (e) => {
    const event = e as Event;
    event.stopPropagation();
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: VIDEO_FILE_SETTING.FORMAT,
    maxFiles: VIDEO_FILE_SETTING.MAX_NO,
    maxSize: VIDEO_FILE_SETTING.MAX_SIZE,
    onDrop,
    onDragOver,
    onDragEnter,
    noDragEventsBubbling: true,
  });

  const onConfirm = () => {
    setIndexRow(-1);
    resetFileErrors();
    handleAddOrEditApps();
  };

  const onClose = () => {
    setIndexRow(-1);
    resetFileErrors();
    onModalClose(true);
  };

  const resetFileErrors = () => {
    if (fileRejections && fileRejections.length) {
      fileRejections.length = 0;
    }
  };

  const setInputFieldClick = (index) => {
    setIndexRow(index);
  };

  const updateAutoPublishCheckBox = () => {
    if (!typeAutoPublish.disable) {
      autoPublish = !autoPublish;
      onFormChange(autoPublish?.toString(), typeAutoPublish.fieldName);
    }
  };

  const updateLimitedAvailableCheckBox = () => {
    if (!typeIsLimitedAvailable.disable) {
      isLimitedAvailable = !isLimitedAvailable;
      onFormChange(
        isLimitedAvailable?.toString(),
        typeIsLimitedAvailable.fieldName
      );
    }
  };

  const renderInput = (
    label: string,
    fieldVal: string,
    fieldName: string,
    isvalid: boolean,
    charLimit,
    disable: boolean,
    index?: number
  ) => (
    <div
      className={`project-form-field ${
        fieldVal?.trim().length && isvalid ? validatedInputClass : ""
      }`}
    >
      <AppInput
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        autoComplete="off"
        disabled={disable}
        label={label}
        isValid={
          fieldName !== typeAppRelativeURL.fieldName &&
          fieldName !== typeAppHotlink.fieldName
            ? !validateFormInputForSpecialChar(fieldVal)
            : isvalid
        }
        onChange={(e) => onFormChange(e.target.value, e.target.name, index)}
      />
      {validateFormInputForSpecialChar(fieldVal) &&
      fieldName !== typeAppRelativeURL.fieldName &&
      fieldName !== typeAppHotlink.fieldName ? (
        <AppFormErrorText>
          {t.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            fieldVal.trim().split("")[0]
          )}
        </AppFormErrorText>
      ) : (
        !isvalid && (
          <AppFormErrorText>
            {fieldName === formState.typeName.fieldName
              ? t.three_chars_required
              : t.required_field}
          </AppFormErrorText>
        )
      )}
    </div>
  );

  const renderClickInput = (
    label: string,
    fieldVal: string,
    fieldName: string,
    isvalid: boolean,
    charLimit,
    disable: boolean,
    index?: number
  ) => (
    <div
      className={`project-form-field ${
        fieldVal?.trim().length && isvalid ? validatedInputClass : ""
      }`}
    >
      <AppInput
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        autoComplete="off"
        disabled={disable}
        label={label}
        isValid={isvalid}
        onFocus={(e) => setInputFieldClick(index)}
      />
      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
    </div>
  );

  const getOptions = (options: lookupModel[] = []) =>
    options.map((item) => ({ value: item.id, label: item.name }));

  const getMultiOptions = (options: lookupModel[] = []) =>
    options.map((item) => item.name);

  const renderSelect = (
    label: string,
    fieldVal: string | undefined,
    fieldName: string,
    isvalid: boolean,
    options: lookupModel[],
    disable: boolean,
    multiple?: boolean,
    index?: number
  ) => (
    <div
      className={`project-form-field ${
        fieldVal && isvalid ? validatedInputClass : ""
      }`}
    >
      <AppSelect
        id={fieldName}
        value={fieldVal}
        disabled={disable}
        name={fieldName}
        label={label}
        multiple={multiple}
        options={multiple ? getMultiOptions(options) : getOptions(options)}
        onChange={(e) => onFormChange(e.target.value, fieldName, index)}
      />
      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
    </div>
  );

  const handleSearchChangeUser = (query?, index?) => {
    setSearchIndexRow(index);
    handleSearchChange(query);
  };

  const renderMenuItem = (option, props) => {
    return (
      <div className="async-dropdown-modal">
        <div className="async-dropdown-name-modal">
          {option.givenName} {option.surname}
        </div>
        <div>{option.mail}</div>
      </div>
    );
  };

  const renderTypeheadSearch = (
    label: string,
    fieldVal: string | undefined,
    fieldName: string,
    isvalid: boolean,
    options: lookupModel[],
    disable: boolean,
    index?: number
  ) => (
    <div className="search-wrapper">
      <div className="project-form-field">
        <AppIcon icon={search} />
        <AsyncTypeahead
          id="async-search"
          isLoading={searchLoader && indexSearchRow === index}
          filterBy={["givenName", "surname", "mail"]}
          labelKey={(option) => `${option.givenName} ${option.surname}`}
          minLength={3}
          onSearch={(query) => handleSearchChangeUser(query, index)}
          options={userSearchData}
          onChange={(selectedOption) =>
            onFormChange(selectedOption, fieldName, index)
          }
          placeholder={label}
          useCache={false}
          renderMenuItemChildren={(option, props) =>
            renderMenuItem(option, props)
          }
        >
          {({ onClear, selected }) => (
            <div className="rbt-aux">
              {!!selected.length && <ClearButton onClick={onClear} />}
            </div>
          )}
        </AsyncTypeahead>
      </div>
    </div>
  );

  const handleVideoRemove = () => {
    deleteAdminAppsVideo(filePath, true);
  };

  const renderPreviewVideoRowsData = () => {
    return renderPreviewVideoRows({ value: filePath });
  };

  const renderFileUploadError = () => {
    const [
      {
        errors: [{ code }],
      },
    ] = fileRejections;

    if (code === DropZoneErrorStatuses.FILE_TOO_LARGE) {
      return <div>{t.admin_apps_video_size_validation_message}</div>;
    }

    if (code === DropZoneErrorStatuses.FILE_INVALID_TYPE) {
      return <div>{t.admin_apps_video_format_validation_message}</div>;
    }

    return <div>{t.admin_apps_rejected_apps_msg}</div>;
  };

  return (
    <AppModal
      size="md"
      showModal={isOpen}
      onModalClose={() => onClose()}
      onConfirm={() => onConfirm()}
      disabledValue={!isFormValid}
      cancelBtnText={t.cancel}
      confirmBtnText={isEdit ? t.edit_project_update : t.manage_user_add}
      title={isEdit ? t.admin_apps_modal_edit_title : t.admin_apps_add_apps}
    >
      <div className="admin-apps-modal">
        {typeId.fieldVal && (
          <div className="type-id">
            {renderInput(
              t.project_type_id_label,
              typeId.fieldVal,
              typeId.fieldName,
              typeId.isvalid,
              typeId.charLimit,
              typeId.disable
            )}
          </div>
        )}
        <div className="app-row">
          <div className="app-col-xs-2">
            {renderInput(
              t.project_type_name_label,
              typeName.fieldVal,
              typeName.fieldName,
              typeName.isvalid,
              typeName.charLimit,
              typeName.disable
            )}
          </div>
          <div className="app-col-xs-2">
            {renderInput(
              t.admin_apps_modal_key,
              typeKey.fieldVal,
              typeKey.fieldName,
              typeKey.isvalid,
              typeKey.charLimit,
              typeKey.disable
            )}
          </div>
        </div>
        <div className="app-row">
          <div className="app-col-xs-2">
            {renderSelect(
              t.admin_apps_modal_app_category,
              typeAppCategory.fieldVal,
              typeAppCategory.fieldName,
              typeAppCategory.isvalid,
              appCategories,
              typeAppCategory.disable
            )}
          </div>
          <div className="app-col-xs-2">
            {renderSelect(
              t.admin_apps_bundle,
              typeAppBundle.fieldVal,
              typeAppBundle.fieldName,
              typeAppBundle.isvalid,
              filterBundle,
              typeAppBundle.disable,
              true
            )}
          </div>
        </div>

        <div className="app-row">
          <div className="app-col-xs-2">
            {renderSelect(
              t.admin_apps_platform_class,
              typeAppPlatformClassId.fieldVal,
              typeAppPlatformClassId.fieldName,
              typeAppPlatformClassId.isvalid,
              appPlatforms,
              typeAppPlatformClassId.disable
            )}
          </div>
          <div className="app-col-xs-2">
            {renderInput(
              t.admin_apps_relative_url,
              typeAppRelativeURL.fieldVal,
              typeAppRelativeURL.fieldName,
              typeAppRelativeURL.isvalid,
              typeAppRelativeURL.charLimit,
              typeAppRelativeURL.disable
            )}
          </div>
        </div>
        <div className="app-row">
          <div className="app-col-xs-4">
            {renderInput(
              t.admin_apps_externalAppKey,
              typeExternalAppKey.fieldVal,
              typeExternalAppKey.fieldName,
              typeExternalAppKey.isvalid,
              typeExternalAppKey.charLimit,
              typeExternalAppKey.disable
            )}
          </div>
        </div>
        <div className="app-row">
          <div className="app-col-xs-4">
            {renderInput(
              t.admin_apps_hotlink,
              typeAppHotlink.fieldVal,
              typeAppHotlink.fieldName,
              typeAppHotlink.isvalid,
              typeAppHotlink.charLimit,
              typeAppHotlink.disable
            )}
          </div>
        </div>

        <div className="type-desc">
          <div className="project-form-field text-area-field">
            <AppTextField
              maxLength={typeDesc.charLimit}
              name={typeDesc.fieldName}
              value={typeDesc.fieldVal}
              disabled={typeDesc.disable}
              label={t.admin_apps_modal_description_title}
              onChange={(e) => onFormChange(e.target.value, e.target.name)}
              isValid={!validateFormInputForSpecialChar(typeDesc.fieldVal)}
            />
            {validateFormInputForSpecialChar(typeDesc.fieldVal) && (
              <AppFormErrorText>
                {t.all_ssp_validate_special_character_in_text_error.replace(
                  "{1}",
                  typeDesc.fieldVal.trim().split("")[0]
                )}
              </AppFormErrorText>
            )}
          </div>
        </div>
        <div className="app-row">
          <div className="app-col-xs-2 app-col-md-2">
            <div className="project-form-field autoPublish-box text-area-field">
              <div
                onClick={updateAutoPublishCheckBox}
                className="checbox-autoPublish-div"
              >
                <AppCheckbox
                  name={typeAutoPublish.fieldName}
                  value={typeAutoPublish.fieldVal}
                  disabled={typeAutoPublish.disable}
                  checked={autoPublish}
                >
                  {t.admin_apps_publish}
                </AppCheckbox>
              </div>
            </div>
          </div>
          <div className="app-col-xs-3 app-col-md-3">
            <div className="project-form-field text-area-field">
              <div className="checkbox-limited-available-div">
                <span
                  onClick={updateLimitedAvailableCheckBox}
                  className="check-box-la"
                >
                  <AppCheckbox
                    name={typeIsLimitedAvailable.fieldName}
                    value={typeIsLimitedAvailable.fieldVal}
                    disabled={typeIsLimitedAvailable.disable}
                    checked={isLimitedAvailable}
                  >
                    {t.admin_apps_limited_availability}
                  </AppCheckbox>
                </span>
                <span className="limited-available-tool-tip">
                  <AppTooltip title={t.admin_apps_limited_availability_info}>
                    <AppIcon icon={info} />
                  </AppTooltip>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="add-apps">
          <p className="content-title">{t.admin_apps_owner_title}</p>
        </div>
        {typeAppOwners.map((appOwner, idx) => (
          <div key={idx} className="app-row appOwner">
            <div className="app-col-xs-1">
              {renderSelect(
                t[appOwner.typeAppOwnersRegionId.fieldTranslate] || "",
                appOwner.typeAppOwnersRegionId.fieldVal,
                appOwner.typeAppOwnersRegionId.fieldName,
                appOwner.typeAppOwnersRegionId.isvalid,
                projectRegions,
                appOwner.typeAppOwnersRegionId.disable,
                false,
                idx
              )}
            </div>
            <div className="app-col-xs-1">
              {isEdit &&
              (indexRow === -1 || indexRow !== idx) &&
              appOwner.typeAppOwnersName.fieldVal !== ""
                ? renderClickInput(
                    t[appOwner.typeAppOwnersName.fieldTranslate] || "",
                    appOwner.typeAppOwnersName.fieldVal,
                    appOwner.typeAppOwnersName.fieldName,
                    appOwner.typeAppOwnersName.isvalid,
                    appOwner.typeAppOwnersName.charLimit,
                    appOwner.typeAppOwnersName.disable,
                    idx
                  )
                : renderTypeheadSearch(
                    t[appOwner.typeAppOwnersName.fieldTranslate] || "",
                    appOwner.typeAppOwnersName.fieldVal,
                    appOwner.typeAppOwnersName.fieldName,
                    appOwner.typeAppOwnersName.isvalid,
                    appOwner.typeAppOwnersName.charLimit,
                    appOwner.typeAppOwnersName.disable,
                    idx
                  )}
            </div>
            <div className="app-col-xs-1">
              {renderInput(
                t[appOwner.typeAppOwnersEmail.fieldTranslate] || "",
                appOwner.typeAppOwnersEmail.fieldVal,
                appOwner.typeAppOwnersEmail.fieldName,
                appOwner.typeAppOwnersEmail.isvalid,
                appOwner.typeAppOwnersEmail.charLimit,
                appOwner.typeAppOwnersEmail.disable,
                idx
              )}
            </div>
            <div className="app-col-xs-1">
              <div className="close-input-field">
                {renderInput(
                  t[appOwner.typeAppOwnersCcEmail.fieldTranslate] || "",
                  appOwner.typeAppOwnersCcEmail.fieldVal,
                  appOwner.typeAppOwnersCcEmail.fieldName,
                  appOwner.typeAppOwnersCcEmail.isvalid,
                  appOwner.typeAppOwnersCcEmail.charLimit,
                  appOwner.typeAppOwnersCcEmail.disable,
                  idx
                )}
                <span className="close-btn">
                  <AppIconButton
                    aria-label="close"
                    type="button"
                    size="small"
                    onClick={() => handleRemoveAppOwner(idx)}
                  >
                    <AppIcon icon={cross} />
                  </AppIconButton>
                </span>
              </div>
            </div>
          </div>
        ))}
        <AppButton
          variant="text"
          className="add-region-btn"
          size="small"
          onClick={() => handleAddAppOwner()}
        >
          {t.admin_apps_region}
        </AppButton>
        <div className="add-apps">
          <p className="content-title">{t.admin_apps_upload_video_header} </p>
        </div>
        <div className="container">
          {filePath === "" && (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p className="content-title">
                {t.admin_apps_drag_n_drop_placeholder}{" "}
              </p>
              {!!fileRejections.length && renderFileUploadError()}
            </div>
          )}
          {filePath !== "" && (
            <div className="accept-file">
              <div className="accept-file-container">
                <div className="video-text-container">
                  <AppIcon icon={fileDescription} />
                  <div className="video-text">
                    {" "}
                    {t.admin_apps_preview_video_placeholder}
                  </div>
                </div>
                <div>
                  <AppButton variant="text" onClick={handleVideoRemove}>
                    <AppIcon icon={cross} />
                  </AppButton>
                </div>
              </div>
              {renderPreviewVideoRowsData()}
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
};

export default AdminAppsModal;
