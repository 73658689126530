import React, { FC } from 'react';
import en_translation from '../../localization/en/translation.json';
// @ts-ignore
import { url, getFooterText } from './footerConstants';
import './footer.scss';

const Footer: FC = () => {
    const footerText = getFooterText();
	return (
		<footer className="footer">
			<div className="content">
				<span className="footer-title">{footerText}</span>
				<span className="anchor-link">
					<a href={url.TERMS} target="_blank" rel="noopener noreferrer">{en_translation.footer_terms}</a>
				</span>
				<span className="anchor-link">
					<a href={url.PRIVACY} target="_blank" rel="noopener noreferrer">{en_translation.footer_privacy}</a>
				</span>
				<span className="anchor-link">
					<a href={url.EMG} target="_blank" rel="noopener noreferrer">{en_translation.emg_title}</a>
				</span>
			</div>
		</footer>
	)
}

export default Footer;
