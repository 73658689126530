export const columnConfig = [
  {
    headerName: 'App name',
    field: 'appClassName',
    lockPosition: true,
   // sortable: true,
    minWidth: 360,
    sort: "asc",
  },
  {
    headerName: 'App Platform',
    field: 'appPlatformName',
    //sortable: true,
    lockPinned: true,
    minWidth: 220
  },
  {
    headerName: 'Category',
    field: 'appCategoryName',
    //sortable: true,
    lockPinned: true,
    cellRenderer: 'appCategoriesCellRenderer',
    minWidth: 320,
    cellClass: 'custom-cellrenderer'
  },
  {
    headerName: 'Owner',
    field: 'appOwners',
   // sortable: false,
    lockPinned: true,
    cellRenderer: 'appOwnersCellRenderer',
    minWidth: 280,
    cellClass: 'custom-cellrenderer',
    comparator: () => 0,
  },
  {
    headerName: 'Preview Video',
    field: 'demoSiteUrl',
    minWidth: 240,
    lockPinned: true,
   // sortable: false,
    cellRenderer: 'playVideoCellRenderer',
    comparator: () => 0,
  }
];

const sortColumn = (a, b) => {
  const valA = a.props.children.toLowerCase();
  const valB = b.props.children.toLowerCase();

  if (valA === valB) {
    return 0;
  } else if (valA > valB) {
    return 1;
  } else {
    return -1;
  }
}

export const projectColumnConfig = [
  {
    headerName: 'Project',
    field: 'projectName',
    lockPosition: true
  },
  {
    headerName: 'Type',
    field: 'type',
    lockPinned: true
  },
  {
    headerName: 'Data hosting loc.',
    field: 'dataHostingLoc',
    lockPinned: true,
    width: 300,
  },
  {
    headerName: 'No. of users',
    field: 'noOfUsers',
    lockPinned: true,
    width: 180
  },
  {
    headerName: 'State',
    field: 'status',
    lockPinned: true,
   // sortable: true,
    comparator: sortColumn,
    width: 200
  }
];
