export const MenuActions = {
    PUBLISH: "publish",
    UNPUBLISH: "unpublish",
    OPEN: "open",
    EDIT: "edit",
    SETTINGS: "settings",
    WORKPLAN_SYNC: "Workplan_sync"
};

export const MAX_TITLE_WIDTH = 155;
