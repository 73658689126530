import React, { FC, useState } from "react";
import { AppIcon } from "../../theme";
import { user } from "../../theme/icons";
import MoreMenu from "../moreMenu/moreMenu";
import { MoreMenuOption } from "../moreMenu/moreMenuOptions";
import './dropDownMenu.scss';

export interface IDropDownProps {
  controlId: string;
  controlTitle?: string;
  imageData?: string;
  menuOptions: MoreMenuOption[];
  onOptionClick: (actionName: string) => void;
}

const DropDownMenu: FC<IDropDownProps> = (props) => {

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { controlId, controlTitle, menuOptions, imageData, onOptionClick } = props;
  const arrowClassName = isExpanded ? "arrow up" : "arrow down";

  const handleToggle = (isExpanded) => {
    setIsExpanded(isExpanded);
  };

  return (
    <div className="dropdown-menu m-l-10 m-r-20">
      <MoreMenu
        className="user-profile-menu-pop"
        controlId={controlId}
        optionClickFn={onOptionClick}
        onMenuClick={handleToggle}
        options={menuOptions}
        stopEventBubbling
        menuOpener={
          <>
            <div className="user-avatar image-cropper">
              {
                imageData ? <img src={"data:image/png;base64," + imageData} className="rounded"
                  alt={controlTitle} title={controlTitle} />
                  : <AppIcon icon={user} />
              }
            </div>
            <div className="container">
              <span className="font-italic greyed-outline">{controlTitle}&nbsp;<i className={arrowClassName + " m-l-5"}></i></span>
            </div>
          </>
        }
      />
    </div>
  );
}

export default DropDownMenu;
