import {DefaultTheme, makeStyles, styled} from "@material-ui/styles";
import {Box, IconButton, Popover, PopoverActions} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, {ForwardedRef, useState} from "react";
import {ExpandLessRounded, ExpandMoreRounded} from "@material-ui/icons";

export const NotificationDetails = styled('div')({
    paddingInline: "10px",
    fontFamily: "EY-Light",
    fontWeight: "normal",
    marginLeft: "-100%",
    overflow: "hidden",
    maxWidth: 700,
    transition: "margin-left 0.3s ease-out"
});
export const ExpandDetails = ({openNote}: { openNote?: boolean }) => (
    <IconButton title={openNote ? "collapse details" : "expand details"} aria-label="expand" size="small"
                style={{float: "right", marginRight: 5, color: "black"}}>
        {openNote ? <ExpandLessRounded/> : <ExpandMoreRounded/>}
    </IconButton>);
export const CloseNote = ({read, onClick}) => (
    <IconButton title={"close"} aria-label="close" size="small" onClick={onClick}
                style={{float: "right", marginRight: read ? 20 : 15, marginLeft: 5, color: "black"}}>
        <CloseIcon fontSize={"small"}/>
    </IconButton>
);
export const NotificationHeader = styled(({openNote, background, ...rest}) =>
    <div {...rest}/>)<DefaultTheme, { openNote?: boolean, background?: string }>((props) => ({
    ...(props.openNote ? {
        position: "sticky",
        top: 0,
        background: "white",
        width: "100%",
        zIndex: 2,
        margin: "auto",
        marginTop: "3px",
        marginBottom: "0px"
    } : {
        alignSelf: "center", width: "100%", margin: "auto", marginTop: "auto"
    }),
    cursor: "pointer",
    paddingBlock: "13px",
    paddingInline: "10px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    background: props.background
}));
export const NotificationItem = styled(React.forwardRef<HTMLDivElement, { close?: boolean, openNote?: boolean, read?: boolean }>(({
                                                                                                                                      close,
                                                                                                                                      openNote,
                                                                                                                                      read,
                                                                                                                                      ...rest
                                                                                                                                  }, ref: ForwardedRef<HTMLDivElement>) =>
    <div {...rest}
         ref={ref}/>))<DefaultTheme, { close?: boolean, openNote?: boolean, read?: boolean } & React.HTMLAttributes<HTMLDivElement>>((props) => ({
    ...(props.close ? {
        minHeight: 0,
        maxHeight: 0,
        visibility: "hidden",
        opacity: 0,
        transition: "max-height 0.3s, min-height 0.3s, visibility 0.3s, opacity 0.3s"
    } : {
        minHeight: "60px",
        maxWidth: 700
    }),
    filter: `opacity(${props.read && !props.openNote ? "0.5" : "1"})`,
    boxShadow: "1px 7px 1px -6px #cecece",
    display: "flex",
    flexFlow: "column",
    flexGrow: 1,
    borderTop: 0,
    borderInline: 0,
}));
export const UnreadIndicator = styled('span')({
    height: 5,
    width: 5,
    borderRadius: "50%",
    display: "inline",
    float: "right",
    background: "#ff4136",
    position: "sticky",
    top: 10,
    marginTop: "1.5%"
});
const useNoNotificationStyle = makeStyles({root:{
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: "rgb(93, 93, 105)"
}});
export const NoNotification = () => <span className={useNoNotificationStyle().root}>No new notifications</span>
export const NotificationsList = styled('div')({
    maxHeight: "400px",
    minHeight: "60px",
    minWidth: "300px",
    maxWidth: "750px",
    overflowY: "auto",
    paddingInline: "3px"
})
const useNotificationsHeader = makeStyles({root:{
    fontWeight: "bold",
    boxShadow: "0 8px 6px -6px #cecece",
    fontSize: "1.2rem", paddingInline: "10px",
    paddingTop: "5px", paddingBottom: "10px"
}});
export const NotificationsHeader = () => <div className={useNotificationsHeader().root} >Notifications</div>
export const NotificationsContainer = ({children}) => <Box sx={{
    position: "relative",
    mt: "10px",
    bgcolor: "white",
    borderRadius: 5,
    border: "1px solid black",
    maxWidth: "750px",
    //@ts-ignore
    "&::before": {
        backgroundColor: "white",
        content: '""',
        display: "block",
        position: "absolute",
        width: 10,
        height: 10,
        top: -7,
        transform: "rotate(45deg)",
        left: "calc(100% - 30px)",
        zIndex: 99,
        borderTop: "1px solid black",
        borderLeft: "1px solid black"
    }
}}>{children}</Box>
export const NotificationPopover = ({icon, children}: {
    icon: React.ReactNode,
    children: React.ReactNode,
    actionsRef?: React.MutableRefObject<PopoverActions | null>,
    onPaperEntered?: (node: HTMLElement) => void
}) => {
    const [triggerEl, setTriggerEl] = useState<HTMLElement | null>();
    const onEnter = (node) => {
        node.style.left = "";
        node.style.right = `${document.body.clientWidth - (triggerEl?.getBoundingClientRect()?.right ?? 0)}px`;
        node.style.top = `${(triggerEl?.getBoundingClientRect().top ?? 0) + (triggerEl?.getBoundingClientRect()?.height ?? 0)}px`;
    };
    return (<>
        <IconButton onClick={(event) => setTriggerEl(event.currentTarget)}>
            {icon}
        </IconButton>
        <Popover open={!!triggerEl}
                 onClose={() => setTriggerEl(null)}
                 anchorReference={"none"}
                 aria-label={"notifications"}
                 PaperProps={{
                     style: {
                         backgroundColor: "transparent",
                         boxShadow: "none",
                         borderRadius: 0
                     }
                 }}
                 TransitionProps={{
                     onEnter: onEnter,
                     onEntering: onEnter,
                     onEntered: onEnter,
                     onExit: onEnter,
                     onExiting: onEnter,
                     onExited: onEnter
                 }}>
            {
                children
            }
        </Popover>
    </>)
}
export const ViewMoreItem = styled((props) => <div {...props}>View more...</div>)({
    height: "30px",
    cursor: "pointer",
    padding: 5,
    textAlign: "center"
});