import React, { FC } from 'react';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { DEFAULT_PAGE_SIZES } from './appPaginationConstants'
import "./appPagination.scss";

interface Iprops {
  maxCount?: number;
  onChangePage?: Function;
  pageNumber?: number;
  pageSize?: number;
  currentPage?: number;
  maxPage?: number;
  onChangeRowsPerPage?: Function;
}

const AppPagination: FC<Iprops> = ({ maxCount = 0, pageSize = 50, currentPage = 0, maxPage = 0, pageNumber = 1, onChangePage, onChangeRowsPerPage }) => {
  const handleChangePage = (newPage) => {
    onChangePage && onChangePage(newPage)
  };

  const handleChangeRowsPerPage = ({ target }) => {
    onChangeRowsPerPage && onChangeRowsPerPage(parseInt(target.value))
  };

  const handleFirstPageButtonClick = () => {
    handleChangePage(1);
  };

  const handleBackButtonClick = () => {
    handleChangePage(pageNumber - 1);
  };

  const handleNextButtonClick = () => {
    handleChangePage(pageNumber + 1);
  };

  const handleLastPageButtonClick = () => {
    handleChangePage(maxPage);
  };


  const renderArrows = () => {
    return (
      <div className="app-pagination-buttons">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={pageNumber === 1}
        >
          {<FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={pageNumber === 1}
        >
          {<KeyboardArrowLeft />}
        </IconButton>
        <span className="page-count"> 
          {`${currentPage} / ${maxPage}`}
        </span>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={pageNumber >= maxPage}
        >
          {<KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={pageNumber >= maxPage}
        >
          {<LastPageIcon />}
        </IconButton>
      </div>)
  }

  return (
    <div className="app-pagination">
      <TablePagination
        component="div"
        count={maxCount}
        page={pageNumber - 1}
        rowsPerPageOptions={DEFAULT_PAGE_SIZES}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        ActionsComponent={renderArrows}
        labelRowsPerPage=""
      />
    </div>
  );
}

export default AppPagination;