export enum Fields {
  iconButton = "iconButton",
  name = "name",
  isCustomized = "isCustomized",
  clientName = "clientName",
  projectFriendlyId = "projectFriendlyId",
  description = "description",
  apps = "apps",
  projectCategoryName = "projectCategoryName",
  ppeddApprover = "ppeddApprover",
  projectTypeName = "projectTypeName",
  bundleName = "bundleName",
  paceId = "paceId",
  additionalPaceIds = "additionalPaceIds",
  regionName = "regionName",
  locationName = "locationName",
  noOfUsers = "noOfUsers",
  createdBy = "createdBy",
  createdOn = "createdOn",
  status = "status",
  expirationDate = "expirationDate",
  serviceLine = "serviceLine",
}

const headers = {
  [Fields.iconButton]: "",
  [Fields.name]: "Project name",
  [Fields.isCustomized]: "Is Customized",
  [Fields.clientName]: "Client name",
  [Fields.projectFriendlyId]: "Project Friendly ID",
  [Fields.description]: "Description",
  [Fields.apps]: "Apps",
  [Fields.projectCategoryName]: "Project category",
  [Fields.ppeddApprover]: "PPEDD Approver",
  [Fields.serviceLine]: "Service Line",
  [Fields.projectTypeName]: "Type",
  [Fields.bundleName]: "Bundle",
  [Fields.paceId]: "Pace Id",
  [Fields.additionalPaceIds]: "Additional Pace Ids",
  [Fields.regionName]: "Region",
  [Fields.locationName]: "Data hosting loc.",
  [Fields.noOfUsers]: "No. of users",
  [Fields.createdBy]: "Creator",
  [Fields.createdOn]: "Created date",
  [Fields.status]: "State",
  [Fields.expirationDate]: "Expiration Date",
};

const suppressEnterKeyEvent = (params) => {
  const key = params.event.key;
  const gridShouldDoNothing = params.editing && key === "Enter";
  return gridShouldDoNothing;
};
export const getColumnConfig = (isPortalAdmin, isStandardDemoProject) => [
  {
    headerName: headers[Fields.iconButton],
    field: Fields.iconButton,
    minWidth: 43,
    width: 43,
    pinned: "left",
    lockPosition: true,
    sortable: false,
  },
  {
    headerName: headers[Fields.name],
    field: Fields.name,
    lockPosition: true,
    //sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.isCustomized],
    field: Fields.isCustomized,
   // sortable: true,
    filter: "customFilter",
    filterType: "boolean",
  },
  {
    headerName: headers[Fields.clientName],
    field: Fields.clientName,
    lockPinned: true,
    //sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.projectFriendlyId],
    field: Fields.projectFriendlyId,
    width: 300,
    lockPinned: true,
   // sortable: true,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.description],
    field: Fields.description,
    lockPinned: true,
   // sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.apps],
    field: Fields.apps,
    width: 210,
    lockPinned: true,
   // sortable: false,
  },
  {
    headerName: headers[Fields.projectCategoryName],
    field: Fields.projectCategoryName,
    lockPinned: true,
   // sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.ppeddApprover],
    field: Fields.ppeddApprover,
    lockPinned: true,
    width: 300,
   // sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.serviceLine],
    field: Fields.serviceLine,
    lockPinned: true,
    width: 300,
   // sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.projectTypeName],
    field: Fields.projectTypeName,
    lockPinned: true,
   // sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.bundleName],
    field: Fields.bundleName,
    lockPinned: true,
   // sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.paceId],
    field: Fields.paceId,
    lockPinned: true,
  //  sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.additionalPaceIds],
    field: Fields.additionalPaceIds,
    lockPinned: true,
   // sortable: false,
    editable: isPortalAdmin,
    cellEditor: "additionalPaceIDsPillEditor",
    // enter key event is suppressed because search pills keyboard event
    // will mix grid, give not good UX
    suppressKeyboardEvent: suppressEnterKeyEvent,
  },
  {
    headerName: headers[Fields.regionName],
    field: Fields.regionName,
    width: 200,
    lockPinned: true,
  //  sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.locationName],
    field: Fields.locationName,
    lockPinned: true,
   // sortable: false,
    filter: "customFilter",
    width: 300,
    minFilterCharLimit: 2,
  },
  {
    headerName: headers[Fields.noOfUsers],
    field: Fields.noOfUsers,
    lockPinned: true,
    width: 180,
   // sortable: false,
  },
  {
    headerName: headers[Fields.createdBy],
    field: Fields.createdBy,
    lockPinned: true,
    width: 280,
   // sortable: false,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.createdOn],
    field: Fields.createdOn,
    lockPinned: true,
    width: 250,
   // sortable: true,
    // sort: "desc",//for now commenting this (temprarory fix) to stop infinite loading while sorting
    sortingOrder: ["desc", "asc"],
    filter: "customFilter",
    filterType: "date",
  },
  {
    headerName: headers[Fields.status],
    field: Fields.status,
    lockPinned: true,
    width: 200,
   // sortable: true,
    filter: "customFilter",
  },
  {
    headerName: headers[Fields.expirationDate],
    field: Fields.expirationDate,
    width: 250,
    lockPinned: true,
   // sortable: true,
    editable: (params) =>
      isPortalAdmin && !isStandardDemoProject(params.data.projectCategoryId),
    cellEditor: "customDateEditor",
    filter: "customFilter",
    filterType: "date",
    // enter key event is suppressed cell editor keyboard event
    // will mix grid, give not good UX
    suppressKeyboardEvent: suppressEnterKeyEvent,
  },
];
