import React, {
  FC,
  useState,
  useEffect,
  useImperativeHandle,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import * as _ from 'lodash';
import {
  SelectCellEditor,
  SelectCellRenderer,
  UserDetailsCellRenderer,
} from '../editableGrid.module';
// import { ChangeDetectionStrategyType } from "ag-grid-react/lib/changeDetectionService";
import { IPagination, Ifilters, Isort } from '../../../models/IPagination';
import { AppPagination } from '../../../theme';
import ListFilter from './listFilter';
import ListDateEditor from './listDateEditor';
import UserSearchEditor from './listSearchUserEditor';
import { usePrevious } from '../../../utils/hooks';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import './list.scss';

interface IProps {
  columnConfig: any[];
  rowData: any[];
  handleGridReady?: Function;
  onSortChanged?: Function;
  pagination?: IPagination;
  onRowDataChanged?: Function;
  setItemsPerPage?: Function;
  onPageChange?: Function;
  onGridSizeChanged?: Function;
  onViewportChanged?: Function;
  paginate: boolean;
  recordsPerPageData?: number[];
  currentPage?: number;
  maxRecordsPerPage?: number;
  firstColumnBorderRight?: boolean;
  frameworkComponents?: any;
  rowSelection?: string;
  rowHeight?: number;
  deltaRowDataMode?: boolean;
  withStickyScroll?: boolean;
  onFilterChange?: Function;
  defaultSort?: Isort[];
  resetAllFilters?: boolean;
  onDateEditorChange?: Function;
  defaultColDef?: any;
  rowClassRules?: any;
  clientSideSorting?: boolean;
  basicGrid?: boolean;
  onCellValueChanged?: Function;
  onSearchEditorUserSelect?: Function;
  getRowHeight?: Function;
  // rowDataChangeDetectionStrategy?: ChangeDetectionStrategyType
}

interface SortChangedEvent {
  type: string;
  api: GridApi;
}

let handleDateEditorChanged: any = null;

const List: FC<IProps> = (props) => {
  const [gridScrollPos, setGridScrollPos] = useState<number>(0);
  const [stickyScroll, setStickyScroll] = useState<boolean>(
    !!props.withStickyScroll
  );
  const [filters, setFilters] = useState<Ifilters[]>([]);
  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const agGridRef = useRef(null);

  const {
    columnConfig,
    rowData,
    pagination,
    paginate,
    recordsPerPageData,
    currentPage,
    frameworkComponents,
    rowSelection,
    onGridSizeChanged,
    deltaRowDataMode,
    rowClassRules,
    basicGrid,
    // rowDataChangeDetectionStrategy,
    resetAllFilters,
  } = props;

  const prevProps = usePrevious({ resetAllFilters });
  const prevState = usePrevious({ gridScrollPos, filters });

  let staticFilters: Ifilters[] = [];

  const showStickyScroll = useCallback(() => {
    setStickyScroll(window.pageYOffset + window.innerHeight < gridScrollPos);
  }, [gridScrollPos]);

  const checkScrollPosition = () => {
    const table = document.querySelector('.app-table');

    if (!table) {
      return;
    }
    setGridScrollPos(
      table.getBoundingClientRect().top + table.clientHeight + window.scrollY
    );
  };

  const handleViewPortChange = () => {
    const { withStickyScroll, onViewportChanged } = props;

    if (withStickyScroll) {
      checkScrollPosition();
    }

    onViewportChanged && onViewportChanged();
  };

  const onPageChange = (pageNo) => {
    const { onPageChange } = props;
    if (onPageChange) {
      onPageChange(pageNo);
    }
  };

  const setItemsPerPage = (newCount) => {
    const { setItemsPerPage } = props;
    if (setItemsPerPage) {
      setItemsPerPage(newCount);
    }
  };

  const onRowDataChanged = () => {
    const { onRowDataChanged } = props;

    if (onRowDataChanged) {
      onRowDataChanged();
    }
  };

  const handleGridReady = (gridApis) => {
    agGridRef.current = gridApis.api;

    const { handleGridReady } = props;
    if (handleGridReady) {
      handleGridReady(gridApis);
      handleDateEditorChanged = handleDateEditorChange;
    }
  };

  const onSortChanged = (params: SortChangedEvent) => {
    const {
      clientSideSorting,
      columnConfig,
      onSortChanged,
      defaultSort = [],
    } = props;

    if (clientSideSorting) {
      params && params.api.redrawRows();

      return;
    }

    const sortModel = params.api
      .getColumnState()
      .filter((s) => s.sort !== null);

    if (resetFilters) {
      setResetFilters(false);

      return;
    }

    if (!sortModel.length) {
      params.api.resetColumnState();
      //   params.api?.setSortModel(defaultSort);
      return;
    }

    const [{ colId, sort }] = sortModel;
    const colDef = columnConfig.find(
      (colDef) => colDef.field === colId || colDef.colId === colId
    );
    if (colDef && colDef.sortable) {
      if (onSortChanged) {
        onSortChanged(colId, sort);
      }
    }
  };

  const customFilter = (params) => {
    const { field, sortable, filterType, minFilterCharLimit } = params.colDef;

    return (
      <ListFilter
        resetFilters={resetFilters}
        sortable={!!sortable}
        filterType={filterType}
        field={field}
        minFilterCharLimit={minFilterCharLimit}
        onClearAllFilter={handleClearAllFilter}
        onFilterSortChange={handleFilterSorting}
        onClearThis={handleClearThis}
        onFilterTextChange={handleFilterTextChange}
        onFilterValueChange={handleFilterValueChange}
      />
    );
  };

  const userSearchEditor = ({ data, rowIndex }) => {
    const { onSearchEditorUserSelect } = props;

    return (
      <UserSearchEditor
        onUserSelect={(e) =>
          onSearchEditorUserSelect &&
          onSearchEditorUserSelect(e, data, rowIndex)
        }
      />
    );
  };

  const setFilter = useCallback((filterColumn, isValidFilter = false) => {
    const filterInstance = agGridRef.current?.getFilterInstance(filterColumn);
    if (filterInstance) {
      filterInstance.doesFilterPass = () => isValidFilter;
      filterInstance.isFilterActive = () => isValidFilter;
    }
  }, []);

  // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  const handleFilterTextChange = useCallback(
    _.debounce((filterText, filterColumn) => {
      const isValidFilter = !!filterText.trim().length;
      const restFilters = staticFilters.filter(
        (f) => f.filterColumn !== filterColumn
      );

      setFilter(filterColumn, isValidFilter);

      const updatedFiletrs = isValidFilter
        ? [...restFilters, { filterColumn, filterText }]
        : [...restFilters];
      staticFilters = [...updatedFiletrs];
      setFilters(updatedFiletrs);

      setResetFilters(false);
    }, 2000),
    []
  );

  const handleFilterValueChange = (filterText, filterColumn) => {
    const restFilters = filters.filter((f) => f.filterColumn !== filterColumn);

    setFilter(filterColumn, true);
    const updatedFiletrs = [...restFilters, { filterColumn, filterText }];
    staticFilters = [...updatedFiletrs];
    setFilters(updatedFiletrs);
    setResetFilters(false);

    agGridRef.current?.hidePopupMenu();
  };

  const handleFilterChange = useCallback(() => {
    const { onFilterChange } = props;
    const sortModel = agGridRef.current
      ?.getColumnState()
      .find((s) => s.sort != null);

    if (sortModel) {
      const { colId, sort } = sortModel;
      onFilterChange && onFilterChange(filters, colId, sort);

      return;
    }
    onFilterChange && onFilterChange(filters);
  }, [props, filters]);

  const handleFilterSorting = (colId, sort) => {
    // agGridRef.current?.setSortModel([{ colId, sort }]);
    agGridRef.current?.hidePopupMenu();
  };

  const handleClearThis = (field) => {
    const isFilterApplied = staticFilters.find((f) => f.filterColumn === field);
    if (!isFilterApplied) {
      agGridRef.current?.hidePopupMenu();

      return;
    }

    const newFilters = staticFilters.filter((f) => f.filterColumn !== field);

    setFilter(field);
    staticFilters = [...newFilters];
    setFilters(newFilters);

    agGridRef.current?.hidePopupMenu();
  };

  const handleClearAllFilter = useCallback(() => {
    const { defaultSort = [] } = props;
    if (agGridRef.current) {
      // agGridRef.current?.setSortModel(defaultSort);
      agGridRef.current?.hidePopupMenu();
      staticFilters.forEach((f) => setFilter(f.filterColumn));
      staticFilters.forEach((f) => {
        agGridRef.current?.destroyFilter(f.filterColumn);
      });
    }

    // eslint-disable-next-line
    staticFilters = [];
    setFilters([]);
    setResetFilters(true);
    setTimeout(() => {
      setResetFilters(false);
    }, 100);
  }, [props, filters, setFilter, staticFilters]);

  const defaultCellRenderer = ({ value }) => (
    <span className='app-table-cell-renderer'>{value || null}</span>
  );

  const getDefaultColDef = useMemo(() => {
    const { defaultColDef = {}, clientSideSorting } = props;

    let colDefs = {
      cellRenderer: 'defaultCellRenderer',
      width: 200,
      sortable: false,
      ...defaultColDef,
    };

    if (!clientSideSorting) {
      colDefs = { ...colDefs, comparator: () => 0 };
    }

    return colDefs;
  }, [props]);

  const handleDateEditorChange = (value) => {
    const { onDateEditorChange } = props;
    agGridRef.current?.stopEditing();

    onDateEditorChange && onDateEditorChange(value);
  };

  const handleCellValueChanged = (e) => {
    const { onCellValueChanged } = props;

    onCellValueChanged && onCellValueChanged(e);
  };

  const handleRowHeight = (params) => {
    const { getRowHeight, rowHeight } = props;

    return getRowHeight ? getRowHeight(params) : rowHeight || 48;
  };

  const renderPagination = (
    currentPage,
    maxPage,
    pagination,
    paginationPageNoList
  ) => (
    <div className='list-pagination'>
      <AppPagination
        maxCount={pagination?.totalRecords}
        pageNumber={pagination?.pageNumber}
        pageSize={pagination?.pageSize}
        onChangePage={onPageChange}
        onChangeRowsPerPage={setItemsPerPage}
        maxPage={maxPage}
        currentPage={currentPage}
      />
    </div>
  );

  useEffect(() => {
    const { withStickyScroll } = props;

    withStickyScroll && window.addEventListener('scroll', showStickyScroll);

    return () => {
      withStickyScroll && window.addEventListener('scroll', showStickyScroll);
    };
  }, [props, showStickyScroll]);

  useEffect(() => {
    const { resetAllFilters } = props;

    if (prevState && prevState.gridScrollPos !== gridScrollPos) {
      showStickyScroll();
    }

    if (prevState && prevState.filters !== filters) {
      handleFilterChange();
    }

    if (
      prevProps &&
      prevProps.resetAllFilters !== resetAllFilters &&
      resetAllFilters
    ) {
      handleClearAllFilter();
    }
  }, [
    props,
    gridScrollPos,
    filters,
    prevState,
    prevProps,
    handleClearAllFilter,
    showStickyScroll,
    handleFilterChange,
  ]);

  const defaultFrameworkComponents = {
    customFilter: customFilter,
    defaultCellRenderer: defaultCellRenderer,
    defaultSelectCellRenderer: SelectCellEditor,
    defaultUserCellRenderer: SelectCellRenderer,
    defaultUserDetailsRenderer: UserDetailsCellRenderer,
    customDateEditor: customDateEditor,
    userSearchEditor: userSearchEditor,
  };

  const finalFrameworkComponents = {
    ...frameworkComponents,
    ...defaultFrameworkComponents,
  };
  const maxPage =
    Math.ceil((pagination?.totalRecords || 0) / (pagination?.pageSize || 1)) ||
    1;
  const paginationPageNoList = recordsPerPageData || [10, 20, 50, 100];

  return (
    <div className={`list ${stickyScroll ? 'sticky-scroll' : ''}`}>
      <div className='ag-theme-material app-table'>
        {/* <AgGridReact rowData={rowData} columnDefs={columnConfig} defaultColDef={getDefaultColDef} /> */}
        <AgGridReact
          ref={agGridRef}
          domLayout='autoHeight'
          disableStaticMarkup={!basicGrid}
          rowSelection={rowSelection || 'single'}
          defaultColDef={!basicGrid && getDefaultColDef}
          rowData={rowData}
          columnDefs={columnConfig}
          suppressCellSelection
          suppressMaxRenderedRowRestriction={true}
          suppressDragLeaveHidesColumns={true}
          components={finalFrameworkComponents}
          getRowNodeId={(row) => {
            return row.id;
          }}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
          rowBuffer={500}
          getRowHeight={handleRowHeight}
          rowClassRules={rowClassRules}
          immutableData={deltaRowDataMode || false}
          onGridReady={handleGridReady}
          onSortChanged={onSortChanged}
          enableCellTextSelection
          onRowDataChanged={onRowDataChanged}
          // rowDataChangeDetectionStrategy={rowDataChangeDetectionStrategy ?? ChangeDetectionStrategyType.DeepValueCheck}
          onGridSizeChanged={() => onGridSizeChanged && onGridSizeChanged()}
          onViewportChanged={handleViewPortChange}
          onCellValueChanged={(e) => handleCellValueChanged(e)}
        />
        {/* {columnConfig.map(column => (<AgGridColumn {...column} key={column.field} />))}
                </AgGridReact> */}
        {(paginate &&
          renderPagination(
            currentPage,
            maxPage,
            pagination,
            paginationPageNoList
          )) ||
          null}
      </div>
    </div>
  );
};

const customDateEditor = React.forwardRef(
  (params: { value: any; api: GridApi; data: any; colDef: ColDef }, ref) => {
    useImperativeHandle(ref, () => ({
      getValue() {
        return params.value;
      },
    }));
    return (
      <ListDateEditor
        title={params.colDef.headerName}
        projectId={params.data.id}
        onDateEditorChange={handleDateEditorChanged}
        onCancel={() => params.api.stopEditing(true)}
      />
    );
  }
);

export default List;
