import React, { useRef, useState } from "react";
import { Badge, PopoverActions } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { APP_STATE } from "../../store/reducers";
import { NOTIFICATION } from "../../store/actions/admin-portal-communication-actions";
import {
  NoNotification,
  NotificationPopover,
  NotificationsContainer,
  NotificationsHeader,
  NotificationsList,
  ViewMoreItem,
} from "./parts";
import { Notification } from "./notificationItem";
import {
  NewspaperSVG,
  StopWatchSVG,
  SurveySVG,
  AdhocNewsSVG,
} from "./notificationTagIcons";
import cssVariables from "./notificationStyle.module.scss";

const MAX_ITEMS_ONLOAD = 5;
export const NotificationBell = () => {
  const notifications = useSelector<APP_STATE, NOTIFICATION[]>(
    ({ meReducer }) => meReducer.activeNotifications
  );
  const _popoverActionRef = useRef<PopoverActions | null>(null);
  const [loadAll, setLoadAll] = useState(false);
  const {
    releaseTagColor,
    releaseTagBackGroundColor,
    surveyTagColor,
    surveyTagBackGroundColor,
    generalTagColor,
    generalTagBackGroundColor,
    adhocNewsTagBackGroundColor,
    adhocNewsTagColor,
  } = cssVariables;
  return (
    <NotificationPopover
      actionsRef={_popoverActionRef}
      onPaperEntered={(node) => {
        node.style.left = "";
        node.style.right = "212px";
      }}
      icon={
        <Badge
          badgeContent={
            notifications.filter(
              (notification) => notification?.userNotificationStatus !== "Read"
            )?.length ?? "?"
          }
          color={"secondary"}
        >
          <Notifications style={{ color: "white" }} />
        </Badge>
      }
    >
      <NotificationsContainer>
        <NotificationsHeader />
        <NotificationsList>
          {(!loadAll
            ? notifications?.slice(0, MAX_ITEMS_ONLOAD)
            : notifications
          )?.map((notification) => {
            switch (notification.type) {
              // replaced ReleaseNotification and General Notiification with N otification genric component for now I have not deleted the files after testing we can delete those files
              case "ReleaseNotes":
                return (
                  <Notification
                    key={notification.id}
                    notificationId={notification.id}
                    read={notification.userNotificationStatus === "Read"}
                    version={notification.title}
                    content={notification.message}
                    tagSVGIcon={<StopWatchSVG />}
                    tagBackground={releaseTagBackGroundColor}
                    headerBackGround={releaseTagColor}
                  />
                );
              case "General":
                return (
                  <Notification
                    key={notification.id}
                    notificationId={notification.id}
                    read={notification.userNotificationStatus === "Read"}
                    version={notification.title}
                    content={notification.message}
                    tagSVGIcon={<NewspaperSVG />}
                    tagBackground={generalTagColor}
                    headerBackGround={generalTagBackGroundColor}
                  />
                );

              case "Survey":
                return (
                  <Notification
                    key={notification.id}
                    notificationId={notification.id}
                    read={notification.userNotificationStatus === "Read"}
                    version={notification.title}
                    content={notification.message}
                    tagSVGIcon={<SurveySVG />}
                    tagBackground={surveyTagColor}
                    headerBackGround={surveyTagBackGroundColor}
                  />
                );

              case "AdHocNews":
                return (
                  <Notification
                    key={notification.id}
                    notificationId={notification.id}
                    read={notification.userNotificationStatus === "Read"}
                    version={notification.title}
                    content={notification.message}
                    tagSVGIcon={<AdhocNewsSVG />}
                    tagBackground={adhocNewsTagColor}
                    headerBackGround={adhocNewsTagBackGroundColor}
                  />
                );
              default:
                return <></>;
            }
          })}
          {!notifications.length && <NoNotification />}
          {
            // disabling load more functionality since it isn't fully defined
            notifications.length > MAX_ITEMS_ONLOAD && !loadAll && (
              <ViewMoreItem onClick={() => setLoadAll(true)} />
            )
          }
        </NotificationsList>
      </NotificationsContainer>
    </NotificationPopover>
  );
};

export default NotificationBell;
