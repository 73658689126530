import React, { Component } from "react";
import { connect } from 'react-redux';
import { ClearButton, AsyncTypeahead } from 'react-bootstrap-typeahead';
import moment from "moment";
import * as _ from 'lodash';
import { getProjectLogsAction, searchProjectLogsAction, flushProjectLogs } from '../../store/actions/admin-projects-actions';
import t from "../../localization/en/translation.json";
import { AppModal, AppIcon } from "../../theme";
import { search } from "../../theme/icons";
import List from "../../components/editableGrid/list/list";
import { ProjectLogsRowDataModel } from "../../containers/adminProjects/adminProjectsModel";
import { getColumnConfig } from './columnConfig';
import { timeZoneAbbreviated } from '../../utils/helper-utility';
import "./adminProjectsLogsModal.scss";

interface IProps {
  showModal: boolean,
  onAppsModalClose: Function;
  selectedProjectDetails: {
    selectedProjectId: string,
    selectedProjectName: string,
    selectedProjectCreatedBy: string
  };
  logs: ProjectLogsRowDataModel[];
  searchProjectLogs: ProjectLogsRowDataModel[];
  getProjectLogsAction: Function;
  searchLogLoader: boolean;
  searchProjectLogsAction: Function;
  flushProjectLogs: Function;
  isLogsFetchComplete: boolean;
}

interface IState {
  currentPage: number;
  stickyScroll: boolean;
  gridScrollPos: number;
  searchQuery: ProjectLogsRowDataModel[]
}

const appModalBody = ".admin-projects-logs-modal.app-modal .MuiPaper-root";

class AdminProjectsLogsModal extends Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      stickyScroll: true,
      gridScrollPos: 0,
      searchQuery: []
    };

    this.handleInfiniteScroll = _.debounce(this.handleInfiniteScroll, 800);
  }


  gridApi;
  gridColumnApi;
  gridLogsData = [];
  typeahead;
  modalBody;

  componentDidMount() {
    this.getProjectLogs();
    window.addEventListener("scroll", this.showStickyScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.showStickyScroll);
    window.removeEventListener("resize", this.onPageResize);
    this.props.flushProjectLogs()
  }

  componentDidUpdate(prevProps, prevState) {
    const { logs } = this.props;
    const { currentPage } = this.state;

    if (logs?.length && logs !== prevProps.logs) {
      this.generateLogsData(logs);
    }

    if (prevState.currentPage !== currentPage) {
      this.getProjectLogs()
    }
  }

  handleInfiniteScroll = (scrollTop, scrollHeight, modalContentHeight) => {
    const { isLogsFetchComplete } = this.props;

    if (isLogsFetchComplete) {
      return
    }

    if (scrollTop > scrollHeight - modalContentHeight) {
      this.setState({ currentPage: this.state.currentPage + 1 })
    }
  }

  showStickyScroll = () => {
    const modalBody = document.querySelector(appModalBody);
    const { stickyScroll, gridScrollPos } = this.state;
    if (modalBody && stickyScroll !== ((modalBody.scrollTop + modalBody.clientHeight) < gridScrollPos)) {
      this.setState({ stickyScroll: modalBody.scrollTop + modalBody.clientHeight < gridScrollPos });
    }
  }

  checkScrollPosition = () => {
    const appTable: HTMLElement | null = document.querySelector(".project-log-table");
    const modalBody: HTMLElement | null = document.querySelector(appModalBody);
    const scrollElm: HTMLElement | null = document.querySelector(".log-sticky-scroll .ag-body-horizontal-scroll");
    const tableViewport: HTMLElement | null = document.querySelector(".project-log-table .ag-header-viewport");
    if (!appTable || !modalBody) {
      return
    }
    this.setState({ gridScrollPos: appTable.getBoundingClientRect().top + appTable.clientHeight + modalBody.scrollTop },
      () => {
        setTimeout(() => {
          if (scrollElm && modalBody && tableViewport) {
            scrollElm.style.width = `${tableViewport.clientWidth}px`;
            scrollElm.style.top = `${modalBody.getBoundingClientRect().top + modalBody.clientHeight - scrollElm.clientHeight}px`;
          }
        }, 50);
      });
  }

  getProjectLogs = () => {
    const { currentPage } = this.state;
    const { selectedProjectDetails: { selectedProjectId }, getProjectLogsAction } = this.props; //NOSONAR
    const payload = {
      id: selectedProjectId,
      data: {
        pageNumber: currentPage,
        sortColumn: "timestamp",
        sortDirection: "desc",
        search: "",
        daysAgo: 7
      }
    }
    getProjectLogsAction(payload);
  }

  onPageResize = () => {
    this.checkScrollPosition();
  }

  handleGridReady = ({ api, columnApi}) => {
    this.gridApi = api;
    this.gridColumnApi = columnApi;
    window.addEventListener("resize", this.onPageResize);
  };

  generateLogsData = logs => {
    const { currentPage } = this.state;
    const localTimeZoneAbbr = timeZoneAbbreviated();

    this.gridLogsData = logs.map((log, i) => ({
      timestamp: `${moment(log.timestamp).format("MM/DD/YYYY HH:mm:ss")} ${localTimeZoneAbbr}`,
      message: log.message,
      id: `log-${i}`
    }))
    this.setState({ currentPage });
  };

  handleSearchChange = query => {
    const { currentPage } = this.state;
    const { selectedProjectDetails: { selectedProjectId }, searchProjectLogsAction } = this.props
    const payload = {
      id: selectedProjectId,
      data: {
        pageNumber: currentPage,
        sortColumn: "timestamp",
        sortDirection: "desc",
        search: query,
        daysAgo: 7
      }
    }
    searchProjectLogsAction(payload);
  };

  handleSelectValue = selectedArray => {
    this.setState({
      ...this.state,
      currentPage: 1,
      searchQuery: selectedArray
    }, () => {
      if (selectedArray.length) {
        this.generateLogsData(selectedArray);
      } else {
        this.generateLogsData(this.props.logs);
      }
    });
  };

  renderMenuItem = (option, props) => {
    return (
      <div className="async-dropdown">
        <div className="async-dropdown-name">{option.message}</div>
      </div>
    )
  }

  render() {
    const { showModal, onAppsModalClose, selectedProjectDetails: { selectedProjectName },
      searchLogLoader, searchProjectLogs, logs } = this.props;
    const { stickyScroll } = this.state;
    const tableClass = `project-log-table ${stickyScroll ? "log-sticky-scroll" : ""}`;
    const maxLineLength = Math.max(...(this.gridLogsData.map((value: any) => value?.message.length)))

    return (
      <AppModal
        modalClass="admin-projects-logs-modal"
        showModal={showModal}
        onModalClose={() => onAppsModalClose()}
        title={`${selectedProjectName} ${t.admin_portal_project_logs_title} `}
        onModalScroll={this.handleInfiniteScroll}
      >
        {(logs.length) ?
          <div className={tableClass}>
            <div className="search-wrapper">
            <div className="project-form-field">
                <AppIcon icon={search} />
                <AsyncTypeahead
                  id="async-search"
                  isLoading={searchLogLoader}
                  filterBy={['timestamp', 'message']}
                  labelKey={option => `${option.message}`}
                  minLength={3}
                  onSearch={this.handleSearchChange}
                  options={searchProjectLogs}
                  onChange={selected => this.handleSelectValue(selected)}
                  placeholder={t.app_drawer_search}
                  ref={(ref) => this.typeahead = ref}
                  useCache={false}
                  renderMenuItemChildren={(option, props) => this.renderMenuItem(option, props)}>
                  {({ onClear, selected }) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                    </div>
                  )}
                </AsyncTypeahead>
              </div>
            </div>
            <List
              columnConfig={getColumnConfig(maxLineLength)}
              defaultColDef={defaultColDef}
              rowData={this.gridLogsData}
              handleGridReady={this.handleGridReady}
              clientSideSorting
              onRowDataChanged={this.checkScrollPosition}
              paginate={false}
              basicGrid
            />
          </div>
          :
          <span>{t.no_logs_found}</span>
        }
      </AppModal>
    );
  }
}

const defaultColDef = {
  resizable: false,
  // Add other default properties that you want to apply to all columns
};
const mapStateToProps = ({ adminProjectsReducer }) => {
  return {
    logs: adminProjectsReducer.logs,
    isLogsFetchComplete: adminProjectsReducer.isLogsFetchComplete,
    searchLogLoader: adminProjectsReducer.searchLogLoader,
    searchProjectLogs: adminProjectsReducer.searchProjectLogs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectLogsAction: payload => { dispatch(getProjectLogsAction(payload)) },
    searchProjectLogsAction: payload => { dispatch(searchProjectLogsAction(payload)) },
    flushProjectLogs: () => { dispatch(flushProjectLogs()) }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProjectsLogsModal);
