import React, { ReactNode } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from "@material-ui/styles";
import themeConfig from "../config";

const useStyle = makeStyles({
  root: {
     color: themeConfig.black
  },
  label: {
    fontFamily: themeConfig.fontFamilyLight,
    fontSize: themeConfig.fontSize14,
    color: themeConfig.black
  },
  checked: {
    color: themeConfig.black,
  }
})

interface IProps {
  id?: string;
  onChange?: Function;
  disabled?: boolean;
  checked?: boolean;
  name: string;
  maxLength?: number;
  value?: string;
  className?: string;
  children: ReactNode
}

  const AppCheckbox: React.FC<IProps> = ({ id = "app-checkbox", className = "", children, onChange, value = "", disabled = false, checked = false, name }) => { 
    const classes = useStyle()
    const handleChange = (e) => {
      onChange && onChange(e, { value, label: children })
    }
  
    return (
    <span className="app-checkbox">
      <FormControlLabel
        classes={{
          label: classes.label
        }}
        control={
          <Checkbox
            id={id}
            className={className}
            checked={checked}
            onChange={handleChange}
            name={name}
            color="default"
            value={value}
            disabled={disabled}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        }
        label={children}
      />
    </span>  
  );
}

export default AppCheckbox;