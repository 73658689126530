import React from 'react';
import he from 'he';
// Custom cell renderer component
export const RenderTitleCell = ({ data }) => {

   // Define the custom styles
   const textStyle:React.CSSProperties = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100%", // You might want to set a maxWidth to ensure the ellipsis works
    display: "inline-block", // Make sure the span behaves like a block for the styles to take effect
  };

  return (
    <span style={textStyle} title={data.title}>
      {data.title}
    </span>
  );


  //return `<span title="${data.title}" style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}> ${data.title}</span>`;
};



