export const MAX_DESC_LENGTH = 20;
export const MIN_APP_NAME_LENGTH = 3;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ACTION_NAME = {
    UPDATE: 'Update',
    DELETE: 'Delete'
  };
export const CUSTOM_BUNDLE_NAME = {
    CUSTOM: 'MY'
};

export const DEFAULT_SORT = { colId: "name", sort: "asc" };

export const VIDEO_FILE_SETTING = {
  FORMAT: "video/mp4",
  MAX_NO: 1,
  MAX_SIZE: 125829120 // 120 MB
};