import { IUser, IPansPolicy, IAccessRight, ISurvey } from "./../../models/user";
import * as type from "../action-types";
import { NOTIFICATION } from "../actions/admin-portal-communication-actions";

export type ME_REDUCER_STATE = {
  currentUser: IUser;
  loading: boolean;
  paansPolicy: IPansPolicy;
  accessRight: IAccessRight | null;
  resetPaans: {};
  appErrorFailNotification: {};
  activeNotifications: NOTIFICATION[];
  surveyDetails: ISurvey;
  isCreateProjectDisabled: boolean;
  isMaintanenceFlagLoaded: boolean;
  showMailChangeModalPopUp: boolean;
  currentEmail: string;
  previousEmail: string;
};
const initialState: ME_REDUCER_STATE = {
  accessRight: {
    loading: false,
    isEyUser: false,
    canCreateProject: false,
    serviceLineId:"",
    hasIpAssetManagerAccess: false,
  },
  currentUser: {},
  loading: true,
  paansPolicy: { hasAcceptedAll: false, loaded: false },
  resetPaans: {},
  appErrorFailNotification: {},
  activeNotifications: [],
  surveyDetails: {},
  isCreateProjectDisabled: false,
  isMaintanenceFlagLoaded: false,
  showMailChangeModalPopUp: false,
  currentEmail: "",
  previousEmail: "",
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.SHOW_CHANGE_EMAIL_POPUP:
      const { showEmailChangePopUp, currentEmail, previousEmail } =
        action.payload;
      return {
        ...state,
        showMailChangeModalPopUp: showEmailChangePopUp,
        currentEmail: currentEmail,
        previousEmail: previousEmail,
      };
    case type.ME:
      return { ...state, loading: true };
    case type.ME_SUCCESS:
      return { ...state, currentUser: action.payload || {}, loading: false };
    case type.ME_FAILURE:
      return { ...state, currentUser: {}, loading: false };
    case type.GET_PAANS_POLICY_REQUEST:
      return {
        ...state,
        paansPolicy: {
          ...state.paansPolicy,
          loaded: false,
        },
      };
    case type.GET_PAANS_POLICY_SUCCESS:
    case type.GET_PAANS_POLICY_FAILURE:
      return {
        ...state,
        paansPolicy: {
          ...(action.payload && typeof action.payload == "object"
            ? action.payload.data
            : {}),
          loaded: true,
        },
      };
    case type.DELAY_SURVEY_SUCCESS:
    case type.GET_SURVEY_DETAILS_FAILURE:
      return {
        ...state,
        surveyDetails: {},
      };
    case type.GET_SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        surveyDetails: action.payload?.data,
      };
    case type.GET_MY_ACCESS_RIGHT_REQUEST:
      return {
        ...state,
        accessRight: {
          ...state.accessRight,
          loading: true,
        },
      };
    case type.GET_MY_ACCESS_RIGHT_SUCCESS:
    case type.GET_MY_ACCESS_RIGHT_FAILURE:
      return {
        ...state,
        accessRight: {
          ...(action.payload && typeof action.payload == "object"
            ? action.payload.data
            : {}),
          loading: false,
        },
      };

    case type.APP_ERROR_HANDLER_SUCCESS:
      return { ...state, resetPaans: action.payload };

    case type.APP_ERROR_HANDLER_FAILURE:
      return { ...state, appErrorFailNotification: action.payload };
    case type.FLUSH_APP_ERROR_NOTIFICATION:
      return { ...state, appErrorFailNotification: {} };
    case type.GET_ALL_ACTIVE_NOTIFICATIONS_SUCCESS:
      return { ...state, activeNotifications: action.payload };
    case type.READ_NOTIFICATION_SUCCESS:
    case type.READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        activeNotifications: state.activeNotifications.map((notification) => {
          if (notification.id === action.payload.notificationId) {
            notification.userNotificationStatus = "Read";
          }
          return notification;
        }),
      };
    case type.CLOSE_NOTIFICATION_SUCCESS:
    case type.CLOSE_NOTIFICATION_FAILURE:
      return {
        ...state,
        activeNotifications: state.activeNotifications.filter(
          (notification) => notification.id !== action.payload.notificationId
        ),
      };

    case type.GET_ALL_ACTIVE_NOTIFICATIONS_FAILURE:
    default:
      return state;
  }
};
