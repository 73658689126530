import React, { Component } from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import AppCard from "../../components/appCard/appCard";
import { edit, chartLine, network, back, wrench } from "../../theme/icons";
import MoreMenu from "../../components/moreMenu/moreMenu";
import { MoreMenuOption } from "../../components/moreMenu/moreMenuOptions";
import { connect } from "react-redux";
import en_translation from "../../localization/en/translation.json";
import AppNotification from "../../components/appNotification/appNotification";
import { AppDetail } from "../../components/createProject/models/appDetail";
import { Project } from "../../components/createProject/models/project";
import { getProjectStatusAction } from "../../store/actions/project-status-actions";
import { getAppCategories, getApps } from "../../store/actions/meta-actions";
import { updateProjectDetailsForEMG } from "../../store/actions/emg-policy-actions";
import {
  getProjectDetails,
  updateProjectAppPublish,
  createAnnouncementRequest,
  flushAnnouncementNotification,
  getAnnouncementsRequest,
  flushAnnouncements,
  updateAnnouncementRequest,
  flushProjectDetails,
  getMyProjectRightRequest,
  deleteAnnouncementRequest,
  getWrikeSyncSettingsRequest,
  updateWrikeSyncSettingsRequest,
  flushWrikeSyncNotification,
  checkCe4ReadyStatus,
  checkToggleSyncStatus,
  flushToggleSyncStatus,
  extendProjectExpirationRequest,
  extendProjectExpirationFlush,
  showExpirationModel,
  getProjectSupportedFeatures,
  patchProjectUpdateAction,
  getProjectActiveFeatures,
} from "../../store/actions/project-actions";
import { getProjectCategoriesAction } from "../../store/actions/project-categories-actions";
import { getAppPlatforms } from "../../store/actions/meta-actions";
import { AppPlatformClass } from "../../components/appCard/models/appPlatformClass";
import {
  closeProjectRequest,
  flushCloseProject,
} from "../../store/actions/create-project-actions";
import {
  getAppStatus,
  isProjectAdmin,
  isEyUser,
  getDateTime,
  isPlatformPending,
  isPlatformError,
  isPlatformReady,
  isProjectUser,
  isPorjectErrored,
  getAzureAppInsights,
  isPortalAdmin,
  validateFormInputForSpecialChar,
  isProjectAdminUser,
  setPageBrTitle,
  isFeatureEnabled,
} from "../../utils/helper-utility";
import {
  announcementDropdownOptions,
  ANNOUNCEMENT_SCROLL_TARGET,
  ANNOUNCEMENT_DETAILS_MIN_LENGTH,
  ELLIPSIS,
  ACTIVE_PROJECT_STATE,
  isImportantDropDown,
  isEnabledDropdown,
  NumberCount,
  CheckInTime,
  ExpirationPeriodUnit,
  ExpirationPeriod,
  PROJECT_STATUS_ALL,
  PROJECT_USAGE_REPORT,
} from "./projectDetailConstants";
import { ProjectStatus } from "../../components/projectCard/projectCardConstants";
import { Ce4Data } from "../../models/IHelp";
import { gridAppPlatformKeys } from "../manageUsers/listUsers/listUserConstants";
import {
  EProjectStatuses,
  FeatureSupportedKeys,
  HandlerMode,
  RoutePathKeys,
  SessionKeys,
  externalAppSettings,
  assistantCancelEvent,
  assistantSettingURl,
  ProjectActiveFeatures,
} from "../../utils/Constants";
import { checkAndLoadEMGPolicy } from "../../components/emgPolicy/constants";
import CloseProjectPopup, {
  FeedbackType,
} from "../../components/CloseProjectPopup/CloseProjectPopup";
import {
  EProjectCategory,
  EProjectCategoryKey,
} from "../../containers/createProject/formStateModel";
import {
  AppInput,
  AppButton,
  AppModal,
  AppTextField,
  AppTooltip,
  AppIcon,
  AppSelect,
  AppFormErrorText,
} from "../../theme";
import { flag } from "../../theme/icons";
import "../home/home.scss";
import "./projectDetails.scss";
import { CrashBoundary } from "../../components/CrashBoundary";
import { MenuActions } from "../../components/appCard/AppCardConstants";
import SharePointSettings from "./SharePointSettings";
import { ProjectDetailsProps, IState } from "./projectDetailsModel";
import { lookupModel } from "../createProject/lookupModel";
import { showActionToast } from "../../store/actions/notification-actions";
import { AssistantContainer } from "../assistantContainer/assistantContainer";

type RouteParams = {
  id: string;
  encodedParams: string;
};

type IProps = RouteComponentProps<RouteParams> & ProjectDetailsProps;

class ProjectDetails extends Component<IProps, IState> {
  queryParamsMode: string = "";
  confirmOrChangeTitle: string = "";
  confirmOrChangeContent: string = "";
  hideModalFooterForChangeCategory: boolean = false;
  messageTimer: NodeJS.Timeout | null = null;
  sspIframeRef = React.createRef<HTMLIFrameElement>();

  constructor(props: IProps) {
    super(props);
    this.sspIframeRef = React.createRef();

    this.state = {
      showPubUnPubDialog: false,
      pubUnpubAppId: "",
      notificationMessage: "",
      showNotificationMessage: false,
      announcementName: "",
      announcementDetails: "",
      isImportantAnnoucment: isImportantDropDown.NO,
      showAnnouncementModal: false,
      showAnnouncementFailureMsg: false,
      expandedDetails: [],
      announcementId: "",
      isRoleChecked: false,
      showDeleteAnnouncementModal: false,
      showWorkPlanSyncModal: false,
      wrikeSyncModalRule: "",
      wrikeSyncModalIsEnabled: false,
      isProjectPermissionsLoaded: false,
      handlerMode: "",
      checkInReminderCount: "",
      checkInClose: false,
      checkInExpirationPeriodText: "",
      showSharePointSettings: false,
      isChangeCategoryModalOpen: false,
      showAssistantModal: false,
      assistantConfigUrl: "",
    };
    window.addEventListener("message", (event) => {
      if (this.state.assistantConfigUrl) {
        const url = new URL(this.state.assistantConfigUrl);
        if (event.origin !== url.origin) return;
        if (event?.data === assistantCancelEvent) {
          this.setState({ showAssistantModal: false });
        }
      }
    });
  }

  componentDidMount() {
    const {
      record,
      appCategories,
      getAppCategories,
      apps,
      getApps,
      getProjectDetails,
      match,
      projectStatuses,
      getProjectStatusAction,
      getMyProjectRightRequest,
      projectCategories,
      getProjectCategories,
      getProjectSupportedFeatures,
      allAppPlatforms,
      getAppPlatforms,
      getActiveFeatures
    } = this.props;

    this.props.flushProjectDetails();
    !projectCategories.length && getProjectCategories();
    !projectStatuses.length && getProjectStatusAction();
    !appCategories.length && getAppCategories();
    !apps.length && getApps();
    !allAppPlatforms?.length && getAppPlatforms();
    match.params.id &&
      getMyProjectRightRequest({
        projectId: match.params.id,
      });
    match.params.id &&
      getProjectDetails({
        id: match.params.id,
      });
    match.params.id &&
      getProjectSupportedFeatures({
        id: match.params.id,
        key: FeatureSupportedKeys.Workflow,
      });
    match.params.id && getActiveFeatures({ id: match.params.id });
    this.getAnnouncementChunk();

    const appInsights = getAzureAppInsights();
    if (record && record.id && appInsights) {
      appInsights.updateProjectDetails({
        CapitalEdgeProjectFriendlyId: record.projectFriendlyId || "",
      });
    }

    this.checkForEMGPolicy();

    if (match.params.encodedParams) {
      try {
        const query = new URLSearchParams(
          window.atob(match.params.encodedParams)
        );
        this.queryParamsMode = query.get("mode") as string;
        if (
          this.queryParamsMode &&
          this.queryParamsMode.toLowerCase() ===
            HandlerMode.CLOSE_REMINDER.toLowerCase()
        ) {
          this.setState({
            handlerMode: this.queryParamsMode,
            checkInReminderCount: query.get("reminderCount") as string,
          });
        }
      } catch (e) {
        this.props.history.push("/");
      }
    }
    record && record.id && this.updatePageBrTitle();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      record,
      updating,
      updatingError,
      getProjectDetails,
      match,
      savedAnnouncement,
      flushAnnouncements,
      deletedAnnouncement,
      wrikeSyncData,
      isWrikeSyncUpdated,
      flushWrikeSyncNotification,
      checkToggleSyncStatus,
      myProjectRightsData,
      extendProjectExpirationResponse,
      extendProjectExpirationFlush,
      closeProjectResponse,
      closeProjectFlush,
      patchProjectData,
      projectCategories,
      showActionToast,
      accessRight,
    } = this.props;

    if (prevProps.record !== record && record?.id) {
      this.updatePageBrTitle();

      const assistantUrl = record?.copilotDetails?.siteURL || "";
      if (assistantUrl) {
        const assistantConfigUrl = assistantUrl + assistantSettingURl;
        this.setState({ assistantConfigUrl: assistantConfigUrl });
      }
    }

    if (prevProps.updating === true && updating === false) {
      let updateMessage = en_translation.project_app_update_successful;
      if (updatingError) {
        updateMessage = en_translation.project_app_update_error;
      }
      this.setState(
        {
          ...this.state,
          notificationMessage: updateMessage,
          showNotificationMessage: true,
        },
        () => {
          setTimeout(this.closeNotification, 3000);
          getProjectDetails({
            id: match.params.id,
          });
        }
      );
    }

    if (prevProps.savedAnnouncement !== savedAnnouncement) {
      flushAnnouncements();
      this.getAnnouncementChunk();
    }
    if (
      prevProps.deletedAnnouncement !== deletedAnnouncement &&
      deletedAnnouncement.announcementId
    ) {
      flushAnnouncements();
      this.getAnnouncementChunk();
    }

    const appInsights = getAzureAppInsights();
    if (prevProps.record !== record && record && record.id) {
      if (appInsights) {
        appInsights.updateProjectDetails({
          CapitalEdgeProjectFriendlyId: record.projectFriendlyId || "",
        });
      }
      this.checkForEMGPolicy();
    }

    if (prevProps.wrikeSyncData !== wrikeSyncData && wrikeSyncData) {
      this.handleWrikeSyncModalData();
    }

    if (
      prevProps.isWrikeSyncUpdated !== isWrikeSyncUpdated &&
      isWrikeSyncUpdated
    ) {
      setTimeout(() => flushWrikeSyncNotification(), 3000);
    }

    if (prevProps.record !== record) {
      if (record?.appPlatformDetails?.find((p) => p.name === "Wrike")) {
        match.params.id && checkToggleSyncStatus(match.params.id);
      }
    }

    if (
      prevProps.myProjectRightsData !== myProjectRightsData &&
      myProjectRightsData &&
      myProjectRightsData.projectId
    ) {
      this.setState({
        ...this.state,
        isProjectPermissionsLoaded: true,
      });
    }

    if (
      prevProps.extendProjectExpirationResponse !==
        extendProjectExpirationResponse &&
      extendProjectExpirationResponse.success
    ) {
      this.setState({ handlerMode: "" });
      this.props.history.push(`/project/${record?.id}`);
      setTimeout(extendProjectExpirationFlush, 3000);
    }

    if (
      prevProps.closeProjectResponse !== closeProjectResponse &&
      closeProjectResponse.success
    ) {
      this.props.history.push("/");
      setTimeout(closeProjectFlush, 3000);
    }

    // Set confirm and change category modal
    if (
      this.queryParamsMode &&
      this.queryParamsMode.toLowerCase() ===
        HandlerMode.ConfirmOrChangeCategory.toLowerCase() &&
      ((prevProps.record !== record && record?.id) ||
        (prevProps.record === record && prevProps.accessRight !== accessRight))
    ) {
      const selectedProjectCategory = projectCategories.find(
        (projectCategory) => projectCategory.id === record?.projectCategoryId
      );
      const projectAccess = accessRight?.projects?.find(
        (item) => item?.projectId === record?.id
      );

      const isProjectAdmin = this.isProjectAdmin();
      if (selectedProjectCategory && isProjectAdmin) {
        if (record && record.id && projectAccess?.hasAcceptedEMG) {
          this.setChangeOrConfirmCategoryModal(selectedProjectCategory, record);
          this.setState({
            isChangeCategoryModalOpen: true,
          });
        }
      } else {
        this.props.history.push("/");
      }
    }
    if (
      patchProjectData &&
      !_.isEqual(prevProps.patchProjectData, patchProjectData)
    ) {
      if (patchProjectData.success) {
        this.setState({
          isChangeCategoryModalOpen: false,
        });
        showActionToast({
          message: en_translation.confirm_project_category_success_message,
          type: "success",
        });
        this.props.history.push(`/project/${record?.id}`);
      } else if (!patchProjectData.success) {
        showActionToast({
          message: en_translation.generic_error_message,
          type: "error",
          redirectURL: "/",
        });
      }
    }
  }

  componentWillUnmount() {
    const { history } = this.props;
    this.props.flushProjectDetails();
    this.props.flushAnnouncements();
    this.props.flushToggleSyncStatus();
    if (!history.location.pathname.includes(RoutePathKeys.PROJECT)) {
      this.resetPageBrTitle();
    }
    if (this.messageTimer) {
      clearTimeout(this.messageTimer);
    }
  }

  checkForEMGPolicy = () => {
    const { accessRight, record, updateProjectDetailsForEMG } = this.props;
    checkAndLoadEMGPolicy(accessRight, updateProjectDetailsForEMG, record);
  };

  getAnnouncementChunk(pageNumber = 1) {
    const {
      match: { params },
      getAnnouncementsRequest,
    } = this.props;

    getAnnouncementsRequest({ projectId: params.id, pageNumber });
  }

  openPubUnpubAppDialog = (AppId: string) => {
    this.setState({
      ...this.state,
      showPubUnPubDialog: true,
      pubUnpubAppId: AppId,
    });
  };

  closePubUnpubDialog = () => {
    this.setState({
      ...this.state,
      pubUnpubAppId: "",
      showPubUnPubDialog: false,
    });
  };

  closeNotification = () => {
    this.setState({
      ...this.state,
      showNotificationMessage: false,
      notificationMessage: "",
    });
  };

  updateUpdatePubUnpAppStatus = () => {
    const { record, updateProjectAppPublish } = this.props;
    const app =
      record &&
      record.appDetails &&
      record.appDetails.find(
        (app) => app.appClassId === this.state.pubUnpubAppId
      );

    if (app && record) {
      updateProjectAppPublish({
        appClassId: app.appClassId,
        projectId: record.id,
        status: !app.isPublished,
      });
    }

    this.closePubUnpubDialog();
  };

  gotoManageUsers = () => {
    const { record, history } = this.props;
    sessionStorage.setItem(
      SessionKeys.HOME_PAGE_PROJECT_TILE_CLICK,
      record?.projectFriendlyId + ""
    );
    if (
      record?.projectStatus.projectStatusName.toLowerCase() !==
      ProjectStatus.DRAFT
    ) {
      history.push(`/project/${record?.id}/manage-users`);
    }
  };

  isUsageReportAvailable = () => {
    const { record } = this.props;
    if (
      record &&
      record?.projectReports?.find(
        (report) => report.key === PROJECT_USAGE_REPORT
      ) &&
      record?.projectStatus.projectStatusName.toLowerCase() !==
        ProjectStatus.DRAFT
    )
      return true;
    else return false;
  };

  gotoUsageReports = () => {
    const { record, history } = this.props;
    if (record) {
      const projectReport = record?.projectReports?.find(
        (report) => report.key === PROJECT_USAGE_REPORT
      );
      if (
        projectReport &&
        record?.projectStatus.projectStatusName.toLowerCase() !==
          ProjectStatus.DRAFT
      )
        history.push(
          `/project/${record?.id}/usage-reports/${projectReport?.pbiReportId}`
        );
    }
  };

  disabledManageUserBtn = () => {
    const { record } = this.props;
    if (
      record?.projectStatus.projectStatusName.toLowerCase() ===
      ProjectStatus.DRAFT
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleMoreMenuOptionClick = (
    actionName: string,
    id: string,
    appPlatformClassId: string
  ) => {
    const appCard =
      actionName && id
        ? this.props.record?.appDetails?.find((obj) => obj.appClassId === id)
        : null;
    const appplatform =
      actionName && id
        ? this.props.record?.appPlatformDetails?.find(
            (obj) => obj.appPlatformClassId === appCard?.appPlatformId
          )
        : null;
    const { record } = this.props;

    if (actionName === "open") {
      if (appplatform?.siteURL) {
        let url = appplatform.siteURL;
        if (appplatform.name.toUpperCase() !== gridAppPlatformKeys.WRIKE) {
          url = `${url}${appCard?.appURL || ""}`;
        }
        window.open(url, `${record?.id}_${appCard?.appClassName}`);
      }
    } else if (
      actionName === MenuActions.SETTINGS &&
      this.props.record?.appDetails?.find(
        (appDetail) => appDetail.appPlatformId === appPlatformClassId
      )?.key === gridAppPlatformKeys.SHARE_POINT
    ) {
      this.setState({
        showSharePointSettings: true,
        isSharepointSyncEnabled: record?.isSharePointSyncEnabled,
        sharepointSiteUrl: record?.appPlatformDetails?.find(
          (appPlatformDetail) =>
            appPlatformDetail.appPlatformClassId === appPlatformClassId
        )?.siteURL,
      });
    }
  };

  handleAnnouncementDetailsChange = ({
    target: { value: announcementDetails },
  }) => {
    this.setState({ announcementDetails });
  };

  handleAnnouncementNameChange = ({ target: { value: announcementName } }) => {
    this.setState({ announcementName });
  };

  handleAnnouncementPriority = (e) => {
    this.setState({ isImportantAnnoucment: e.target.value });
  };

  handleAnnouncementModalClose = () => {
    this.setState({
      announcementDetails: "",
      announcementName: "",
      isImportantAnnoucment: isImportantDropDown.NO,
      announcementId: "",
    });
    this.toggleAnnouncementModal(false);
  };

  renderAnnouncementDetailsInput = (label: string, value: string) => (
    <div className="project-form-field text-area-field announcement-textarea">
      <AppTextField
        maxLength={250}
        name={label}
        value={value}
        label={label}
        onChange={this.handleAnnouncementDetailsChange}
        isValid={!validateFormInputForSpecialChar(value)}
      />
      {validateFormInputForSpecialChar(value) && (
        <AppFormErrorText>
          {en_translation.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            value.trim().split("")[0]
          )}
        </AppFormErrorText>
      )}
    </div>
  );

  renderIsImportantDropdown = (title: string, fieldVal: string | undefined) => (
    <AppSelect
      id="announcement-priority"
      value={fieldVal || ""}
      name={title}
      label={title}
      options={announcementDropdownOptions}
      onChange={this.handleAnnouncementPriority}
    />
  );

  renderAnnouncememtNameInput = (label: string, fieldVal: string) => (
    <div className="project-form-field">
      <AppInput
        value={fieldVal}
        name={label}
        autoComplete="off"
        maxLength="50"
        label={label}
        onChange={this.handleAnnouncementNameChange}
        isValid={!validateFormInputForSpecialChar(fieldVal)}
      />
      {validateFormInputForSpecialChar(fieldVal) && (
        <AppFormErrorText>
          {en_translation.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            fieldVal.trim().split("")[0]
          )}
        </AppFormErrorText>
      )}
    </div>
  );

  renderCreateAnnouncementModal = () => {
    const { announcementDetails, announcementName, isImportantAnnoucment } =
      this.state;
    const {
      announcement_details_label,
      announcement_name_label,
      announcement_important_label,
    } = en_translation;

    return (
      <div>
        {this.renderAnnouncememtNameInput(
          announcement_name_label,
          announcementName
        )}
        {this.renderAnnouncementDetailsInput(
          announcement_details_label,
          announcementDetails
        )}
        {this.renderIsImportantDropdown(
          announcement_important_label,
          isImportantAnnoucment
        )}
      </div>
    );
  };

  toggleAnnouncementModal = (showAnnouncementModal) => {
    this.setState({ showAnnouncementModal });
  };

  toggleWorkPlanSyncModal = (showWorkPlanSyncModal) => {
    this.setState({ showWorkPlanSyncModal });
  };

  toggleDeleteAnnouncementModal = (showDeleteAnnouncementModal) => {
    this.setState({ showDeleteAnnouncementModal });

    if (!showDeleteAnnouncementModal) {
      this.setState({ announcementId: "" });
    }
  };

  handleAnnouncementSave = () => {
    const { record, createAnnouncementRequest, updateAnnouncementRequest } =
      this.props;
    const { announcementId } = this.state;

    if (!record) {
      return;
    }
    const { id: projectId } = record;
    const {
      announcementName: name,
      announcementDetails: details,
      isImportantAnnoucment,
      announcementId: id,
    } = this.state;
    const isImportant = isImportantAnnoucment === isImportantDropDown.YES;
    const announcementData = { projectId, name, details, isImportant };

    announcementId.length
      ? updateAnnouncementRequest({ ...announcementData, id })
      : createAnnouncementRequest(announcementData);
    this.handleAnnouncementModalClose();
  };

  isProjectAdmin = () => {
    const { record, sspPlatformRoles, accessRight } = this.props;
    return isProjectAdmin(record?.id || "", accessRight, sspPlatformRoles);
  };
  isProjectAdminUser = () => {
    const { record, sspPlatformRoles, accessRight } = this.props;
    return isProjectAdminUser(record?.id || "", accessRight, sspPlatformRoles);
  };
  isEyUser = () => {
    return isEyUser(this.props.accessRight);
  };

  isPortalAdmin = () => {
    const { accessRight, sspPlatformRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";

    return isPortalAdmin(portalRole, sspPlatformRoles);
  };

  isProjectUser = () => {
    const { record, sspPlatformRoles, accessRight } = this.props;
    return isProjectUser(
      record?.id || "",
      accessRight?.projects || [],
      sspPlatformRoles
    );
  };

  getAppDetails = (): AppDetail[] => {
    const { record } = this.props;
    let appDetails: AppDetail[] = [];
    if (record?.appDetails?.length) {
      appDetails = record.appDetails;
    }
    this.getRestrictedApps();
    return appDetails;
  };

  toggleAnnouncementDetails = (expandDetailsIndex, isExpanded) => {
    const { expandedDetails } = this.state;
    let newExpandedDetails;

    if (isExpanded) {
      newExpandedDetails = expandedDetails.filter(
        (detailsIndex) => detailsIndex !== expandDetailsIndex
      );
    } else {
      newExpandedDetails = [...expandedDetails, expandDetailsIndex];
    }

    this.setState({ expandedDetails: newExpandedDetails });
  };

  renderAnnouncementDetails = (announcementDetails, AnnouncementIndex) => {
    const isExpanded =
      this.state.expandedDetails.indexOf(AnnouncementIndex) >= 0;
    const isOverlapped =
      announcementDetails.length > ANNOUNCEMENT_DETAILS_MIN_LENGTH;

    return (
      <React.Fragment>
        {isExpanded
          ? announcementDetails
          : announcementDetails.substring(0, ANNOUNCEMENT_DETAILS_MIN_LENGTH)}
        {isOverlapped && ELLIPSIS}
        {isOverlapped && (
          <span
            className="details-btn"
            onClick={() =>
              this.toggleAnnouncementDetails(AnnouncementIndex, isExpanded)
            }
          >
            {isExpanded ? en_translation.show_less : en_translation.details}
          </span>
        )}
      </React.Fragment>
    );
  };

  handleAnnouncementAction = (action, announcement) => {
    if (action === en_translation.edit) {
      this.handleEditAnnouncement(announcement);
    }
    if (action === en_translation.delete) {
      this.setState({ announcementId: announcement.id });
      this.toggleDeleteAnnouncementModal(true);
    }
  };

  handleEditAnnouncement = ({
    id: announcementId,
    name: announcementName,
    details: announcementDetails,
    isImportant,
  }) => {
    const isImportantAnnoucment = isImportant
      ? isImportantDropDown.YES
      : isImportantDropDown.NO;

    this.setState({
      announcementName,
      announcementDetails,
      isImportantAnnoucment,
      announcementId,
      showAnnouncementModal: true,
    });
  };

  handleDeleteAnnouncement = () => {
    const {
      deleteAnnouncementRequest,
      match: { params },
    } = this.props;
    const { announcementId } = this.state;

    deleteAnnouncementRequest({ projectId: params.id, announcementId });
    this.toggleDeleteAnnouncementModal(false);
  };

  renderAnnouncementMoreMenu = (announcement, i) => {
    const { currentUser } = this.props;
    const isProjectAdmin = this.isProjectAdmin();
    const isAuthor = currentUser.id === announcement.authorId;

    if (!isAuthor && !isProjectAdmin) {
      return "";
    }

    const options: MoreMenuOption[] = [
      {
        id: "123",
        actionName: en_translation.edit,
        title: en_translation.edit,
      },
      {
        id: "124",
        actionName: en_translation.delete,
        title: en_translation.delete,
      },
    ];

    return (
      <MoreMenu
        controlId={`announcement-more-menu-${i}`}
        optionClickFn={(e) => this.handleAnnouncementAction(e, announcement)}
        options={options}
        stopEventBubbling
      />
    );
  };
  renderAnnouncements = () => {
    const { announcements, announcementPaginationData } = this.props;
    const { chunk, isAllAnnoucementsFetched } = announcementPaginationData;

    if (!announcements.length) {
      return (
        <div className="no-announcement">
          {en_translation.No_announcement_msg}
        </div>
      );
    }
    return (
      <InfiniteScroll
        dataLength={announcements.length}
        next={() => this.getAnnouncementChunk(chunk)}
        hasMore={!isAllAnnoucementsFetched}
        loader={announcements.length > 4 ? <h4>Loading...</h4> : ""}
        scrollableTarget={ANNOUNCEMENT_SCROLL_TARGET}
      >
        <div className="app-row announcements-row">
          {announcements.map((announcement, i) => (
            <div
              key={`announcment_${i}`}
              className="announcement app-col-xs-12 app-col-lg-12 app-col-xl-12"
            >
              {announcement.isImportant && (
                <span className="flag">
                  <AppIcon icon={flag} />
                </span>
              )}
              <span className="announcement-name">{announcement.name}</span>
              <div className="announcement-details">
                {this.renderAnnouncementDetails(announcement.details, i)}
              </div>
              <span className="announcement-author">{`${
                announcement.author
              } - ${getDateTime(announcement.modifiedDateTime)}`}</span>
              {this.renderAnnouncementMoreMenu(announcement, i)}
              <div className="announcement-separator"></div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  getEditBtnTooltipMsg = (isPending, isError, isProjectInError) => {
    if (isProjectInError) {
      return en_translation.edit_project_tooltip_message_error;
    } else if (isPending) {
      return en_translation.edit_project_tooltip_message;
    } else if (isError) {
      return en_translation.edit_project_platform_error;
    } else {
      return "";
    }
  };

  getRestrictedApps = () => {
    const { record, myProjectRightsData, currentUser } = this.props;
    const currentUserApps = _.find(myProjectRightsData.users, {
      mail: currentUser.mail,
    });
    if (currentUserApps && record) {
      record?.appDetails?.map((app) => {
        for (const appDetail of currentUserApps?.appPlatforms) {
          app.notRetricted = appDetail.apps.some((key) => key === app.key);
          if (app.notRetricted) {
            break;
          }
        }
        return app;
      });
    }
  };

  getWorkplanSyncStatus = () => {
    const { projectStatuses, record } = this.props;

    const ce4AppPlatform = record?.appPlatformDetails?.find(
      (p) => p.appPlatformClassId === Ce4Data.PLATFORM_ID
    );
    if (!ce4AppPlatform) {
      return false;
    }

    const ce4PlatformStatus = projectStatuses.find(
      (p) => p.id === ce4AppPlatform.statusId
    );

    return !!(
      ce4PlatformStatus && ce4PlatformStatus.key === EProjectStatuses.RDY
    );
  };

  handleAppCardMenuClick = () => {
    const { match, checkCe4ReadyStatus } = this.props;
    const isCE4PlatformReady = this.getWorkplanSyncStatus();

    if (isCE4PlatformReady) {
      return;
    }

    match.params.id &&
      checkCe4ReadyStatus({
        id: match.params.id,
      });
  };

  handleWorkPlanSyncClick = () => {
    const { getWrikeSyncSettingsRequest, match } = this.props;

    getWrikeSyncSettingsRequest(match.params.id);
    this.toggleWorkPlanSyncModal(true);
  };

  handleWrikeSyncModalData = () => {
    const {
      wrikeSyncData: { isEnabled: wrikeSyncModalIsEnabled, title },
    } = this.props;
    const wrikeSyncModalRule = JSON.stringify(title, null, 2) || "";

    this.setState({ wrikeSyncModalIsEnabled, wrikeSyncModalRule });
  };

  handleWrikeRuleChange = ({ target: { value: wrikeSyncModalRule } }) => {
    this.setState({ wrikeSyncModalRule });
  };

  handleWrikeConfigSave = () => {
    const { updateWrikeSyncSettingsRequest, match } = this.props;
    const { wrikeSyncModalIsEnabled, wrikeSyncModalRule } = this.state;
    const title = JSON.stringify(JSON.parse(wrikeSyncModalRule));

    updateWrikeSyncSettingsRequest({
      id: match.params.id,
      title,
      isEnabled: wrikeSyncModalIsEnabled,
    });
    this.toggleWorkPlanSyncModal(false);
  };

  renderWrikeSyncModalContent = () => {
    const { wrikeSyncModalIsEnabled, wrikeSyncModalRule } = this.state;
    const isValidRule = this.getValidRuleStatus();
    const worlPlanSyncOptions = [
      { value: isEnabledDropdown.YES, label: isEnabledDropdown.YES },
      { value: isEnabledDropdown.NO, label: isEnabledDropdown.NO },
    ];

    return (
      <>
        <div className="workplan-active">
          <div className="workplan-dropdown">
            <label>{en_translation.workplan_sync_active}</label>
            <AppSelect
              id="workplan-sync"
              value={
                wrikeSyncModalIsEnabled
                  ? isEnabledDropdown.YES
                  : isEnabledDropdown.NO
              }
              name={en_translation.workplan_sync_active}
              label=""
              options={worlPlanSyncOptions}
              onChange={(e) =>
                this.setState({
                  wrikeSyncModalIsEnabled:
                    e.target.value === isEnabledDropdown.YES,
                })
              }
            />
          </div>
          <span className="workplan-sync-info-msg">
            {en_translation.workplan_sync_msg}
          </span>
        </div>
        <div className="workplan-sync-rules">
          <label>{en_translation.workplan_sync_rules}</label>
          <textarea
            value={wrikeSyncModalRule}
            onChange={this.handleWrikeRuleChange}
          />
          {!isValidRule && (
            <span className="rule-error">
              {en_translation.workplan_sync_rule_error}
            </span>
          )}
        </div>
      </>
    );
  };

  getValidRuleStatus = () => {
    const { wrikeSyncModalRule } = this.state;

    try {
      JSON.parse(wrikeSyncModalRule);
      return true;
    } catch (error) {
      return false;
    }
  };

  onCloseProject = (id: string, feedback: FeedbackType) => {
    this.props.closeProject({ id, feedback });
  };

  getDigestPeriod = (period) => {
    const splits = period.split("-");
    const count = splits?.[0];
    const unit = splits?.[1];
    if (unit === ExpirationPeriodUnit.Month) {
      return `${count + " " + ExpirationPeriod.Month}${
        count === NumberCount._1 ? "" : "s"
      }`;
    } else if (unit === ExpirationPeriodUnit.Week) {
      return `${count + " " + ExpirationPeriod.Week}${
        count === NumberCount._1 ? "" : "s"
      }`;
    }
    return period;
  };

  isProjectDraft = (projectStatusName: string) => {
    return (
      projectStatusName &&
      projectStatusName.toLowerCase() === ProjectStatus.DRAFT
    );
  };
  isProjectActive = (projectStatusName: string) => {
    return (
      projectStatusName &&
      projectStatusName.toLowerCase() === ACTIVE_PROJECT_STATE.toLowerCase()
    );
  };

  getManageWorkflowsBtnTooltipMsg = (
    isDraft,
    isPending,
    isPlatformError,
    isProjectInError,
    isLegacyProject
  ) => {
    switch (true) {
      case isDraft:
        return en_translation.project_manage_workflows_draft_tooltip;

      case isProjectInError:
        return en_translation.project_manage_workflows_tooltip_error;

      case isPending:
        return en_translation.project_manage_workflows_tooltip_platform_inprogress;

      case isPlatformError:
        return en_translation.project_manage_workflows_platform_error;

      case isLegacyProject:
        return en_translation.project_manage_workflows_legacy_project;

      default:
        return "";
    }
  };

  getWorkflowButtonCheck = () => {
    const { projectStatuses, record } = this.props;
    return (
      isPlatformPending(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      ) ||
      isPlatformError(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      ) ||
      isPorjectErrored(projectStatuses, record?.projectStatus.id!) ||
      this.isProjectDraft(record?.projectStatus?.projectStatusName!) ||
      record?.accessRestrictionKey === PROJECT_STATUS_ALL
    );
  };

  setChangeOrConfirmCategoryModal = (
    selectedProjectCategory: lookupModel | undefined,
    record: Project
  ) => {
    const projectNameKey = "{|projectName|}";
    const projectCategoryKey = "{|projectCategoryName|}";
    if (
      selectedProjectCategory?.key === EProjectCategoryKey.CLIENG.toString()
    ) {
      this.hideModalFooterForChangeCategory = true;
      this.confirmOrChangeTitle =
        en_translation.change_project_category_title_changed;
      this.confirmOrChangeContent =
        en_translation.change_project_category_changed
          .replaceAll(projectNameKey, record.name)
          .replaceAll(projectCategoryKey, selectedProjectCategory?.name || "");
    } else if (record?.projectCategoryConfirmation) {
      this.hideModalFooterForChangeCategory = true;
      this.confirmOrChangeTitle =
        en_translation.change_project_category_title_confirmed;
      this.confirmOrChangeContent =
        en_translation.change_project_category_confirmed
          .replaceAll(projectNameKey, record.name)
          .replaceAll(projectCategoryKey, selectedProjectCategory?.name || "");
    } else {
      this.hideModalFooterForChangeCategory = false;
      this.confirmOrChangeTitle = en_translation.change_project_category_title;
      this.confirmOrChangeContent =
        en_translation.change_project_category_content
          .replaceAll(projectNameKey, record.name)
          .replaceAll(projectCategoryKey, selectedProjectCategory?.name || "");
    }
  };

  handleChangeProjectCategory = () => {
    const { record, history } = this.props;
    const mode = window.btoa("mode=confirmOrChangeCategory");
    history.push(`/project/${record?.id}/project-details/${mode}`);
  };

  handleConfirmProjectCategory = () => {
    const { record } = this.props;
    if (record && record.id) {
      this.setState({ isChangeCategoryModalOpen: false });
      const projectCategoryConfirmation = {
        categoryId: record.projectCategoryId,
      };
      this.props.patchProjectUpdateAction({
        projectId: record.id,
        projectCategoryConfirmation,
      });
    }
  };

  handleProjectCategoryCloseModal = () => {
    const { history, record } = this.props;
    this.setState({ isChangeCategoryModalOpen: false });
    history.push(`/project/${record?.id}`);
    this.queryParamsMode = "";
  };

  updatePageBrTitle() {
    const { record } = this.props;
    if (record && record.name) {
      setPageBrTitle(record.name);
    }
  }

  resetPageBrTitle() {
    setPageBrTitle();
  }

  handleIframeLoad = () => {
    if (this.messageTimer) {
      clearTimeout(this.messageTimer);
    }
    const iframe = this.sspIframeRef?.current;
    if (iframe) {
      const iframeWindow = iframe.contentWindow;
      if (iframeWindow) {
        this.messageTimer = setTimeout(() => {
          iframeWindow.postMessage(
            externalAppSettings,
            this.state.assistantConfigUrl
          );
        }, 1000);
      }
    }
  };

  render() {
    const {
      record,
      appCategories,
      projectStatuses,
      showAnnouncementFailure,
      showDeleteAnnouncementFailure,
      flushAnnouncementNotification,
      currentUser,
      myProjectRightsData,
      flushWrikeSyncNotification,
      isWrikeSyncUpdated,
      projectCategories,
      extendProjectExpirationResponse,
      isFeatureSupported,
      allAppPlatforms,
      activeFeatures,
    } = this.props;

    const {
      showPubUnPubDialog,
      pubUnpubAppId,
      notificationMessage,
      showNotificationMessage,
      showAnnouncementModal,
      announcementName,
      announcementDetails,
      showDeleteAnnouncementModal,
      showWorkPlanSyncModal,
      wrikeSyncModalIsEnabled,
      isProjectPermissionsLoaded,
      handlerMode,
      checkInReminderCount,
      checkInClose,
      checkInExpirationPeriodText,
      isChangeCategoryModalOpen,
      showAssistantModal,
      assistantConfigUrl,
    } = this.state;

    if (record && record.id) {
      let selectedApp: AppDetail | undefined;
      let pubUnpubDialogMessage = "";
      let pubUnpubDialogSubMessage = "";
      const appDetails = this.getAppDetails();
      if (pubUnpubAppId) {
        selectedApp = appDetails.find(
          (item) => item.appClassId === pubUnpubAppId
        );
        if (selectedApp && selectedApp.isPublished) {
          pubUnpubDialogMessage = en_translation.unpublish_confirm_message;
          pubUnpubDialogSubMessage =
            en_translation.unpublish_confirm_sub_message;
        } else {
          pubUnpubDialogMessage = en_translation.publish_confirm_message;
          pubUnpubDialogSubMessage = en_translation.publish_confirm_sub_message;
        }
      }

      const isProjectAdmin = this.isProjectAdmin();
      const showNoOfUserCount =
        this.isProjectAdmin() || this.isProjectAdminUser();
      const isEyUser = this.isEyUser();
      const isPending = isPlatformPending(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      );
      const isError = isPlatformError(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      );
      const isReady = isPlatformReady(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      );
      const isProjectInError = isPorjectErrored(
        projectStatuses,
        record?.projectStatus.id
      );
      const isDraft = this.isProjectDraft(
        record?.projectStatus?.projectStatusName
      );
      const isActive = this.isProjectActive(
        record?.projectStatus?.projectStatusName
      );
      let digestPeriod,
        showProjectAlreadyExtendedMessage = false;
      const isLegacyProject = isFeatureSupported === false;
      if (handlerMode === HandlerMode.CLOSE_REMINDER) {
        if (
          record.expirationDate &&
          (record.expirationDate as number) >=
            Date.parse(new Date().toUTCString()) &&
          record.projectStatus.projectStatusName === ACTIVE_PROJECT_STATE
        ) {
          showProjectAlreadyExtendedMessage = true;
        } else {
          let showExpirationModel = true;
          if (extendProjectExpirationResponse.success) {
            showExpirationModel = false;
          }
          this.props.showExpirationModel(showExpirationModel);

          const expirationPeriod =
            projectCategories.find((c) => c.id === record.projectCategoryId)
              ?.expirationPeriod ?? "???";
          digestPeriod = this.getDigestPeriod(expirationPeriod);
        }
      }
      const keysToKeep = [
        gridAppPlatformKeys.CE4.toLowerCase(),
        gridAppPlatformKeys.CFP.toLowerCase(),
      ];

      const _SharePointSettingEditable =
        isProjectAdmin &&
        isEyUser &&
        isPlatformReady(
          projectStatuses,
          // eslint-disable-next-line array-callback-return
          record?.appPlatformDetails?.filter((appPlatform) => {
            // business requirement is only when application platform cfp or ce4 is part of project
            // along SharePoint can let project admin to edit it
            if (
              record?.appDetails?.some(
                (el) => el.appPlatformId === appPlatform.appPlatformClassId
              )
            ) {
              return allAppPlatforms
                .filter((obj: AppPlatformClass) =>
                  keysToKeep.includes(obj.key.toLowerCase())
                )
                .map((obj: AppPlatformClass) => obj.id)
                .includes(appPlatform.appPlatformClassId);
            }
          }),
          record?.infrastructureStatusId
        );
      const showAssistantModalPopUp = (assistantFlag: boolean) => {
        this.setState({ showAssistantModal: assistantFlag });
      };
      const isManageAssistantEnabled = isFeatureEnabled(ProjectActiveFeatures.MANAGE_ASSISTANT, activeFeatures, undefined);
      const isAssistantEnabled = record?.copilotDetails?.isActive;
      const isProjectNonActive =
        this.getWorkflowButtonCheck() || !isActive || isLegacyProject;

      return (
        <CrashBoundary>
          <div className="home project-details">
            {/* co-pilot button */}
            {isEyUser && isActive && isAssistantEnabled && (
              <AssistantContainer
                {...record?.copilotDetails}
                isProjectNonActive={isProjectNonActive}
              />
            )}
            {/* co-pilot button */}
            <div className="head-section">
              <div className="page-breadcrumb back-link">
                <Link className="ml-5" to="/">
                  <AppIcon icon={back} className="icon-link" />{" "}
                  {en_translation.project_details_my_projects}
                </Link>
              </div>
              <div className="details-row">
                <div className="row">
                  <section
                    className={`content app-col-xs-4 app-col-lg-8 app-col-xl-8 ${
                      record.projectStatus.projectStatusName ===
                        ACTIVE_PROJECT_STATE || isReady
                        ? "border-right"
                        : ""
                    }`}
                  >
                    <h1 className="project-name">{record.name || "NA"}</h1>
                    <div className="p-l-25">
                      <div className="project-meta">
                        <div className="row">
                          <section className="col-xs-12 col-lg-3 col-xl-3">
                            <div className="project-details-card">
                              <div className="project-card-body">
                                <div className="project-item-title">
                                  {en_translation.project_home_client_name}
                                </div>
                                <div className="details-content">
                                  <div className="item-content">
                                    {record.clientName || "NA"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section className="col-xs-12 col-lg-3 col-xl-3">
                            <div className="project-details-card">
                              <div className="project-card-body">
                                <div className="project-item-title">
                                  {en_translation.project_details_meta_created}
                                </div>
                                <div className="details-content">
                                  <div className="item-content">
                                    {moment
                                      .utc(record.createdOn)
                                      .format("MMMM DD, yyyy")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          {showNoOfUserCount && (
                            <section className="col-xs-12 col-lg-3 col-xl-3">
                              <div className="project-details-card">
                                <div className="project-card-body">
                                  <div className="project-item-title">
                                    {
                                      en_translation.project_details_meta_no_of_users
                                    }
                                  </div>
                                  <div className="details-content">
                                    <div className="item-content">
                                      {(record?.projectStatus.projectStatusName.toLowerCase() !==
                                        ProjectStatus.DRAFT &&
                                        record.numberOfUsers) ||
                                        "NA"}
                                    </div>
                                    <AppTooltip
                                      hide={!this.disabledManageUserBtn()}
                                      title={
                                        this.disabledManageUserBtn()
                                          ? en_translation.project_setting_tooltip_message
                                          : ""
                                      }
                                      className="tooltip-spacing"
                                    >
                                      <button
                                        className="project-detail-action-link"
                                        onClick={this.gotoManageUsers}
                                        disabled={
                                          this.disabledManageUserBtn()
                                            ? true
                                            : false
                                        }
                                      >
                                        <AppIcon
                                          icon={edit}
                                          className="icon-link"
                                        />{" "}
                                        {en_translation.step_three_title}
                                      </button>
                                    </AppTooltip>
                                  </div>
                                </div>
                              </div>
                            </section>
                          )}
                          {isProjectAdmin && this.isUsageReportAvailable() && (
                            <section className="col-xs-12 col-lg-3 col-xl-3">
                              <div className="project-details-card">
                                <div className="project-card-body">
                                  <div className="project-item-title">
                                    {
                                      en_translation.project_details_meta_project_usage
                                    }
                                  </div>
                                  <div className="details-content">
                                    <button
                                      className="project-detail-action-link"
                                      onClick={this.gotoUsageReports}
                                      disabled={!this.isUsageReportAvailable()}
                                    >
                                      <AppIcon
                                        icon={chartLine}
                                        className="icon-link"
                                      />{" "}
                                      {
                                        en_translation.project_details_meta_usage_report
                                      }
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </section>
                          )}
                        </div>
                      </div>

                      <div className="egagement-tags">
                        {record.description || ""}
                      </div>
                      {projectStatuses.length && (
                        <div className="btn-align">
                          {isProjectAdmin && (
                            <div className="project_settings btn">
                              <AppTooltip
                                hide={
                                  !isDraft &&
                                  !isPending &&
                                  !isError &&
                                  !isProjectInError &&
                                  !isLegacyProject
                                }
                                title={this.getManageWorkflowsBtnTooltipMsg(
                                  isDraft,
                                  isPending,
                                  isError,
                                  isProjectInError,
                                  isLegacyProject
                                )}
                              >
                                <Link
                                  to={
                                    isProjectNonActive
                                      ? "#"
                                      : `/project/${record?.id}/manage-workflows/workflows/definitions`
                                  }
                                >
                                  <AppButton
                                    className={
                                      isProjectNonActive
                                        ? "manage-user-btn-disabled"
                                        : "manage-user-btn"
                                    }
                                    color="secondary"
                                  >
                                    <AppIcon
                                      icon={network}
                                      className="icon-link"
                                    />
                                    {
                                      en_translation.project_details_meta_workflows
                                    }
                                  </AppButton>
                                </Link>
                              </AppTooltip>
                            </div>
                          )}

                          {isProjectAdmin && (
                            <div className="project_settings btn m-l-10">
                              <AppTooltip
                                placement="bottom"
                                hide={
                                  !isPending && !isProjectInError && !isError
                                }
                                title={this.getEditBtnTooltipMsg(
                                  isPending,
                                  isError,
                                  isProjectInError
                                )}
                              >
                                <Link
                                  to={
                                    isPending || isError || isProjectInError
                                      ? "#"
                                      : `/project/${record?.id}/project-details`
                                  }
                                >
                                  <AppButton
                                    className={
                                      isPending || isError || isProjectInError
                                        ? "manage-user-btn-disabled"
                                        : "manage-user-btn"
                                    }
                                    color="secondary"
                                    size="large"
                                  >
                                    <AppIcon
                                      icon={edit}
                                      className="icon-link"
                                    />
                                    {en_translation.project_settings}
                                  </AppButton>
                                </Link>
                              </AppTooltip>
                            </div>
                          )}
                          {isManageAssistantEnabled && isEyUser && isProjectAdmin && (
                            <div
                              className="project_settings btn m-l-10"
                              onClick={() =>
                                !isProjectNonActive &&
                                showAssistantModalPopUp(true)
                              }
                            >
                              <div>
                                <AppButton
                                  className={
                                    isProjectNonActive
                                      ? "manage-user-btn-disabled"
                                      : "manage-user-btn"
                                  }
                                  color="secondary"
                                  size="large"
                                >
                                  <AppIcon
                                    icon={wrench}
                                    className="icon-link"
                                  />
                                  {en_translation.manage_assistant}
                                </AppButton>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </section>
                  <AppModal
                    title={en_translation.manage_assistant}
                    showModal={showAssistantModal}
                    showDividers={false}
                    onModalClose={() => showAssistantModalPopUp(false)}
                    size="md"
                    children={
                      assistantConfigUrl ? (
                        <iframe
                          ref={this.sspIframeRef}
                          title="assistantConfig"
                          id="assistantConfig"
                          className="assistantConfig"
                          src={assistantConfigUrl}
                          style={{ border: "none" }}
                          height="400px"
                          width="100%"
                          onLoad={this.handleIframeLoad}
                        ></iframe>
                      ) : (
                        "Failed to load assistant configurations"
                      )
                    }
                  />
                  {(record.projectStatus.projectStatusName ===
                    ACTIVE_PROJECT_STATE ||
                    isReady) && (
                    <section className="announcement-section app-col-xs-4 app-col-lg-4 app-col-xl-4">
                      <div className="app-row">
                        <h1 className="announcement-title app-col-xs-4 app-col-lg-8 app-col-xl-8">
                          {en_translation.announcement_title}
                        </h1>
                        <div className="announcement-actions app-col-xs-4 app-col-lg-4 app-col-xl-4">
                          <span
                            className="create-announcement"
                            onClick={() => {
                              this.toggleAnnouncementModal(true);
                            }}
                          >
                            {en_translation.announcement_create_btn_text}
                          </span>
                        </div>
                      </div>
                      <div
                        id={ANNOUNCEMENT_SCROLL_TARGET}
                        className="display-announcement"
                      >
                        {this.renderAnnouncements()}
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
            <div className="row app-tiles">
              {!!projectStatuses.length &&
                // eslint-disable-next-line
                appDetails?.map((bundle, key) => {
                  if (appDetails) {
                    const appStatus = getAppStatus(
                      projectStatuses,
                      record.appPlatformDetails,
                      bundle.appPlatformId
                    );

                    return (
                      <section
                        className="col-xs-4 col-lg-3 col-xl-3 m-l-10"
                        key={key}
                      >
                        <AppCard
                          key={bundle.appClassId}
                          onPublishStatusUpdate={this.openPubUnpubAppDialog}
                          moreMenuEnabled={true}
                          moreMenuSettingsOptionEnabled={
                            bundle.key === gridAppPlatformKeys.SHARE_POINT
                          }
                          appCategories={appCategories}
                          descriptionEnabled={true}
                          showDemoBtn={false}
                          showPublishStatus={true}
                          isProjectAdmin={isProjectAdmin}
                          isEyUser={isEyUser}
                          isPortalAdmin={this.isPortalAdmin()}
                          handleMoreMenuOptionClick={
                            this.handleMoreMenuOptionClick
                          }
                          appStatus={appStatus}
                          projectUserName={record?.createdByUserName}
                          projectEmailAddress={record?.createdByUserEmail}
                          currentUser={currentUser}
                          workplanSyncEnabled={this.getWorkplanSyncStatus()}
                          onAppCardMenuclick={this.handleAppCardMenuClick}
                          myProjectRightsData={myProjectRightsData}
                          onWorkPlanSyncClick={this.handleWorkPlanSyncClick}
                          data={{
                            id: bundle.appClassId,
                            name: bundle.appClassName,
                            appCategoryId: bundle.appCategoryId,
                            appPlatformClassId: bundle.appPlatformId,
                            appRelativeURL: "",
                            channelOnePermittedFlag: "",
                            isPublished: bundle.isPublished,
                            demoSiteURL: "",
                            description: bundle?.appDescription || "",
                            key: bundle.key || "",
                            appURL: bundle.appURL,
                            notRetricted: bundle.notRetricted,
                            isAppLoaded: isProjectPermissionsLoaded,
                          }}
                        />
                      </section>
                    );
                  }
                })}
            </div>
            <AppModal
              title={en_translation.update_app}
              showModal={showPubUnPubDialog}
              cancelBtnText={en_translation.cancel}
              confirmBtnText={en_translation.confirm}
              onConfirm={this.updateUpdatePubUnpAppStatus}
              onModalClose={this.closePubUnpubDialog}
              children={
                <div>
                  <div>{pubUnpubDialogMessage}</div>
                  <div>{pubUnpubDialogSubMessage}</div>
                </div>
              }
            />
            <div className="announcement-modal">
              <AppModal
                title={en_translation.announcement_modal_title}
                showModal={showAnnouncementModal}
                cancelBtnText={en_translation.cancel}
                confirmBtnText={en_translation.announcement_save_btn_text}
                onConfirm={this.handleAnnouncementSave}
                onModalClose={this.handleAnnouncementModalClose}
                disabledValue={
                  !announcementName.trim().length ||
                  validateFormInputForSpecialChar(announcementName) ||
                  validateFormInputForSpecialChar(announcementDetails)
                }
              >
                {this.renderCreateAnnouncementModal()}
              </AppModal>
            </div>
            <div className="delete-announcement-modal">
              <AppModal
                title={en_translation.delete}
                showModal={showDeleteAnnouncementModal}
                cancelBtnText={en_translation.cancel}
                confirmBtnText={en_translation.delete_draft_modal_delete_btn}
                onConfirm={() => this.handleDeleteAnnouncement()}
                onModalClose={() => this.toggleDeleteAnnouncementModal(false)}
              >
                <p>{en_translation.delete_announcement_modal_msg}</p>
              </AppModal>
            </div>
            <div>
              <AppModal
                title={en_translation.work_plan_sync}
                showModal={showWorkPlanSyncModal}
                cancelBtnText={en_translation.cancel}
                confirmBtnText={en_translation.save}
                onConfirm={this.handleWrikeConfigSave}
                onModalClose={() => this.toggleWorkPlanSyncModal(false)}
                disabledValue={!this.getValidRuleStatus()}
                modalClass={"workplan-sync-modal"}
              >
                {this.renderWrikeSyncModalContent()}
              </AppModal>
            </div>
            {showNotificationMessage && (
              <AppNotification
                message={notificationMessage}
                onCloseNotifcation={this.closeNotification}
                variant={
                  notificationMessage ===
                  en_translation.project_app_update_successful
                    ? "success"
                    : "error"
                }
              />
            )}
            {(showAnnouncementFailure || showDeleteAnnouncementFailure) && (
              <AppNotification
                message={
                  showDeleteAnnouncementFailure
                    ? en_translation.announcement_delete_failure_msg
                    : en_translation.announcement_create_failure_msg
                }
                onCloseNotifcation={flushAnnouncementNotification}
                variant="error"
              />
            )}
            {isWrikeSyncUpdated && (
              <div className="work-plan-notification">
                <AppNotification
                  message={
                    wrikeSyncModalIsEnabled
                      ? en_translation.workplan_sync_enable_msg
                      : en_translation.workplan_sync_disable_msg
                  }
                  onCloseNotifcation={() => flushWrikeSyncNotification()}
                  variant="success"
                />
              </div>
            )}
            {extendProjectExpirationResponse.success && (
              <AppNotification
                message={`${
                  extendProjectExpirationResponse.data
                    ? en_translation.project_extended.replaceAll(
                        "{|period|}",
                        checkInExpirationPeriodText
                      )
                    : en_translation.project_extended_failed
                }`}
                variant={
                  extendProjectExpirationResponse.data ? "success" : "error"
                }
                onCloseNotifcation={extendProjectExpirationFlush}
              />
            )}
            {handlerMode === HandlerMode.CLOSE_REMINDER &&
              record &&
              (showProjectAlreadyExtendedMessage ? (
                <AppNotification
                  variant="info"
                  message={en_translation.project_already_extended_message.replaceAll(
                    "{|projectName|}",
                    record.name
                  )}
                  onCloseNotifcation={() => {
                    this.setState({ ...this.state, handlerMode: "" });
                    this.props.history.push(`/project/${record.id}`);
                  }}
                />
              ) : (
                <ExpiryDecisionModal
                  project={record}
                  digestPeriod={digestPeriod}
                  count={checkInReminderCount ?? "unknown"}
                  onExtend={(id: string) => {
                    this.props.showExpirationModel(false);
                    this.setState({
                      checkInClose: false,
                      checkInExpirationPeriodText: digestPeriod,
                    });
                    this.props.extendProjectExpiration({ payload: { id } });
                  }}
                  onCloseProject={() => this.setState({ checkInClose: true })}
                  onClose={() => {
                    this.props.showExpirationModel(false);
                    this.setState({ ...this.state, handlerMode: "" });
                    this.props.history.push(`/project/${record.id}`);
                  }}
                />
              ))}
            {record && record.id && (
              <CloseProjectPopup
                show={checkInClose}
                onClosePopup={() => this.setState({ checkInClose: false })}
                isPricingFeedback={
                  record.projectCategoryId === EProjectCategory.CLI_ENG
                }
                priced={record.provisioningQuestionsDetails?.isPricingIncluded}
                onProjectClose={(feedback) =>
                  this.onCloseProject(record.id as string, feedback)
                }
              />
            )}
          </div>

          {this.state.showSharePointSettings && (
            <SharePointSettings
              sharePointBaseUrl={this.state.sharepointSiteUrl}
              projectId={record.id}
              isEditable={_SharePointSettingEditable}
              onClose={() => {
                this.setState({
                  showSharePointSettings: false,
                  sharepointSiteUrl: undefined,
                  isSharepointSyncEnabled: undefined,
                });
              }}
              sync={this.state.isSharepointSyncEnabled}
            />
          )}
          {isChangeCategoryModalOpen && (
            <AppModal
              modalClass="change-or-confirm-project-category"
              showModal={isChangeCategoryModalOpen}
              onModalClose={this.handleProjectCategoryCloseModal}
              cancelBtnText={en_translation.cancel}
              cancelBtnVariant={"text"}
              title={this.confirmOrChangeTitle}
              hideFooterSection={this.hideModalFooterForChangeCategory}
              additionalBtns={
                <React.Fragment>
                  <AppButton
                    type="button"
                    color="secondary"
                    onClick={this.handleChangeProjectCategory}
                  >
                    {en_translation.change}
                  </AppButton>
                  <AppButton
                    type="button"
                    onClick={this.handleConfirmProjectCategory}
                  >
                    {en_translation.confirm}
                  </AppButton>
                </React.Fragment>
              }
            >
              <div
                className="modal-content"
                dangerouslySetInnerHTML={{
                  __html: this.confirmOrChangeContent,
                }}
              ></div>
            </AppModal>
          )}
        </CrashBoundary>
      );
    } else {
      return null;
    }
  }
}

const ExpiryDecisionModal = ({
  project,
  digestPeriod,
  count,
  onClose,
  onCloseProject,
  onExtend,
}: {
  project: Project;
  digestPeriod: string;
  count: string;
  onClose: () => void;
  onCloseProject: () => void;
  onExtend: (id: string) => void;
}) => {
  const checkInCount = ((count) => {
    switch (count) {
      case NumberCount._1:
        return CheckInTime.First;
      case NumberCount._2:
        return CheckInTime.Second;
      case NumberCount._3:
        return CheckInTime.Third;
      default:
        return count;
    }
  })(count);

  let checkInContentDuration = digestPeriod;
  if (digestPeriod === NumberCount._3 + " " + ExpirationPeriod.Month + "s") {
    checkInContentDuration = ExpirationPeriod.Quarterly;
  } else if (digestPeriod === NumberCount._1 + " " + ExpirationPeriod.Week) {
    checkInContentDuration = ExpirationPeriod.Weekly;
  }

  const digestContent = en_translation.project_checkIn_content
    .replaceAll("{|projectName|}", project.name)
    .replaceAll("{|period|}", checkInContentDuration);
  const digestNote =
    count === NumberCount._3
      ? en_translation.project_last_checkIn_note.replaceAll(
          "{|projectName|}",
          project.name
        )
      : en_translation.project_checkIn_note
          .replaceAll("{|count|}", checkInCount)
          .replaceAll("{|projectName|}", project.name);

  return (
    <AppModal
      showModal={true}
      title={en_translation.project_checkIn}
      modalClass="check-in-modal"
      onModalClose={onClose}
    >
      <>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: digestContent }}
        ></div>
        <div className="btn-container">
          <AppButton
            className="app-button btn-green"
            variant="contained"
            onClick={() => onExtend(project.id as string)}
            size="small"
          >
            {en_translation.project_checkIn_extend.replaceAll(
              "{|duration|}",
              digestPeriod
            )}
          </AppButton>
          <AppButton
            className="app-button btn-red"
            variant="contained"
            onClick={onCloseProject}
            size="small"
          >
            {en_translation.project_checkIn_close}
          </AppButton>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: digestNote }}
        ></div>
      </>
    </AppModal>
  );
};

const mapStateToProps = ({
  projects,
  meta,
  projectStatusReducer,
  meReducer,
  projectCategoriesReducer,
  createProjectReducer,
}) => {
  return {
    record: projects.record,
    appCategories: meta.appCategories,
    apps: meta.apps,
    updating: projects.updating,
    updatingError: projects.updatingError,
    projectStatuses: meta.projectStatuses || projectStatusReducer.projectStatus,
    showAnnouncementFailure: projects.showAnnouncementFailure,
    sspPlatformRoles: meta.userRoles,
    announcements: projects.announcements,
    announcementPaginationData: projects.announcementPaginationData,
    accessRight: meReducer.accessRight,
    savedAnnouncement: projects.savedAnnouncement,
    currentUser: meReducer.currentUser,
    paansPolicy: meReducer.paansPolicy,
    myProjectRightsData: projects.myProjectRightsData,
    deletedAnnouncement: projects.deletedAnnouncement,
    showDeleteAnnouncementFailure: projects.showDeleteAnnouncementFailure,
    wrikeSyncData: projects.wrikeSyncData,
    isWrikeSyncUpdated: projects.isWrikeSyncUpdated,
    isToggleSyncApiAvailable: projects.isToggleSyncApiAvailable,
    projectCategories: projectCategoriesReducer.projectCategories,
    extendProjectExpirationResponse: projects.extendProjectExpirationResponse,
    closeProjectResponse: createProjectReducer.closeProject,
    isFeatureSupported: projects.isFeatureSupported,
    patchProjectData: projects.patchProjectData,
    allAppPlatforms: meta.appPlatforms,
    activeFeatures: projects.activeFeatures,
  };
};

const mapDispatchToProps = {
  getProjectDetails,
  updateProjectAppPublish,
  getAppCategories,
  getApps,
  getProjectStatusAction,
  createAnnouncementRequest,
  flushAnnouncementNotification,
  getAnnouncementsRequest,
  flushAnnouncements,
  updateAnnouncementRequest,
  flushProjectDetails,
  getMyProjectRightRequest,
  deleteAnnouncementRequest,
  updateProjectDetailsForEMG,
  getWrikeSyncSettingsRequest,
  updateWrikeSyncSettingsRequest,
  flushWrikeSyncNotification,
  checkCe4ReadyStatus,
  checkToggleSyncStatus,
  flushToggleSyncStatus,
  getProjectCategories: getProjectCategoriesAction,
  extendProjectExpiration: extendProjectExpirationRequest,
  extendProjectExpirationFlush: extendProjectExpirationFlush,
  closeProject: closeProjectRequest,
  closeProjectFlush: flushCloseProject,
  showExpirationModel,
  getProjectSupportedFeatures,
  patchProjectUpdateAction,
  showActionToast,
  getAppPlatforms: getAppPlatforms,
  getActiveFeatures: getProjectActiveFeatures
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
