import React from "react";
import { connect } from 'react-redux';
import { ClearButton, AsyncTypeahead } from 'react-bootstrap-typeahead';
import { search } from "../../../theme/icons";
import { IUser } from '../../../models/user';
import { AppIcon } from "../../../theme";
import { searchAdminUserGrid } from '../../../store/actions/admin-users-actions';

interface IProps {
    searchUserData: IUser[];
    fetchSearchUserList: Function;
    searchLoader: boolean;
    onUserSelect: Function;
    clearOnSelect?: boolean;
    placeholder?: string
}

class ListSearchUserEditor extends React.Component<IProps> {
    typeAheadRef = React.createRef<AsyncTypeahead<IUser>>()
    renderMenuItem = (option, data) => {
        return (
          <li className="async-dropdown-modal">
            <div className="async-dropdown-name-modal">{option.givenName} {option.surname}</div>
            <div>{option.mail}</div>
          </li>
        )
      }

    handleChange = (selectedOption) => {
        const { onUserSelect, clearOnSelect } = this.props;

        onUserSelect(selectedOption);
        if(!clearOnSelect || !this.typeAheadRef) {
          return
        }
        
        // @ts-ignore
        this.typeAheadRef.current.clear();
    }

    render() {
            const { searchUserData, fetchSearchUserList, searchLoader, placeholder } = this.props;

            return (
            <div className="search-wrapper">
              <div className="project-form-field">
                <AppIcon icon={search} />
                <AsyncTypeahead
                  id="async-search"
                  ref={this.typeAheadRef}
                  isLoading={searchLoader}
                  filterBy={['givenName', 'surname', 'mail']}
                  labelKey={option => `${option.givenName} ${option.surname}`}
                  minLength={3}
                  onSearch={query => fetchSearchUserList(query)}
                  options={searchUserData}
                  onChange={(selectedOption) => this.handleChange(selectedOption)}
                  placeholder={placeholder || `Search user`}
                  useCache={false}
                  renderMenuItemChildren={(option, props) => this.renderMenuItem(option, props)}>
                  {({ onClear, selected }) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                    </div>
                  )}
                </AsyncTypeahead>
              </div>
            </div>)
    }
}

const mapStateToProps = ({ adminUsersReducer }) => {
    return {
      searchUserData: adminUsersReducer.adminSearchUserGrid.data,
      searchLoader: adminUsersReducer.searchLoader,
    };
  };

const mapDispatchToProps = dispatch => {
    return {
      fetchSearchUserList: payload => { dispatch(searchAdminUserGrid(payload)) },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(ListSearchUserEditor);
