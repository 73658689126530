import React, { Component } from "react";
import * as _ from "lodash";
import {
  AppDatePicker,
  AppFormErrorText,
  AppIcon,
  AppInput,
  AppModal,
  AppSelect,
  AppTextField,
  AppTooltip
} from "../../theme";
import t from "../../localization/en/translation.json";
import { lookupModel } from "../../containers/createProject/lookupModel";
import { InputFieldsType } from "../adminWorkflows/adminWorkflowConstant";
import { validateFormInputForSpecialChar } from "../../utils/helper-utility";
import { DATE_TIME_SCHEDULER } from "../adminAddEventModal/adminAddEventModalConstants";
import "./genericFormModal.scss";
import AppNumberPicker from "../appNumberPicker/appNumberPicker";
import { info } from "../../theme/icons";

export interface IForm {
  fieldLabel: string;
  fieldName: string;
  fieldVal: string;
  isvalid?: boolean;
  charLimit: number;
  disable?: boolean;
  className?: string;
  isHidden?: boolean;
  isTouched?: boolean;
  isRequired?: boolean;
  multiple?: boolean;
  fieldType?: string;
  fieldOptions?: [] | null;
  fieldOnChangeMapping?: string[];
  isValidSpecialChar?: boolean;
}

interface IProps {
  title: string;
  isOpen: boolean;
  isEdit: boolean;
  handleConfirm: Function;
  formFields: IForm[];
  onModalClose: Function;
}

interface IState {
  formFields: IForm[];
  isFormValid: boolean;
}

class GenericFormModal extends Component<IProps, IState> {
  validatedInputClass = "validated-input";
  constructor(props) {
    super(props);
    this.state = {
      formFields: this.props.formFields,
      isFormValid: false,
    };
  }
  onConfirm = () => {
    const { formFields } = this.state;
    this.props.handleConfirm(formFields);
  };

  onClose = () => {
    this.props.onModalClose(true);
  };

  onFormChange = (item: IForm, val) => {
    const { formFields } = this.state;
    const idx = _.findIndex(formFields, { fieldName: item.fieldName });
    this.handleFormChange(formFields, val, idx);
    this.setState({
      ...this.state,
      formFields: formFields,
      isFormValid: this.checkDirtyFields(),
    });
  };

  handleFormChange = (formFields: IForm[], val: string, idx: number) => {
    if (_.isNumber(idx)) {
      formFields[idx]["fieldVal"] = val;
      formFields[idx]["isTouched"] = true;

      if (formFields[idx]["isRequired"]) {
        formFields[idx]["isvalid"] =
          !_.isNil(val) && val.toString().trim().length > 0;
      }
      if (formFields[idx]["isValidSpecialChar"]) {
        formFields[idx]["isvalid"] = !validateFormInputForSpecialChar(val);
      }
      if (
        formFields[idx]["isRequired"] &&
        formFields[idx]["isValidSpecialChar"]
      ) {
        formFields[idx]["isvalid"] =
          !_.isNil(val) &&
          val.toString().trim().length > 0 &&
          !validateFormInputForSpecialChar(val);
      }

      if (formFields[idx]["fieldOnChangeMapping"]?.length) {
        const selectedOption = _.find(formFields[idx].fieldOptions, {
          id: formFields[idx]["fieldVal"],
        });
        if (selectedOption) {
          _.forEach(formFields[idx]["fieldOnChangeMapping"], (key) => {
            if (_.has(selectedOption, key) && !_.isNil(selectedOption[key])) {
              const formField = _.find(formFields, ["fieldName", key]);
              if (formField) {
                formField.fieldVal = selectedOption[key];
              }
            }
          });
        }
      }
    }
  };

  checkDirtyFields = () => {
    const { formFields } = this.state;
    const checkValidform = _.findIndex(formFields, (formField: IForm) => {
      return (
        (formField?.isRequired &&
          (formField?.isvalid !== true || formField?.isTouched !== true)) ||
        (formField?.isValidSpecialChar && formField?.isvalid !== true)
      );
    });
    return checkValidform < 0;
  };

  renderInput = (item: IForm, index: number) => (
    <div
      className={`project-form-field ${
        item.fieldVal?.toString().trim().length && item.isvalid
          ? this.validatedInputClass
          : ""
      }`}
    >
      <AppInput
        key={`app-input-${index}`}
        value={item.fieldVal}
        name={item.fieldName}
        maxLength={item?.charLimit}
        autoComplete="off"
        disabled={item?.disable}
        label={item.fieldLabel}
        isValid={
          item.fieldName !== "sendFromEmail" &&
          item.fieldName !== "sendToEmail" &&
          item.fieldName !== "communicationEmailSubject" &&
          item.fieldName !== "id"
            ? !validateFormInputForSpecialChar(item.fieldVal)
            : item?.isvalid
        }
        onChange={(e) => this.onFormChange(item, e.target.value)}
      />
      {item?.isTouched &&
      item?.isRequired &&
      !item.isvalid &&
      !validateFormInputForSpecialChar(item?.fieldVal) ? (
        <AppFormErrorText>{t.required_field}</AppFormErrorText>
      ) : item.fieldName !== "sendFromEmail" &&
        item.fieldName !== "sendToEmail" &&
        item.fieldName !== "communicationEmailSubject" &&
        item.fieldName !== "id" &&
        validateFormInputForSpecialChar(item?.fieldVal) ? (
        <AppFormErrorText>
          {t.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            item["fieldVal"].trim().split("")[0]
          )}
        </AppFormErrorText>
      ) : (
        ""
      )}
    </div>
  );

  getOptions = (options: lookupModel[] = []) =>
    options.map((item) => ({
      value: item.id,
      label: item.name || item?.title,
    }));

  getMultiOptions = (options: lookupModel[] = []) =>
    options.map((item) => item.name);

  renderSelect = (item: IForm, index: number) => (
    <div
      className={`project-form-field ${
        item.fieldVal && item.isvalid ? this.validatedInputClass : ""
      }`}
    >
      <AppSelect
        key={`app-select-${index}`}
        id={index.toString()}
        value={item.fieldVal}
        disabled={item.disable}
        name={item.fieldName}
        label={item?.fieldLabel}
        multiple={item.multiple}
        options={
          item.multiple
            ? this.getMultiOptions(item.fieldOptions || [])
            : this.getOptions(item.fieldOptions || [])
        }
        onChange={(e) => this.onFormChange(item, e.target.value)}
      />
      {item?.isTouched && item?.isRequired && !item.isvalid && (
        <AppFormErrorText>{t.required_field}</AppFormErrorText>
      )}
    </div>
  );

  getTooltipHtml = (text: string) => {
    let lines = text?.split('\n');
    let tooltipText = lines?.map((line, index) => {
      return (
        <span key={index} onClick={(e) => { e.stopPropagation(); }}>
          {line}
          <br />
        </span>
      );
    });

    return tooltipText;
  }

  renderTextArea = (item, index) => {
    let tooltipText;
    if (item && item.tooltip) {
      tooltipText = this.getTooltipHtml(item.tooltip);
    }

    return (
      <AppTextField
        key={`textField-${index}`}
        maxLength={item.charLimit}
        name={item.fieldName}
        value={item.fieldVal}
        disabled={item.disable}
        label={
          (
            tooltipText?.length > 0 ?
              <React.Fragment>
                {item?.fieldLabel}
                <span className="tool-tip">
                  <AppTooltip
                    placement="top"
                    title={tooltipText}
                    interactive
                    PopperProps={{
                      disablePortal: true
                    }}
                    isWhite>
                    <AppIcon icon={info} />
                  </AppTooltip>
                </span>
              </React.Fragment> : item?.fieldLabel)
        }
        onChange={(e) => this.onFormChange(item, e.target.value)}
      />
    )
  };

  handleSetHours = (item, dateTime) => {
    let date = item.fieldVal;
    if (date && _.isNumber(dateTime)) {
      date = new Date(date);
      date.setHours(dateTime);
      this.onFormChange(item, date);
    }
  };

  handleSetMinutes = (item, dateTime) => {
    let date = item.fieldVal;
    if (date && _.isNumber(dateTime)) {
      date = new Date(date);
      date.setMinutes(dateTime);
      this.onFormChange(item, date);
    }
  };

  renderDateTimePicker = (item, index) => {
    let date = item.fieldVal;
    let hrs, mins;
    if (date) {
      date = new Date(date);
      hrs = date.getHours();
      mins = date.getMinutes();
    }
    return (
      <div
        className={`schedule-duration ${
          item?.fieldType === InputFieldsType.DateTime ? "margin-date-time" : ""
        }`}
      >
        <div className="project-form-field">
          <AppDatePicker
            key={`date-picker-${index}`}
            label={item.fieldLabel}
            value={date}
            onChange={(date) => this.onFormChange(item, date)}
            format={DATE_TIME_SCHEDULER.format}
          />
        </div>
        {item?.fieldType === InputFieldsType.DateTime && (
          <AppNumberPicker
            value={hrs}
            min={DATE_TIME_SCHEDULER.minTime}
            max={DATE_TIME_SCHEDULER.maxHrs}
            onChange={(hrs) => this.handleSetHours(item, hrs)}
          />
        )}
        {item?.fieldType === InputFieldsType.DateTime && (
          <span className="seprator">:</span>
        )}
        {item?.fieldType === InputFieldsType.DateTime && (
          <AppNumberPicker
            value={mins}
            min={DATE_TIME_SCHEDULER.minTime}
            max={DATE_TIME_SCHEDULER.maxMinutes}
            onChange={(mins) => this.handleSetMinutes(item, mins)}
          />
        )}
      </div>
    );
  };

  renderField = (item: IForm, index) => {
    if (item?.fieldType === InputFieldsType.Select) {
      return this.renderSelect(item, index);
    } else if (item?.fieldType === InputFieldsType.TextArea) {
      return this.renderTextArea(item, index);
    } else if (
      item?.fieldType === InputFieldsType.Date ||
      item?.fieldType === InputFieldsType.DateTime
    ) {
      return this.renderDateTimePicker(item, index);
    } else {
      return this.renderInput(item, index);
    }
  };

  render() {
    const { title, isOpen, isEdit } = this.props;
    const { formFields, isFormValid } = this.state;

    return (
      <AppModal
        size="md"
        showModal={isOpen}
        onModalClose={() => this.onClose()}
        onConfirm={() => this.onConfirm()}
        disabledValue={!isFormValid}
        cancelBtnText={t.cancel}
        confirmBtnText={isEdit ? t.update : t.add}
        title={isEdit ? `${t.update} ${title}` : `${t.add} ${title}`}
      >
        <div className="admin-generic-modal">
          <div className="app-row">
            {formFields.map((item, index) =>
              !item?.isHidden ? (
                  <div
                    className={`${item?.className ? item?.className : "app-col-xs-2"
                      }`}
                    key={index}
                  >
                    {this.renderField(item, index)}
                  </div>
              ) : null
            )}
          </div>
        </div>
      </AppModal>
    );
  }
}

export default GenericFormModal;
