import {
  faQuestionCircle,
  faUser,
  faTimesCircle,
  faCopy,
  faPlayCircle,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import {
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faGlobe,
  faShieldAlt,
  faFlag,
  faUserCircle,
  faInfoCircle,
  faCheck,
  faFile,
  faUpload,
  faSearch,
  faClipboardCheck,
  faCloudUploadAlt,
  faCheckCircle,
  faDownload,
  faExpand,
  faCompress,
  faPlus,
  faArrowLeft,
  faPen,
  faTrash,
  faRedo,
  faSortAmountUp,
  faSortAmountDown,
  faBan,
  faLock,
  faFileAlt,
  faCaretRight,
  faExclamationTriangle,
  faExclamationCircle,
  faChartLine,
  faNetworkWired,
  faLongArrowAltLeft,
  faList,
  faClock,
  faPlay,
  faBell,
  faAngleDown,
  faCogs,
  faEye,
  faHandHoldingHeart,
  faComments,
  faExternalLinkAlt,
  faMicroscope,
  faWrench,
  faExpandAlt,
  faCompressAlt
} from "@fortawesome/free-solid-svg-icons";

export const cross = faTimes;
export const rightArrow = faChevronRight;
export const leftArrow = faChevronLeft;
export const upArrow = faChevronUp;
export const downArrow = faChevronDown;
export const globe = faGlobe;
export const confidential = faShieldAlt;
export const helpCircle = faQuestionCircle;
export const user = faUser;
export const flag = faFlag;
export const userImage = faUserCircle;
export const info = faInfoCircle;
export const check = faCheck;
export const file = faFile;
export const upload = faUpload;
export const search = faSearch;
export const clipboardCheck = faClipboardCheck;
export const cloudUpload = faCloudUploadAlt;
export const checkCircle = faCheckCircle;
export const download = faDownload;
export const crossCircle = faTimesCircle;
export const expand = faExpand;
export const compress = faCompress;
export const add = faPlus;
export const copy = faCopy;
export const play = faPlayCircle;
export const back = faArrowLeft;
export const edit = faPen;
export const trash = faTrash;
export const refresh = faRedo;
export const sortUp = faSortAmountUp;
export const sortDown = faSortAmountDown;
export const dnd = faBan;
export const lock = faLock;
export const fileDescription = faFileAlt;
export const envelope = faEnvelope;
export const caretRight = faCaretRight;
export const warning = faExclamationTriangle;
export const exclamation = faExclamationCircle;
export const chartLine = faChartLine;
export const network = faNetworkWired;
export const arrowLeft = faLongArrowAltLeft;
export const list = faList;
export const clock = faClock;
export const bell = faBell;
export const angleDown = faAngleDown;
export const cogs = faCogs;
export const playIconWithoutCircle = faPlay;
export const view = faEye;
export const handHoldingHeart = faHandHoldingHeart;
export const comments = faComments;
export const externalLink = faExternalLinkAlt;
export const scope = faMicroscope;
export const wrench = faWrench;
export const expandAlt = faExpandAlt;
export const compressAlt = faCompressAlt;
