import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { dnd, sortDown, sortUp, trash } from '../../../theme/icons';
import {
  MIN_FILTER_CHAR_LIMIT,
  FILTER_TYPE,
  RADIO_OPTIONS,
  SORT_ORDER,
  DATE_FORMAT,
} from './listConstants';
import { AppButton, AppIcon } from '../../../theme';
import t from '../../../localization/en/translation.json';

interface IProps {
  resetFilters: boolean;
  field: string;
  sortable: boolean;
  onFilterSortChange: Function;
  onClearThis: Function;
  onClearAllFilter: Function;
  onFilterTextChange: Function;
  onFilterValueChange: Function;
  filterType?: string;
  filterState: any;
  minFilterCharLimit?: number;
}

interface IState {
  filterText: string;
  filterDate: string;
  selectedOption: string;
}

const disableOption = 'disable-option';

class ListFilter extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      filterDate: '',
      selectedOption: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { resetFilters } = this.props;

    if (prevProps.resetFilters !== resetFilters) {
      this.resetAll();
    }
  }

  resetAll = () => {
    this.setState({ filterDate: '', filterText: '', selectedOption: '' });
  };

  handleClearThis = () => {
    const { field, onClearThis } = this.props;

    this.resetAll();
    onClearThis(field);
  };

  handleFilterChange = (filterText, filterColumn) => {
    const { minFilterCharLimit } = this.props;
    let charLimit = minFilterCharLimit || MIN_FILTER_CHAR_LIMIT;

    this.setState({ filterText });
    if (filterText.length && filterText.length < charLimit) {
      return;
    }

    this.props.onFilterTextChange(filterText, filterColumn);
  };

  validateTextInput = () => {
    const { filterText } = this.state;
    const { minFilterCharLimit } = this.props;
    let charLimit = minFilterCharLimit || MIN_FILTER_CHAR_LIMIT;

    if (filterText.length >= charLimit) {
      return '';
    }

    return (
      <div className='search-error'>
        {t.list_custom_filter_min_char.replaceAll(
          '{|minCharLimit|}',
          charLimit.toString()
        )}
      </div>
    );
  };

  handleDateChange = (filterDate, filterColumn) => {
    const filterText = `${moment(filterDate, DATE_FORMAT)
      .startOf('day')
      .utc()
      .valueOf()},${moment(filterDate, DATE_FORMAT)
      .endOf('day')
      .utc()
      .valueOf()}`;

    this.props.onFilterValueChange(filterText, filterColumn);
  };

  handleRadioChange = (selectedOption, filterColumn) => {
    this.setState({ selectedOption });
    const option = selectedOption === RADIO_OPTIONS.yes;
    const optionValue = option.toString();

    this.props.onFilterValueChange(optionValue, filterColumn);
  };

  renderTextFilter = () => {
    const { filterText } = this.state;
    const { field } = this.props;

    return (
      <span>
        <input
          value={filterText}
          className='search-input'
          placeholder={t.list_custom_filter_search}
          onChange={(e) => this.handleFilterChange(e.target.value, field)}
          type='text'
        />
        {!!filterText.length && this.validateTextInput()}
      </span>
    );
  };

  renderDateFilter = () => {
    const { filterDate } = this.state;
    const { field } = this.props;

    return (
      <div className='list-date-filter-container'>
        <input
          className='list-date-filter'
          type='date'
          value={filterDate}
          onChange={({ target }) => this.setState({ filterDate: target.value })}
        />
        <div className='list-date-buttons'>
          <AppButton
            disabled={!filterDate.trim().length}
            onClick={() => this.handleDateChange(filterDate, field)}
          >
            {t.update}
          </AppButton>
          <AppButton onClick={this.handleClearThis}>{t.cancel}</AppButton>
        </div>
      </div>
    );
  };

  renderBooleanFilter = () => {
    const { field } = this.props;
    const { selectedOption } = this.state;

    return (
      <div className='filter-radio-group'>
        <h4 className='filter-radio-title'>{t.list_custom_radio_title}</h4>
        <span className='filter-radio-option'>
          <input
            type='radio'
            onChange={() => this.handleRadioChange(RADIO_OPTIONS.yes, field)}
            id='active'
            name='banner_status'
            value='active'
            checked={selectedOption === RADIO_OPTIONS.yes}
          />
          <label>{RADIO_OPTIONS.yes}</label>
        </span>
        <span className='status-radio-option'>
          <input
            type='radio'
            onChange={() => this.handleRadioChange(RADIO_OPTIONS.no, field)}
            id='inactive'
            name='banner_status'
            value='inActive'
            checked={selectedOption === RADIO_OPTIONS.no}
          />
          <label>{RADIO_OPTIONS.no}</label>
        </span>
      </div>
    );
  };

  renderFilter = () => {
    const { filterType } = this.props;

    if (filterType === FILTER_TYPE.date) {
      return this.renderDateFilter();
    }

    if (filterType === FILTER_TYPE.boolean) {
      return this.renderBooleanFilter();
    }

    if (filterType === FILTER_TYPE.none) {
      return;
    }

    return this.renderTextFilter();
  };

  getFilterClasses = () => {
    const { filterType } = this.props;
    const { filterDate, filterText, selectedOption } = this.state;

    if (filterType === FILTER_TYPE.date) {
      return !filterDate.length ? disableOption : '';
    }

    if (filterType === FILTER_TYPE.boolean) {
      return !selectedOption.length ? disableOption : '';
    }

    return !filterText.length ? disableOption : '';
  };

  getSortBtnClasses = (sortOrder) => {
    const { filterState, field } = this.props;

    if (!filterState || filterState.sortColumn !== field) {
      return '';
    }

    return filterState.sortDirection === sortOrder ? disableOption : '';
  };

  render() {
    const {
      onFilterSortChange,
      onClearAllFilter,
      sortable,
      field,
      filterState,
    } = this.props;

    return (
      <div className='custom-filter ag-custom-component-popup'>
        <h3>
          {sortable
            ? t.list_custom_sort_filter_title
            : t.list_custom_filter_title}
        </h3>
        <ul>
          {/* {sortable && (
            <li
              className={this.getSortBtnClasses(SORT_ORDER.asc)}
              onClick={() => onFilterSortChange(field, SORT_ORDER.asc)}
            >
              <AppIcon icon={sortUp} />
              {t.list_custom_filter_asc}
            </li>
          )}
          {sortable && (
            <li
              className={this.getSortBtnClasses(SORT_ORDER.desc)}
              onClick={() => onFilterSortChange(field, SORT_ORDER.desc)}
            >
              <AppIcon icon={sortDown} />
              {t.list_custom_filter_desc}
            </li>
          )} */}
          <li
            className={this.getFilterClasses()}
            onClick={() => this.handleClearThis()}
          >
            <AppIcon icon={trash} />
            {t.list_custom_filter_clear}
          </li>
          <li
            className={filterState?.isClearAllDisabled ? disableOption : ''}
            onClick={() => onClearAllFilter()}
          >
            <AppIcon icon={dnd} />
            {t.list_custom_filter_clear_all}
          </li>
          <li>{this.renderFilter()}</li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ listReducer }) => {
  return {
    filterState: listReducer.filterState,
  };
};

export default connect(mapStateToProps)(ListFilter);
