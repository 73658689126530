import React from 'react';
import { AppDetail } from '../../../components/createProject/models/appDetail';
import { AppPlatformClass } from '../../../components/appCard/models/appPlatformClass';
import t from '../../../localization/en/translation.json';
import _ from 'lodash';
import { lookupModel } from '../../createProject/lookupModel';
import { isAppActive } from '../../../utils/helper-utility';
import { AppPlatformDetail } from '../../../components/createProject/models/appPlatformDetail';
import { AppIcon, AppTooltip } from '../../../theme';
import { Project } from '../../../components/createProject/models/project';
import { info } from '../../../theme/icons';
import { user as userIcon } from '../../../theme/icons';
import { CustomCellRendererProps } from 'ag-grid-react';
import Select from 'react-select/src/Select';

export const EY_MEMBER = 'MEMBER';
export const EXTERNAL_USER = 'EXTERNAL';

export const UserType = {
  Internal: 'internal',
  External: 'external',
};

export const USER_ACTIONS = {
  UPDATE: 2,
  DELETE: 3,
};

export interface IGridColumn {
  headerName: string;
  field: string;
  cellRenderer?: any;
  width: number;
  headerComponentParams?: any;
  headerComponent?: string;
  pinned?: string;
  id?: string;
  multiSelect?: boolean;
  isSelectDisabled?: boolean;
  projectAdmin?: boolean;
  platformActive?: boolean;
  lockVisible?: boolean;
  lockPinned?: boolean;
  lockPosition?: boolean;
  suppressMovable?: boolean;
  cellRendererParams?: any;
}

export const getGridColumns = (
  projectAppList: AppDetail[],
  appPlatformList: AppPlatformClass[],
  projectStatuses: lookupModel[],
  appPlatformDetails: AppPlatformDetail[],
  isProjectAdmin: boolean,
  isProjectAdminUser: boolean,
  isEyUser: boolean,
  project: Project | undefined,
  onPlatformRoleChange: Function,
  platformRoleFilter: Function,
  renderSspRoleDropdown: Function,
  deleteUserAction: Function,
  renderPlatformHeader: any,
) => {
  const additionalPlatforms: IGridColumn[] = [];
  const sideSpaceMargin = 48 * 2;
  const leftFixedColumnWidth = 350;
  const rightFixedColumnWidth = 200;
  const borderWidthAdjustment = 20;
  const maxWidth = window.innerWidth;

  const groupedApps = _.groupBy(
    projectAppList,
    (item: AppDetail) => item.appPlatformId
  );

  const maxAppsColumnWidth =
    maxWidth -
    sideSpaceMargin -
    leftFixedColumnWidth -
    rightFixedColumnWidth -
    borderWidthAdjustment;
  const appPlatformCount = Object.keys(groupedApps).length;
  const calculatedMaxAppsColumnWidth = Math.max(
    150,
    Math.ceil(maxAppsColumnWidth / appPlatformCount)
  );

  gridAppPlatform.forEach((gridPlatform) => {
    const appPlatform = appPlatformList.find(
      (appPlatform: AppPlatformClass) => {
        return appPlatform.key === gridPlatform.key;
      }
    );

    if (appPlatform) {
      if (Object.keys(groupedApps).find((item) => item === appPlatform.id)) {
        const groupPlatformApps = groupedApps[appPlatform.id].sort(
          (appPlatformOne, appPlatformTwo) => {
            if (appPlatformOne.appClassName < appPlatformTwo.appClassName) {
              return -1;
            }
            return 1;
          }
        );

        const isAppPlatformActive = isAppActive(
          projectStatuses,
          appPlatformDetails,
          appPlatform.id
        );
        let headerAppsCount;

        if (
          groupPlatformApps.length &&
          appPlatform.key !== gridAppPlatformKeys.WRIKE &&
          appPlatform.key !== gridAppPlatformKeys.SHARE_POINT
        ) {
          const appsName = groupPlatformApps.map((item) => item.appClassName);

          headerAppsCount = (
            <AppTooltip placement='top' title={appsName.join(', ')} isWhite>
              <span className='apps-count'>{groupPlatformApps.length}</span>
            </AppTooltip>
          );
        }

        const hdrTitleWidth = headerAppsCount
          ? calculatedMaxAppsColumnWidth - 29 - 20
          : calculatedMaxAppsColumnWidth - 10;

        let header =
          gridPlatform.key === gridAppPlatformKeys.SHARE_POINT ? (
            <span
              className='hdr-title hdr-sharepoint-title'
              style={{ maxWidth: `${hdrTitleWidth}px` }}
            >
              {groupPlatformApps[0].appClassName} &nbsp;
              <AppTooltip
                title={
                  project?.isSharePointSyncEnabled
                    ? t.sharePointSyncEnabledTitle
                    : t.sharePointSyncDisabledTitle
                }
                placement={'top'}
                className={'roles-selector sharepoint-tooltip'}
                isWhite
              >
                <AppIcon style={{ top: 2, position: 'relative' }} icon={info} />
              </AppTooltip>
            </span>
          ) : (
            <span
              className='hdr-title'
              style={{ maxWidth: `${hdrTitleWidth}px` }}
            >
              {appPlatform.name}
            </span>
          );

        if (!headerAppsCount) {
          let showToolTip = false;
          const ctx = document.createElement('canvas').getContext('2d');
          const maxWidth = hdrTitleWidth;
          if (ctx) {
            ctx.font = '600 12px EYInterstate-Bold';
            const text = ctx.measureText(groupPlatformApps[0].appClassName);
            showToolTip = text.width > maxWidth;
          }

          if (showToolTip) {
            header = (
              <AppTooltip
                placement='top'
                className='roles-selector'
                title={groupPlatformApps[0].appClassName}
                isWhite
              >
                {header}
              </AppTooltip>
            );
          }
        }
        //either ProjectAdminUser(External or internal) OR ProjectAdmin(Always be internal)
        const canUpdatePlatformRoles =
          isProjectAdminUser || (isProjectAdmin && isEyUser);
        additionalPlatforms.push({
          headerName: '',
          lockPinned: true,
          field: gridPlatform.key,
          width: calculatedMaxAppsColumnWidth,
          id: appPlatform.id,
          platformActive: !isAppPlatformActive,
          isSelectDisabled:
            !isAppPlatformActive || (!isProjectAdmin && !isEyUser),
          projectAdmin: !isProjectAdmin && !isEyUser,
          multiSelect: appPlatform.isMultiRole,
          headerComponent: renderPlatformHeader,
          headerComponentParams: {
            isAppPlatformActive,
            header,
            headerAppsCount,
          },
          cellRenderer: 'defaultSelectCellRenderer',
          cellRendererParams: {
            data: {
              options: [],
              multiple: appPlatform.isMultiRole,
              placeholder: 'None', // when SharePoint Sync is enable, then we need to disable role edit
              isSelectDisabled:
                !isAppPlatformActive ||
                !canUpdatePlatformRoles ||
                (gridPlatform.key === gridAppPlatformKeys.SHARE_POINT &&
                  project?.isSharePointSyncEnabled),
              filterOptions: platformRoleFilter,
            },
            selectWidth: Math.min(250, calculatedMaxAppsColumnWidth),
            hideSelect: !isAppPlatformActive,
            additionalProps: {
              key: gridPlatform.key,
              multiSelect: appPlatform.isMultiRole,
              id: appPlatform.id,
            },
            OnValueChange: onPlatformRoleChange,
            selectValueFieldName: gridPlatform.key,
          },
        });
      }
    }
  });

  const sspPlatform = appPlatformList.find(
    (appPlatform: AppPlatformClass) =>
      appPlatform.key === gridAppPlatformKeys.SSP
  );

  const pinnedObject = appPlatformCount ? { pinned: 'left' } : {};

  const uderDetailsCell = ({ data }: CustomCellRendererProps) => {
    const user = data?.userDetails;
    return (
      <div className={`user-details`}>
        <div className='user-name ellipsis'>{`${user.givenName} ${user.surname}`}</div>
        <div className='user-type'>
          <AppIcon className='user-icon' icon={userIcon} /> {user.userType}
        </div>
        <div className='ellipsis'>{user.officeLocation}</div>
        <div className='ellipsis'>{user.mail}</div>
      </div>
    );
  };


  return [
    {
      headerName: t.manage_users_user_details_placeholder,
      cellRenderer: uderDetailsCell,
      field: 'userDetails',
      width: 240,
      ...pinnedObject,
      id: 'userDetails',
      lockPosition: true,
    },
    {
      headerName: t.manage_users_ssp_placeholder,
      field: 'ssp',
      width: 240,
      multiSelect: sspPlatform?.isMultiRole || false,
      ...pinnedObject,
      id: 'ssp',
      isSelectDisabled: !isProjectAdmin && !isEyUser,
      lockPosition: true,
      cellRenderer: renderSspRoleDropdown,
    },
    ...additionalPlatforms,
    {
      headerName: t.manage_users_action_placeholder,
      cellRenderer: deleteUserAction,
      field: 'actions',
      width: 109,
      pinned: 'right',
      suppressMovable: true,
      id: 'actions',
    },
  ];
};

export enum AppPlatforms {
  Cfp = 'CFP',
  Wrike = 'Wrike',
  Tp = 'Transaction Periscope',
  Ce4 = 'Capital Edge 4',
  SharePoint = 'SHAREPOINT',
}

export const gridAppPlatformKeys = {
  WRIKE: 'WRIKE',
  CE4: 'CE4',
  TP: 'TP',
  CFP: 'CFP',
  SHARE_POINT: 'SHAREPOINT',
  SSP: 'ssp',
};

export const gridAppPlatform = [
  {
    key: gridAppPlatformKeys.WRIKE,
    searchIngroupedApps: true,
    multiselect: false,
  },
  {
    key: gridAppPlatformKeys.CE4,
    searchIngroupedApps: true,
    multiselect: false,
  },
  {
    key: gridAppPlatformKeys.TP,
    searchIngroupedApps: true,
    multiselect: false,
  },
  {
    key: gridAppPlatformKeys.CFP,
    searchIngroupedApps: true,
    multiselect: true,
  },
  {
    key: gridAppPlatformKeys.SHARE_POINT,
    searchIngroupedApps: false,
    multiselect: true,
  },
  {
    key: gridAppPlatformKeys.SSP,
    searchIngroupedApps: false,
    multiselect: false,
  },
];

export interface IbulkImportTemplateResponse {
  success: boolean;
  error: boolean;
  data: {
    fileContents: string;
  };
}
