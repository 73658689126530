import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import _ from 'lodash';
import * as actionstype from '../../store/actions/meta-actions';
import { AppClass } from '../../components/appCard/models/appClass';
import { AppPlatformClass } from '../../components/appCard/models/appPlatformClass';
import { BundleClass } from '../../components/bundleCard/models/bundleClass';
import { Id } from '../../components/bundleCard/models/id';
import BundleAppSelect from '../../components/createProject/bundleAppSelect/bundleAppSelect';
import { AppDetail } from '../../components/createProject/models/appDetail';
import { AppPlatformDetail } from '../../components/createProject/models/appPlatformDetail';
import { IPpeddApprover, Project } from '../../components/createProject/models/project';
import { ProjectStatus } from '../../components/createProject/models/projectStatus';
import { ProjectDetailsModel } from '../../components/projectDetails/projectDetailsModel';
import ProjectFooter from '../../components/projectFooter/projectFooter';
import ProjectForm, { isDontDeleteTagPresent } from '../../components/projectForm/projectForm';
import StepIndicator, { Status } from '../../components/stepIndicator/stepIndicator';
import AppNotification from '../../components/appNotification/appNotification';
import RouteLeavingGuard from '../../components/routeLeavingGuard/routeLeavingGuard';
import t from '../../localization/en/translation.json';
import { searchProjectsAction, clearSearchedProjectsAction } from '../../store/actions/search-projects-actions';
import { getProjectCategoriesAction } from '../../store/actions/project-categories-actions';
import { getClientSizesAction } from '../../store/actions/client-sizes-actions';
import { clearProjectDataAction, createProjectAction, updateProjectAction, flushCreatedBundle, getUpdatedBundles } from '../../store/actions/create-project-actions';
import { getCountriesCodeAction, getDataLocationsAction } from '../../store/actions/data-locations-actions';
import { getProjectStatusAction } from '../../store/actions/project-status-actions';
import { getProjectTypesAction } from '../../store/actions/project-types-actions';
import { getSectorsAction } from '../../store/actions/sectors-actions';
import { searchUsersAction } from '../../store/actions/user-actions';
import { getMyAccessRight } from '../../store/actions/me-actions';

import { getProjectDetails, flushProjectDetails, getProjectActiveFeatures } from '../../store/actions/project-actions';
import CustomizeBundle from '../customizeBundle/customizeBundle';
import { BundleStateModel } from './bundlesStateModel';
import { IFormModel, IPaceFieldState, EProjectCategory, IApproverFieldState, EProjectCategoryKey, IStringFieldState, IGeoFieldState, IBooleanFieldState } from './formStateModel';
import { CountryLookupModel, DataLocationLookupModel, lookupModel, PaceProjectModel, RegionLookupModel, IServiceLines } from './lookupModel';
import ConfirmAndCreateProject from '../../components/confirmAndCreateProject/confirmAndCreateProject';
import { AppCategory } from '../../components/appCard/models/appCategory';
import { EProjectStatuses, HandlerMode, ProjectActiveFeatures, ProjectSteps, RoutePathKeys, UserTypes } from '../../utils/Constants';
import { ProjectStatus as ProjectStatusName } from '../../components/projectCard/projectCardConstants';
import { APPROVER_SORT_COLUMN, MIN_PROJECT_NAME_LENGTH, PAGE_SIZE } from './createProjectConstants';
import { IProjectIcon } from '../../models/IProjectIcon';
import { getAzureAppInsights, isFeatureEnabled, isPlatformError, isPlatformPending, isPorjectErrored, isPortalAdmin, setPageBrTitle, validateFormInputForSpecialChar } from '../../utils/helper-utility';
import { updateProjectDetailsForEMG } from '../../store/actions/emg-policy-actions';
import { IAccessRight, IUser } from '../../models/user';
import { checkAndLoadEMGPolicy } from '../../components/emgPolicy/constants';
import { gridAppPlatformKeys } from '../manageUsers/listUsers/listUserConstants';
import { AppModal } from '../../theme';
import './createProject.scss';
import { ISSPPlatformRole } from '../../models/IRoles';
import { CrashBoundary } from '../../components/CrashBoundary';
import { showActionToast } from '../../store/actions/notification-actions';
import Loader from '../../components/loader/loader';

type RouteParams = { id?: string; encodedParams?: string };
type IProps = RouteComponentProps<RouteParams> & {
  clearProjectData(): void;
  getProjectTypes(): void;
  getSectors(): void;
  getClientSizes(): void;
  getDataLocations(): void;
  getChannels(): void;
  getProjectStatus(): void;
  createProject(payload: Project): void;
  updateProject(payload: Project): void;
  flushCreatedBundle(): void;
  fire_getCountriesCodeAction(): void;
  getProjectCategories: Function;
  projectTypes: lookupModel[];
  sectors: lookupModel[];
  serviceLines: IServiceLines[];
  clientSizes: lookupModel[];
  dataLocations: DataLocationLookupModel[];
  projectAreas: lookupModel[];
  projectRegions: RegionLookupModel[];
  projectCountries: CountryLookupModel[];
  projectCategories: lookupModel[];
  projectStatus: lookupModel[];
  createProjectData: { id: string };
  updateProjectData: { id: string };
  errorOnCreateOrUpdate: null | string | {};
  loading: boolean;
  getBundleCards: () => BundleClass[];
  bundles: BundleClass[];
  apps: AppClass[];
  record?: Project;
  getProjectDetails: Function;
  flushProjectDetails: Function;
  getMyAccessRight: Function;
  getUpdatedBundles: (bundles: BundleClass[]) => BundleClass[];
  masterBundles: BundleClass[];
  getAppPlatforms: () => AppPlatformClass[];
  getAppCards: () => AppClass[];
  getAppCategories: () => AppCategory[];
  appPlatforms: AppPlatformClass[];
  appCategories: AppCategory[];
  searchProjects: Function;
  clearSearchedProjects: Function;
  searchLoader: boolean;
  searchProjectsData: PaceProjectModel[];
  projectIcons: IProjectIcon[];
  getProjectIcons: Function;
  accessRight: IAccessRight;
  updateProjectDetailsForEMGAction: Function;
  searchUsers: Function;
  searchUsersLoader: boolean;
  searchUsersData: IPpeddApprover[];
  sspRoles: ISSPPlatformRole[];
  getActiveFeatures: Function;
  activeFeatures: string[];
  isCreateProjectDisabled: boolean;
  isMaintanenceFlagLoaded: boolean;
  showActionToast: Function;
  currentUser: IUser;
};

interface IState {
  formState: IFormModel;
  paceFieldState: IPaceFieldState;
  stepIndicatorState: {
    currentStep: number;
    step1Progress: Status;
    step2Progress: Status;
    step3Progress: Status;
  };
  disableCreateBtn: boolean;
  isFormValid: boolean;
  disableContinueBtn: boolean;
  customizeModeuleEnabled: boolean;
  disableSaveBtn: boolean;
  showError: boolean;
  isDirty: boolean;
  isPaceProjectModalOpen: boolean;
  showServiceLineModal: boolean;
  showOnceConfirmModal: boolean;
  approverFieldState: IApproverFieldState;
  userServiceLineId: string;
  projectPermittedServiceLines: IServiceLines[];
  isChangeCategoryLoading: boolean;
}

interface IAppCardAndPlatformDetail {
  appDtls: AppDetail[];
  appPlatformDtls: AppPlatformDetail[];
}

export enum FormFields {
  projectName = 'projectName',
  projectType = 'projectType',
  projectClientName = 'projectClientName',
  projectSector = 'projectSector',
  projectServiceLine = 'projectServiceLine',
  projectSize = 'projectSize',
  projectLocation = 'projectLocation',
  projectDesc = 'projectDesc',
  projectMFAEnabled = 'projectMFAEnabled',
  projectConfidential = 'projectConfidential',
  projectRegion = 'projectRegion',
  projectCategory = 'projectCategory',
  isPricingIncluded = 'isPricingIncluded',
  iconId = 'Project Icon',
  area = 'area',
  region = 'region',
  country = 'country',
  serviceLineId = 'serviceLineId',
}

class CreateProject extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.setProjectFormState();
  }

  shouldNavigateToHome = false;
  isProjectDrafted = false;
  selectedBundle: BundleClass | undefined = {
    id: '',
    name: '',
    description: '',
    key: '',
    appDetails: [],
    appClassIds: [],
    selectedAppIds: [],
    selected: false,
  };
  appCards: AppClass[] = [];
  appPlatforms: AppPlatformClass[] = [];
  projectEditEnable = true;
  createProjectEnable = true;
  dataLoaded = false;
  navEditPage = false;
  navigateToStep2 = false;
  editLAApp: AppClass[] = [];
  paceProjectLoaded = false;
  approverLoaded = false;
  showServiceLineModal = false;
  showOnceConfirmModal = true;
  queryParamsMode: string = '';

  componentDidMount() {
    window.scroll(0, 0);
    const { record } = this.props;

    this.props.clearProjectData();
    this.props.flushProjectDetails();
    this.loadAPI();

    window.addEventListener('beforeunload', this.handleBeforeUnload);
    const appInsights = getAzureAppInsights();
    if (record && record.id && appInsights) {
      appInsights.updateProjectDetails({
        CapitalEdgeProjectFriendlyId: record.projectFriendlyId || '',
      });
    }

    this.checkForEMGPolicy();
  }

  loadAPI = () => {
    const {
      projectTypes,
      sectors,
      clientSizes,
      dataLocations,
      projectStatus,
      bundles,
      appPlatforms,
      getProjectTypes,
      getSectors,
      getClientSizes,
      getDataLocations,
      getProjectStatus,
      getBundleCards,
      getAppPlatforms,
      getProjectDetails,
      match: {
        params: { id },
      },
      apps,
      getAppCards,
      appCategories,
      getAppCategories,
      fire_getCountriesCodeAction,
      projectAreas,
      projectRegions,
      projectCountries,
      getProjectCategories,
      projectCategories,
      getProjectIcons,
      projectIcons,
      getActiveFeatures,
    } = this.props;

    !this.createProjectEnable && !apps.length && getAppCards();
    !this.createProjectEnable && !appCategories.length && getAppCategories();
    id && !bundles.length && getBundleCards();
    !projectTypes.length && getProjectTypes();
    !sectors.length && getSectors();
    id && !appPlatforms.length && getAppPlatforms();
    !clientSizes.length && getClientSizes();
    !dataLocations.length && getDataLocations();
    (!projectAreas.length || !projectRegions.length || !projectCountries.length) && fire_getCountriesCodeAction();
    !projectCategories.length && getProjectCategories();
    !projectStatus.length && getProjectStatus();
    id && getProjectDetails({ id });
    id && getActiveFeatures({ id });
    !projectIcons.length && getProjectIcons();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.paceFieldState.searchLoader !== nextProps.searchLoader || prevState.paceFieldState.searchProjectsData !== nextProps.searchProjectsData) {
      return {
        paceFieldState: {
          ...prevState.paceFieldState,
          searchLoader: nextProps.searchLoader,
          searchProjectsData: nextProps.searchProjectsData,
        },
      };
    }
    if (prevState.approverFieldState.searchUsersLoader !== nextProps.searchUsersLoader || prevState.approverFieldState.searchUsersData !== nextProps.searchUsersData) {
      return {
        approverFieldState: {
          ...prevState.approverFieldState,
          searchUsersLoader: nextProps.searchUsersLoader,
          skipToken: nextProps.skipToken,
          searchUsersData: nextProps.searchUsersData,
        },
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: IProps) {
    this.checkCanEditProject();
    this.checkIsExternalUser();
    this.checkProjectId();
    this.navigateToHome();
    const { record, bundles, getUpdatedBundles, masterBundles, projectCategories, projectIcons, isCreateProjectDisabled, isMaintanenceFlagLoaded, showActionToast } = this.props;
    const { formState, approverFieldState } = this.state;
    if (this.createProjectEnable && isCreateProjectDisabled && isMaintanenceFlagLoaded) {
      showActionToast({
        message: t.permission_denied_error,
        type: 'error',
        status: 403,
        redirectToUnauth: true,
        redirectURL: '/',
      });
    }

    if (!this.createProjectEnable && record && record.id && bundles.length) {
      if (prevProps.masterBundles !== masterBundles) {
        getUpdatedBundles(_.cloneDeep(masterBundles));
      }
      if (!this.dataLoaded) {
        this.populateProjectDetails();
      }
      this.dataLoaded = true;
    }
    if (record && record.paceId && !this.paceProjectLoaded) {
      // call the api to search for pace id only when project category
      // key is CLIENG
      const clientEngagementProjectCategory = projectCategories.find((item) => item.key === EProjectCategoryKey.CLIENG.toString());
      if (clientEngagementProjectCategory && formState.projectCategory.fieldVal === clientEngagementProjectCategory.id) {
        this.makeSearchProjectCall(record.paceId);
        this.paceProjectLoaded = true;
      }
    }

    if (this.isPpeddApproverFeatureEnabled() && record && record.ppeddApproverDetails && !this.approverLoaded) {
      approverFieldState.canDisplay = true;

      if (record.ppeddApproverDetails.id && record.ppeddApproverDetails.displayName && record.ppeddApproverDetails.mail) {
        approverFieldState.selectedValue[0] = record.ppeddApproverDetails;
        approverFieldState.isvalid = true;

        if (approverFieldState.searchUsersData.length === 0) approverFieldState.searchUsersData.push(record.ppeddApproverDetails);
        this.setState(
          {
            approverFieldState,
          },
          () => {
            this.checkDirtyFields();
          }
        );
      }
      this.approverLoaded = true;
    }

    if (!_.isEqual(prevProps.projectIcons, projectIcons)) {
      if (formState.iconId.fieldVal === '' && !record?.id) {
        projectIcons.sort((icon1, icon2) => icon1.iconIndex - icon2.iconIndex);
        formState.iconId.fieldVal = projectIcons[0].id;
        this.setState({
          formState,
        });
      }
    }

    this.getAppInsights(prevProps, record);
  }

  componentWillUnmount() {
    const { clearProjectData, flushProjectDetails, clearSearchedProjects, match: {
      params: { id },
    }, history } = this.props;

    clearProjectData();
    flushProjectDetails();
    clearSearchedProjects();
    this.resetBundlesInStore();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    if (id && !history.location.pathname.includes(RoutePathKeys.PROJECT)) {
      this.resetPageBrTitle();
    }
  }

  handleBeforeUnload = (e) => {
    if (this.state.isDirty) {
      return (e.returnValue = false);
    }
  };

  checkIsExternalUser = () => {
    const { currentUser, history, showActionToast } = this.props;
    if (this.createProjectEnable && currentUser?.userType?.toLowerCase() === UserTypes.External.toLowerCase()) { 
      history.push("/");
      showActionToast({
        message: t.permission_denied_error,
        type: "error",
        status: 403,
        redirectURL: "/",
      });
    }
  }

  checkCanEditProject = () => {
    const { record, projectStatus, history, showActionToast } = this.props;
    if (record && record.appPlatformDetails && record.infrastructureStatusId && record?.projectStatus) {
      const isPending = isPlatformPending(
        projectStatus,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      );
      const isError = isPlatformError(
        projectStatus,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      );
      const isProjectInError = isPorjectErrored(
        projectStatus,
        record?.projectStatus?.id
      );
  
      if (isPending || isError || isProjectInError) {
        history.push("/");
        showActionToast({
          message: t.permission_denied_error,
          type: "error",
          status: 403,
          redirectURL: "/",
        });
      }
    }
  }

  getAppInsights = (prevProps, record) => {
    const appInsights = getAzureAppInsights();
    if (prevProps.record !== record && record && record.id) {
      if (appInsights) {
        appInsights.updateProjectDetails({
          CapitalEdgeProjectFriendlyId: record.projectFriendlyId || '',
        });
      }
      this.checkForEMGPolicy();
    }
  };

  checkForEMGPolicy = () => {
    const { accessRight, record, updateProjectDetailsForEMGAction } = this.props;
    checkAndLoadEMGPolicy(accessRight, updateProjectDetailsForEMGAction, record);
  };
  validateServiceLineId = (serviceLines, servicelineId) => {
    return serviceLines.some((e) => e.id === servicelineId) ? servicelineId : '';
  };
  getServiceLineName = () => {
    const { userServiceLineId, projectPermittedServiceLines } = this.state;
    for (const serviceLine of projectPermittedServiceLines) {
      if (serviceLine.id === userServiceLineId) {
        return serviceLine.name;
      }
    }
    return '';
  };
  setProjectFormState() {
    const {
      match: {
        params: { id },
      },
      searchLoader,
      searchProjectsData,
      projectIcons,
      searchUsersLoader,
      searchUsersData,
      serviceLines,
      accessRight,
    } = this.props;

    projectIcons.sort((icon1, icon2) => icon1.iconIndex - icon2.iconIndex);
    const projectPermittedServiceLines = serviceLines.filter((item) => item.isProjectPermitted);
    const userServiceLineId = this.validateServiceLineId(projectPermittedServiceLines, accessRight.serviceLineId);
    this.createProjectEnable = !id;
    return {
      formState: {
        projectName: {
          fieldName: FormFields.projectName,
          fieldVal: '',
          isvalid: true,
          charLimit: 15,
          disable: !this.createProjectEnable,
          isRequired: true,
        },
        projectType: {
          fieldName: FormFields.projectType,
          fieldVal: '',
          isvalid: true,
          disable: !this.projectEditEnable,
          isRequired: true,
        },
        projectClientName: {
          fieldName: FormFields.projectClientName,
          fieldVal: '',
          isvalid: true,
          charLimit: 15,
          disable: !this.createProjectEnable,
          isRequired: true,
        },
        projectSector: {
          fieldName: FormFields.projectSector,
          fieldVal: '',
          isvalid: true,
          disable: !this.projectEditEnable,
          isRequired: true,
        },
        projectServiceLine: {
          fieldName: FormFields.projectServiceLine,
          fieldVal: userServiceLineId,
          isvalid: true,
          disable: !this.projectEditEnable,
          isRequired: true,
        },
        projectSize: {
          fieldName: FormFields.projectSize,
          fieldVal: '',
          isvalid: true,
          disable: !this.projectEditEnable,
          isRequired: true,
        },
        projectLocation: {
          fieldName: FormFields.projectLocation,
          fieldVal: '',
          isvalid: true,
          disable: !this.createProjectEnable,
          isRequired: true,
        },
        projectDesc: {
          fieldName: FormFields.projectDesc,
          fieldVal: '',
          isvalid: true,
          disable: !this.projectEditEnable,
          isRequired: false,
        },
        projectMFAEnabled: {
          fieldName: FormFields.projectMFAEnabled,
          fieldVal: 'false',
          isvalid: true,
          disable: !this.createProjectEnable,
          isRequired: true,
        },
        projectConfidential: {
          fieldName: FormFields.projectConfidential,
          fieldVal: '',
          isvalid: true,
          disable: !this.createProjectEnable,
          isRequired: true,
        },
        [FormFields.area]: {
          fieldName: FormFields.area,
          fieldVal: null,
          isvalid: true,
          disable: !this.createProjectEnable,
          isRequired: true,
        },
        [FormFields.region]: {
          fieldName: FormFields.region,
          fieldVal: null,
          isvalid: true,
          disable: !this.createProjectEnable,
          isRequired: true,
        },
        [FormFields.country]: {
          fieldName: FormFields.country,
          fieldVal: null,
          isvalid: true,
          disable: !this.projectEditEnable,
          isRequired: true,
        },
        projectCategory: {
          fieldName: FormFields.projectCategory,
          fieldVal: '',
          isvalid: true,
          disable: !this.projectEditEnable,
          isRequired: true,
        },
        isPricingIncluded: {
          fieldName: FormFields.isPricingIncluded,
          fieldVal: undefined,
          isvalid: true,
          disable: false,
          isRequired: false,
        },
        iconId: {
          fieldName: FormFields.iconId,
          fieldVal: (projectIcons.length && projectIcons[0].id) || '',
          isvalid: true,
          disable: !this.projectEditEnable,
          isRequired: true,
        },
      },
      paceFieldState: {
        searchQuery: '',
        isRequired: false,
        isvalid: true,
        disable: true,
        selectedValue: [],
        handleSearchChange: this.handleSearchChange,
        handleProjectSelection: this.handleProjectSelection,
        searchLoader,
        searchProjectsData,
      },
      stepIndicatorState: {
        currentStep: 1,
        step1Progress: !this.isProjectDrafted && !this.createProjectEnable ? Status.Complete : Status.Start,
        step2Progress: !this.isProjectDrafted && !this.createProjectEnable ? Status.Complete : Status.Start,
        step3Progress: Status.Start,
      },
      disableCreateBtn: true,
      disableContinueBtn: false,
      isFormValid: false,
      customizeModeuleEnabled: false,
      disableSaveBtn: true,
      showError: false,
      isDirty: false,
      isPaceProjectModalOpen: false,
      showServiceLineModal: false,
      showOnceConfirmModal: this.createProjectEnable,
      approverFieldState: {
        searchQuery: '',
        isRequired: false,
        isvalid: true,
        selectedValue: [],
        canDisplay: false,
        handleSearchUsersChange: this.handleSearchUsersChange,
        handleUsersSelection: this.handleUsersSelection,
        searchUsersLoader,
        skipToken: '',
        searchUsersData,
      },
      projectPermittedServiceLines,
      userServiceLineId,
      isChangeCategoryLoading: false
    };
  }

  populateProjectDetails() {
    const { formState, stepIndicatorState, paceFieldState, approverFieldState } = this.state;
    const {
      record,
      bundles,
      apps,
      appPlatforms,
      match: {
        params: { encodedParams },
      },
    } = this.props;
    if (record && record.id) {
      this.updatePageBrTitle();
      // porting initial project meta data from api to form centric state
      formState.projectName.fieldVal = record.name;
      formState.projectClientName.fieldVal = record.clientName;
      formState.projectDesc.fieldVal = record.description;
      formState.projectLocation.fieldVal = record.locationDetails ? record.locationDetails.locationId : '';
      formState.projectSector.fieldVal = record.sectorId;
      formState.projectSize.fieldVal = record.clientSizeId;
      formState.projectType.fieldVal = record.projectTypeId;
      formState.projectMFAEnabled.fieldVal = record.mfaEnabled.toString();
      formState[FormFields.area].fieldVal = record.geoLocation?.area;
      formState[FormFields.region].fieldVal = record.geoLocation?.region;
      formState[FormFields.country].fieldVal = record.geoLocation?.country;
      formState.projectConfidential.fieldVal = record.isConfidential ? 'Yes' : 'No';
      formState.projectCategory.fieldVal = record.projectCategoryId;
      formState.isPricingIncluded.fieldVal = record.provisioningQuestionsDetails?.isPricingIncluded;
      formState.iconId.fieldVal = record.iconId || formState.iconId.fieldVal;
      formState.projectServiceLine.fieldVal = this.validateServiceLineId(this.state.projectPermittedServiceLines, record.serviceLineId);
      // marking form fields that need to be disabled on various conditions
      formState.projectName.disable = !this.isProjectDrafted;
      formState.projectClientName.disable = !this.isProjectDrafted || record.isConfidential;
      formState[FormFields.area].disable = !this.isProjectDrafted;
      formState[FormFields.region].disable = !this.isProjectDrafted;
      formState.projectLocation.disable = !this.isProjectDrafted;
      formState.projectMFAEnabled.disable = !this.isProjectDrafted;
      formState.projectConfidential.disable = !this.isProjectDrafted;
      //TODO - paceFieldState is maintained outside of formstate that need to be refactored
      paceFieldState.disable = formState.projectCategory.fieldVal !== EProjectCategory.CLI_ENG;
      paceFieldState.selectedValue = record.paceId && !paceFieldState.disable ? [{ paceId: record.paceId, projectName: '' }] : [];

      if (this.isPpeddApproverFeatureEnabled()) {
        approverFieldState.canDisplay = formState.projectCategory.key !== undefined && this.getProjectCategoriesApproverField().includes(formState.projectCategory.key);
      }

      if (bundles?.length && record.bundleClassId) {
        const bundleType = record.appDetails?.length ? record.bundleClassId : record.projectTypeId;

        // If existing project, fetch bundle values from project document else fetch from AppBundleClasses document
        const bundle = record.appBundle ? record.appBundle : bundles.find((f) => f.id === bundleType);

        if (bundle) {
          this.selectedBundle = bundle;
          this.selectedBundle.selected = true;
          if (record.bundleName) {
            this.selectedBundle.name = record.bundleName;
          }
          if (record.bundleDescription) {
            this.selectedBundle.description = record.bundleDescription;
          }
          if (!this.selectedBundle.appClassIds) {
            this.selectedBundle.appClassIds = [];
          }
          const appCardId: Id[] = [];
          record.appDetails &&
            record.appDetails.forEach((item) => {
              if (item.id) {
                appCardId.push({ id: item.appClassId });
                const appCard = apps.find((app) => app.id === item.appClassId);
                if (appCard) {
                  this.appCards.push(appCard);
                }
              }
            });
          this.selectedBundle.selectedAppIds = appCardId;
        }
      }
      const disableSaveBtn = (record.projectStatus?.projectStatusName?.toLowerCase() === ProjectStatusName.DRAFT ? false : true) && this.isSaveDisabled().disableSaveBtn;
      this.appPlatforms = _.cloneDeep(appPlatforms);
      this.checkDirtyFields();
      stepIndicatorState.step2Progress = this.selectedBundle?.selectedAppIds?.length ? Status.Complete : Status.Start;
      this.setState({
        formState,
        disableCreateBtn: this.selectedBundle?.selectedAppIds?.length === 0,
        disableSaveBtn: disableSaveBtn
      });
      if (encodedParams) {
        const currentQuery = new URLSearchParams(window.atob(encodedParams));
        this.queryParamsMode = currentQuery.get('mode') as string;
        if (this.queryParamsMode.toLowerCase() === HandlerMode.ConfirmOrChangeCategory.toLowerCase()) {
          this.handleChangeProjectCategory();
        }
      }
      
    }
  }

  resetBundlesInStore = () => {
    this.props.bundles.forEach((item) => {
      item.selected = item.isCustomized = false;
    });
  };

  checkProjectId = () => {
    const { createProjectData, record } = this.props;
    if (!this.isProjectDrafted && ((createProjectData && createProjectData.id) || (record && record.id && record.projectStatus.projectStatusName.toLowerCase() === ProjectStatusName.DRAFT))) {
      this.isProjectDrafted = true;
    }
  };

  saveHandler = () => {
    this.saveOrCreateProject(true, false);
  };

  updateHandler = () => {
    const { paceFieldState, formState } = this.state;
    const { record } = this.props;

    if (paceFieldState.selectedValue && paceFieldState.selectedValue[0]) {
      const isDiffrentPaceProject = record?.paceId !== paceFieldState.selectedValue[0].paceId;
      const isDiffrentProjectName = formState.projectName.fieldVal !== paceFieldState.selectedValue[0].projectName.substr(0, 15);

      if ((this.projectEditEnable && isDiffrentPaceProject) || (!this.projectEditEnable && isDiffrentProjectName)) {
        this.setState({ isPaceProjectModalOpen: true });

        return;
      }
    }

    this.saveOrCreateProject(false, true);
  };

  createHandler = () => {
    if (!this.props.isCreateProjectDisabled) {
      this.saveOrCreateProject(false, true);
    }
  };

  saveOrCreateProject = (isDraft: boolean, isActive: boolean) => {
    this.shouldNavigateToHome = false;
    const {
      stepIndicatorState: { currentStep },
      formState,
    } = this.state;
    const isDraftValid = formState.projectName.fieldVal && typeof formState.projectName.fieldVal !== 'string' ? true : formState.projectName.fieldVal.trim() !== '';

    if (isDraftValid) {
      const data = this.createProjectDataStep(isDraft, currentStep, isActive);

      this.props.clearProjectData();
      this.setState({ isDirty: false }, () => {
        if (this.isProjectDrafted || (!this.createProjectEnable && isActive)) {
          this.props.updateProject(data);
        } else {
          this.props.createProject(data);
        }
        this.shouldNavigateToHome = true;
        this.setState({ showError: true });
      });
    } else {
      formState.projectName.isvalid = false;
      this.setState({ formState: formState });
    }
  };

  navigateToHome = () => {
    const {
      history,
      errorOnCreateOrUpdate,
      getMyAccessRight,
      loading,
      accessRight,
      match: {
        params: { id },
      },
    } = this.props;
    if (!this.createProjectEnable && this.shouldNavigateToHome && !loading && !errorOnCreateOrUpdate && !accessRight.loading) {
      getMyAccessRight();
      this.setState({ isDirty: false }, () => {
        id && history.push(`/project/${id}`);
      });
    } else if (this.shouldNavigateToHome && this.isProjectDrafted && !loading && !errorOnCreateOrUpdate && !accessRight.loading) {
      getMyAccessRight();
      this.setState({ isDirty: false }, () => {
        history.push('/');
      });
    }
  };

  cancelHandler = () => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;

    if (this.selectedBundle?.isOwnBundle) {
      this.props.flushCreatedBundle();
    }

    this.createProjectEnable && history.push('/');
    !this.createProjectEnable && id && history.push(`/project/${id}`);
  };

  backHandler = () => {
    const { stepIndicatorState, customizeModeuleEnabled } = this.state;
    stepIndicatorState.currentStep = stepIndicatorState.currentStep - 1;
    this.navigateToStep2 = stepIndicatorState.currentStep === 2 ? true : false;
    this.setState(
      {
        stepIndicatorState: stepIndicatorState,
        disableCreateBtn: true,
        disableContinueBtn: false,
        disableSaveBtn: false,
        customizeModeuleEnabled: stepIndicatorState.currentStep === 1 ? false : customizeModeuleEnabled,
      },
      () => {
        this.checkDirtyFields();
      }
    );
  };

  continueHandler = () => {
    const {
      stepIndicatorState,
      disableCreateBtn,
      stepIndicatorState: { currentStep },
      formState,
      isFormValid,
    } = this.state;

    //price provision policy validation
    if (currentStep === 1) {
      if (!isFormValid || (formState.projectCategory.fieldVal === EProjectCategory.CLI_ENG && formState.isPricingIncluded.fieldVal === undefined)) {
        this.validateFormData();
      } else if (isFormValid && !this.createProjectEnable) {
        this.navigateToStep2 = true;
        this.customizeHandler();
      } else if (isFormValid) {
        stepIndicatorState.currentStep = 2;
        stepIndicatorState.step2Progress = stepIndicatorState.step2Progress === Status.Complete ? stepIndicatorState.step2Progress : Status.Inprogress;
        this.setState({
          stepIndicatorState,
          disableCreateBtn: true,
          disableSaveBtn: false,
        });
        this.navigateToStep2 = true;
      }
    } else if (currentStep === 2 && !disableCreateBtn) {
      stepIndicatorState.currentStep = 3;
      stepIndicatorState.step3Progress = Status.Inprogress;
      this.setState({ stepIndicatorState });
    }
  };

  customizeHandler = () => {
    const { stepIndicatorState } = this.state;
    this.navigateToStep2 = false;
    stepIndicatorState.currentStep = 2;
    this.setState({ customizeModeuleEnabled: true, stepIndicatorState });
  };

  getAppCardAndPlatformDetails = (appClassIds, draftObj): IAppCardAndPlatformDetail => {
    const appDtls: AppDetail[] = [];
    const appPlatformDtls: AppPlatformDetail[] = [];
    const { apps, projectStatus, appPlatforms } = this.props;
    const allApps = !this.createProjectEnable && this.editLAApp.length ? _.concat(apps, this.editLAApp) : apps;
    allApps.forEach((app) => {
      const appData = this.appCards.find((f) => f.id === app.id);
      if (appData && appData !== undefined) {
        const appObj: AppDetail = {
          appClassId: app.id,
          appCategoryId: appData.appCategoryId,
          appClassName: appData.name,
          appPlatformId: appData.appPlatformClassId,
          appUrl: appData.appRelativeURL,
          isPublished: false,
          key: appData.key,
        };

        appDtls.push(appObj);
      }
      const appPlatformData = this.appPlatforms.find((f) => f.id === appData?.appPlatformClassId);
      if (appPlatformData && appPlatformData !== undefined) {
        if (appPlatformDtls.findIndex((f) => f.appPlatformClassId === appPlatformData.id) === -1) {
          const appPlatformObj: AppPlatformDetail = this.getAppPlatformDetail(appPlatformData);

          if (draftObj.key === EProjectStatuses.DRFT) {
            appPlatformObj.statusId = projectStatus.find((obj) => obj.key === EProjectStatuses.DRFT)?.id;
          }
          appPlatformDtls.push(appPlatformObj);
        }
      }
    });

    this.silentProvisionCE4(appPlatforms, appPlatformDtls);
    this.silentProvisionAllPlatforms(appPlatformDtls);

    return { appDtls, appPlatformDtls };
  };

  silentProvisionAllPlatforms = (appPlatformDtls) => {
    const { record } = this.props;

    if (record && record.appPlatformDetails) {
      record.appPlatformDetails.forEach((appPlatformDetail) => {
        if (appPlatformDtls.findIndex((appPlatformDtl) => appPlatformDtl.appPlatformClassId === appPlatformDetail.appPlatformClassId) === -1) {
          const appPlatformData = this.appPlatforms.find((f) => f.id === appPlatformDetail.appPlatformClassId);

          if (appPlatformData) {
            const appPlatform: AppPlatformDetail = this.getAppPlatformDetail(appPlatformData);
            appPlatformDtls.push(appPlatform);
          }
        }
      });
    }
  };

  silentProvisionCE4 = (appPlatforms, appPlatformDtls) => {
    // For CE4 silent provisioning, in case selected app belongs to WRIKE platform
    if (!this.createProjectEnable && !this.isProjectDrafted) {
      const wrikeId = appPlatforms.find((appPlatform) => appPlatform.key === gridAppPlatformKeys.WRIKE)?.id;
      const ce4Id = appPlatforms.find((appPlatform) => appPlatform.key === gridAppPlatformKeys.CE4)?.id;

      if (appPlatformDtls.findIndex((platform) => platform.appPlatformClassId === wrikeId) !== -1 && appPlatformDtls.findIndex((platform) => platform.appPlatformClassId === ce4Id) === -1) {
        const appPlatformData = this.appPlatforms.find((f) => f.id === ce4Id);

        if (appPlatformData) {
          const appPlatformObj: AppPlatformDetail = this.getAppPlatformDetail(appPlatformData);
          appPlatformDtls.push(appPlatformObj);
        }
      }
    }
  };

  getAppCardDetail = (appData: AppClass, app: string): AppDetail => ({
    appClassId: app,
    appCategoryId: appData.appCategoryId,
    appClassName: appData.name,
    appPlatformId: appData.appPlatformClassId,
    appUrl: appData.appRelativeURL,
    isPublished: false,
  });

  getAppPlatformDetail = (appPlatformData: AppPlatformClass): AppPlatformDetail => ({
    appPlatformClassId: appPlatformData.id,
    name: appPlatformData.name,
    description: appPlatformData.description,
    key: appPlatformData.key,
    provisioningStartedOn: 0,
    provisioningEndedOn: 0,
    apiUrl: '',
    siteURL: '',
  });
  getEditAppClass = (appData: AppDetail): AppClass => ({
    id: appData.appClassId,
    appCategoryId: appData.appCategoryId,
    name: appData.appClassName,
    appPlatformClassId: appData.appPlatformId,
    appRelativeURL: appData.appURL || '',
    description: appData.appDescription || '',
    key: appData.key || '',
    isPublished: appData.isPublished,
    channelOnePermittedFlag: '',
    demoSiteURL: '',
  });

  createProjectDataStep = (isDraft: boolean, step: number, isActive: boolean) => {
    const { formState, paceFieldState, approverFieldState } = this.state;
    const { projectStatus, dataLocations } = this.props;
    const matchKey = isActive ? EProjectStatuses.ACTV : EProjectStatuses.DRFT;
    const draftObj = projectStatus.find((obj) => obj.key === matchKey);
    const engStatus: ProjectStatus = {
      projectStatusName: draftObj ? draftObj.name : '',
      id: draftObj ? draftObj.id : '',
    };

    const appCardDetailIds = this.selectedBundle && (this.selectedBundle.appDetails || this.selectedBundle.appClassIds || []);
    const { appDtls, appPlatformDtls } = this.getAppCardAndPlatformDetails(appCardDetailIds, draftObj);
    const paceIdVal = formState.projectCategory.fieldVal === EProjectCategory.CLI_ENG && paceFieldState.selectedValue && paceFieldState.selectedValue[0].paceId;

    const data: Project = {
      name: formState.projectName.fieldVal,
      paceId: paceIdVal || null,
      isDraft: isDraft,
      clientName: formState.projectClientName.fieldVal,
      description: formState.projectDesc.fieldVal,
      sectorId: formState.projectSector.fieldVal,
      serviceLineId: formState.projectServiceLine.fieldVal,
      channelId: '',
      clientSizeId: formState.projectSize.fieldVal,
      stepProgress: this.getStepProgress(appDtls, step),
      projectTypeId: formState.projectType.fieldVal,
      projectStatus: engStatus,
      mfaEnabled: formState.projectMFAEnabled.fieldVal === 'true',
      geoLocation: {
        area: formState[FormFields.area].fieldVal,
        region: formState[FormFields.region].fieldVal,
        country: formState[FormFields.country].fieldVal,
      },
      projectCategoryId: formState.projectCategory.fieldVal,
      isConfidential: formState.projectConfidential.fieldVal === 'Yes',
      iconId: formState.iconId.fieldVal,
      ppeddApproverDetails: approverFieldState.selectedValue[0],
      provisioningQuestionsDetails:
        formState.isPricingIncluded.fieldVal === undefined
          ? undefined
          : {
              isPricingIncluded: formState.isPricingIncluded.fieldVal,
            },
    };
    const selectedDataLocation = dataLocations.find((l) => l.id === formState.projectLocation.fieldVal);

    if (selectedDataLocation) {
      const { id: locationId, name: locationName, description: locationDescription, key, locationBaseURL, sharePointBaseUrl, externalIds } = selectedDataLocation;

      data.locationDetails = {
        locationId,
        locationBaseURL,
        locationDescription,
        locationName,
        sharePointBaseUrl,
        key,
        externalIds,
      };
    }

    this.manupulateProjectData(step, data, appDtls, appPlatformDtls);
    return data;
  };

  manupulateProjectData = (step, data, appDtls, appPlatformDtls) => {
    const { formState } = this.state;
    const { createProjectData, record } = this.props;

    if (step === 1 && this.createProjectEnable) {
      data.bundleClassId = formState.projectType.fieldVal;
    }

    this.step2DataMapping(step, data, appDtls, appPlatformDtls);

    if (this.isProjectDrafted || !this.createProjectEnable) {
      data.id = this.createProjectEnable ? createProjectData.id : record && record.id;
    }

    if (this.createProjectEnable && this.selectedBundle?.isOwnBundle) {
      data.bundleName = this.selectedBundle.name;
      data.bundleDescription = this.selectedBundle.description;
    }
  };

  step2DataMapping = (step, data, appDtls, appPlatformDtls) => {
    if (step !== 1 || (!this.createProjectEnable && this.navEditPage) || appDtls.length) {
      const { bundles } = this.props;
      const appPlatformDetails = appPlatformDtls.filter(platform=> appDtls.some(app=> app.appPlatformId === platform.appPlatformClassId));
      
      // Exceptional case handling for Wrike platform
      if (appPlatformDetails.some(platform => platform.key === gridAppPlatformKeys.WRIKE) && !appPlatformDetails.some(platform => platform.key === gridAppPlatformKeys.CE4)) {
        const ce4AppPlatformData = this.appPlatforms.find(platform => platform.key === gridAppPlatformKeys.CE4);
        if(ce4AppPlatformData) {
          const ce4AppPlatformObj: AppPlatformDetail = this.getAppPlatformDetail(ce4AppPlatformData);
          appPlatformDetails.push(ce4AppPlatformObj);
        }
      }

      data.bundleClassId = this.selectedBundle && this.selectedBundle.id ? this.selectedBundle.id : '';
      data.appBundle = bundles.find((f) => f.id === data.bundleClassId);
      data.appDetails = appDtls.length ? appDtls : [];
      data.appPlatformDetails = appPlatformDetails.length ? appPlatformDetails : [];
    }
  };

  validateAndReturnFields = (field?: string): IFormModel => {
    const { formState } = this.state;
    Object.keys(formState)
      .filter((key) => !field || key === field)
      .forEach((key) => {
        const fValue = (formState[key] as IStringFieldState | IGeoFieldState | IBooleanFieldState).fieldVal;
        if (key === FormFields.projectDesc) {
          formState[key].isvalid = !isDontDeleteTagPresent(formState[key].fieldVal);
        } else if (fValue == null || (typeof fValue === 'string' && fValue.trim() === '') || (typeof fValue === 'object' && !fValue.id.length)) {
          formState[key].isvalid = false;
        }
      });
    return formState;
  };

  validateFormField = (field?: string) => {
    this.setState((state) => ({
      ...state,
      formState: this.validateAndReturnFields(field),
    }));
  };

  validateFormData = () => {
    const { paceFieldState, approverFieldState } = this.state;

    paceFieldState.isvalid = this.checkClientProjectStatus();

    if (this.isPpeddApproverFeatureEnabled()) {
      approverFieldState.isvalid = this.validatePpeddApprover();
    }

    this.setState((state) => ({
      ...state,
      formState: this.validateAndReturnFields(),
      paceFieldState,
      approverFieldState,
    }));
  };

  // TODO- technically static type vice val argument is unknown
  // since sometimes logic do take value event.target.value as well which obviously unknown
  // require refactoring here to deal type conversion, for time being apply type assertion assuming that already here works
  onFormChange = (value: unknown, fieldName: string) => {
    const val = value as string;
    const { formState, paceFieldState, approverFieldState, userServiceLineId } = this.state;
    const { projectCategories } = this.props;

    let prevProjectCategory;
    if (fieldName === t.projects_service_line) {
      if (value === userServiceLineId || !this.state.userServiceLineId) {
        this.setState({
          showServiceLineModal: false,
        });
      } else {
        this.setState({
          showServiceLineModal: true,
        });
      }
    }
    if (fieldName === 'projectCategory') {
      prevProjectCategory = formState.projectCategory.fieldVal;
    }

    formState[fieldName].fieldVal = val;
    formState[fieldName].isvalid = true;
    if (fieldName === 'projectType' && !this.createProjectEnable && this.selectedBundle && !this.selectedBundle.selectedAppIds?.length) {
      const bundle = this.props.bundles.find((bundle) => bundle.id === val);
      this.selectedBundle = { ...this.selectedBundle, ...bundle };
    } else if (fieldName === 'projectConfidential') {
      if (val === 'Yes') {
        formState['projectClientName'].fieldVal = t.project_confidential_client_name;
        formState['projectClientName'].disable = true;
        formState['projectClientName'].isvalid = true;
      } else {
        if (formState['projectClientName'].fieldVal === t.project_confidential_client_name && formState['projectClientName'].disable) {
          formState['projectClientName'].fieldVal = '';
        }
        formState['projectClientName'].disable = false;
      }
    } else if (fieldName === 'projectName') {
      formState['projectName'].isvalid = val.length >= MIN_PROJECT_NAME_LENGTH;
    } else if (fieldName === 'projectCategory') {
      formState[fieldName].key = projectCategories.find((t) => t.id === val)?.key;
      const isValidClientProject = this.checkClientProjectStatus();
      paceFieldState.disable = val !== EProjectCategory.CLI_ENG;
      paceFieldState.isRequired = !isValidClientProject;

      if (val !== EProjectCategory.CLI_ENG) {
        const resetFieldState = {
          searchQuery: '',
          selectedValue: [],
          isvalid: true,
        };

        this.setState({
          paceFieldState: { ...this.state.paceFieldState, ...resetFieldState },
        });
        formState.isPricingIncluded.fieldVal = undefined;
      }

      if (this.isPpeddApproverFeatureEnabled()) {
        if (formState.projectCategory.key !== undefined && this.getProjectCategoriesApproverField().includes(formState.projectCategory.key)) {
          approverFieldState.canDisplay = true;
          approverFieldState.isRequired = approverFieldState.selectedValue.length === 0;
        } else {
          approverFieldState.canDisplay = false;
          approverFieldState.isRequired = false;

          const resetFieldState = {
            searchQuery: '',
            selectedValue: [],
            skipToken: '',
            isvalid: true,
          };

          this.setState({
            approverFieldState: {
              ...this.state.approverFieldState,
              ...resetFieldState,
            },
          });
        }
      }

      if (this.projectEditEnable && this.createProjectEnable) {
        if (prevProjectCategory === EProjectCategory.CLI_ENG || val === EProjectCategory.CLI_ENG) {
          formState['projectName'].fieldVal = '';
        }
      }
    }

    this.setState(
      {
        formState: formState,
        isDirty: true,
      },
      () => {
        this.checkDirtyFields();
      }
    );

    //we need to validate description upon change for validation
    if (fieldName === FormFields.projectDesc) {
      this.validateFormField(fieldName);
    }
  };

  checkClientProjectStatus = () => {
    const { paceFieldState, formState } = this.state;
    const isClientProject = formState.projectCategory.fieldVal === EProjectCategory.CLI_ENG;
    let isPaceIdPreset = false;

    if (paceFieldState.selectedValue && paceFieldState.selectedValue[0]) {
      isPaceIdPreset = paceFieldState.selectedValue[0].paceId !== '';
    }

    return (isClientProject && isPaceIdPreset) || !isClientProject;
  };

  validatePpeddApprover = () => {
    const { approverFieldState, formState } = this.state;
    const { projectCategories } = this.props;

    if (formState.projectCategory.fieldVal !== '') formState.projectCategory.key = projectCategories.find((t) => t.id === formState.projectCategory.fieldVal)?.key;

    const isValidProjectCategory = formState.projectCategory.key !== undefined && this.getProjectCategoriesApproverField().includes(formState.projectCategory.key);
    let isValidApprover = false;
    if (approverFieldState.selectedValue && approverFieldState.selectedValue[0]) {
      isValidApprover = approverFieldState.selectedValue[0] !== null;
    }

    return (isValidProjectCategory && isValidApprover) || !isValidProjectCategory;
  };

  isSaveDisabled = () => {
    const { formState, stepIndicatorState } = this.state;
    let filledFields = 0;
    const isValidProjectName = formState['projectName'].fieldVal.length >= MIN_PROJECT_NAME_LENGTH && !validateFormInputForSpecialChar(formState['projectName'].fieldVal);
    const isValidProjectDesc = !isDontDeleteTagPresent(formState.projectDesc.fieldVal) && !validateFormInputForSpecialChar(formState.projectDesc.fieldVal);
    const isValidClientProjectStatus = this.checkClientProjectStatus();
    const isValidPpeddApprover = this.isPpeddApproverFeatureEnabled() ? this.validatePpeddApprover() : true;

    const requiredFormFields = Object.values(formState).filter((value) => value.isRequired);
    Object.values(formState)
      .filter((value) => value.isRequired)
      .forEach(({ fieldVal }) => {
        if (fieldVal) {
          if (typeof fieldVal === 'string' && !fieldVal.trim()) {
            return;
          }
          filledFields++;
        }
      });
    const isFormComplete = filledFields === requiredFormFields.length && isValidProjectName && isValidClientProjectStatus && isValidPpeddApprover && isValidProjectDesc;

    let formStatus = isFormComplete ? Status.Complete : Status.Inprogress;
    if (formState.projectCategory.fieldVal === EProjectCategory.CLI_ENG) {
      formStatus = formState.isPricingIncluded.fieldVal === undefined ? Status.Inprogress : formStatus;
    }
    stepIndicatorState.step1Progress = filledFields === 0 ? Status.Start : formStatus;
    const disableSaveBtn = isFormComplete === true ? false : true;
    return { disableSaveBtn, stepIndicatorState, isFormComplete };
  };

  checkDirtyFields = () => {
    const { disableSaveBtn, stepIndicatorState, isFormComplete } = this.isSaveDisabled();
    this.setState({
      stepIndicatorState: stepIndicatorState,
      isFormValid: isFormComplete,
      disableSaveBtn: disableSaveBtn,
    });
  };

  getProjectDetail = (): ProjectDetailsModel => {
    const { formState } = this.state;
    return {
      title: t.step_two_title,
      description: formState.projectDesc.fieldVal,
      projectName: formState.projectName.fieldVal,
      projectType: this.props.projectTypes.find((f) => f.id === formState.projectType.fieldVal)?.name,
      id: formState.projectType.fieldVal,
      hideNoOfUsers: true,
      hideProjectApps: true,
    };
  };

  getSelectedAppCards = (appCard, selectedAppIds) => (selectedAppIds.find((apps) => apps.id === appCard.id) ? appCard : '');

  bundleChangeHandler = (data: BundleStateModel) => {
    const { bundles } = data;
    this.navigateToStep2 = false;
    if (bundles.length) {
      const bundle = bundles.find((f) => f.selected);
      if (bundle) {
        this.selectedBundle = bundle;
        this.appCards = data.currentAppCards.filter((appCard) => this.getSelectedAppCards(appCard, bundle.selectedAppIds || []));
        this.appPlatforms = data.currentAppPlatforms;
      } else {
        this.selectedBundle = undefined;
      }
    }
    this.setStepIndicatorState();
    this.handleButtonState();
  };

  setStepIndicatorState() {
    const { stepIndicatorState } = this.state;

    if (this.selectedBundle) {
      const { selectedAppIds = [] } = this.selectedBundle;

      stepIndicatorState.step2Progress = selectedAppIds.length ? Status.Complete : Status.Inprogress;
    } else {
      stepIndicatorState.step2Progress = Status.Inprogress;
    }

    this.setState({ stepIndicatorState });
  }

  toggleContinueBtn = (status: boolean | undefined = undefined) => {
    this.setState({
      disableContinueBtn: status || !this.state.disableContinueBtn,
    });
  };

  disableCustomizationModule = () => {
    if (!this.createProjectEnable) {
      this.cancelHandler();
    } else {
      this.resetBundlesInStore();
      this.setState({ customizeModeuleEnabled: false });

      if (this.selectedBundle?.isOwnBundle && !this.selectedBundle.selectedAppIds?.length) {
        this.props.flushCreatedBundle();
      }
    }
  };

  saveReturnCustomizationModule = () => {
    if (this.selectedBundle && this.selectedBundle.selectedAppIds?.length) {
      const { apps } = this.props;

      this.appCards = apps.filter((appCard) => this.getSelectedAppCards(appCard, this.selectedBundle?.selectedAppIds || []));
      this.selectedBundle.appDetails = this.selectedBundle.appClassIds;
    }
    this.setState({ customizeModeuleEnabled: false });
  };

  saveReturnProjectDetails = (bundleCard: BundleClass, nav: boolean, update: boolean) => {
    const { appPlatforms, apps, record } = this.props;
    this.editLAApp = [];
    if (this.selectedBundle && bundleCard) {
      this.navEditPage = true;
      this.selectedBundle.appDetails = bundleCard.selectedAppIds || bundleCard.appClassIds;
      this.selectedBundle.selectedAppIds = bundleCard.selectedAppIds;
      record &&
        record.appDetails &&
        record.appDetails.forEach((item) => {
          if (item.id) {
            const appCard = apps.find((app) => app.id === item.appClassId);
            if (!appCard) {
              this.editLAApp.push(this.getEditAppClass(item));
            }
          }
        });
      const allApps = !this.createProjectEnable && this.editLAApp.length ? _.concat(apps, this.editLAApp) : apps;
      this.appCards = allApps.filter((appCard) => this.getSelectedAppCards(appCard, bundleCard.selectedAppIds || []));
      this.appPlatforms = appPlatforms;
      this.setState({
        disableCreateBtn: bundleCard.selectedAppIds?.length === 0,
      });
      if (!nav) {
        !this.createProjectEnable && !this.isProjectDrafted && this.updateHandler();
        update && this.isProjectDrafted && this.saveHandler();
      }
    }
  };

  handleStepClick = (id: string) => {
    let { stepIndicatorState, isFormValid } = this.state;
    const ide = parseInt(id);

    isFormValid = isFormValid && this.isPolicyOfProject_valid();

    if (ide === 1) {
      stepIndicatorState.currentStep = 1;
      this.setState({
        stepIndicatorState: stepIndicatorState,
        disableCreateBtn: this.createProjectEnable || this.selectedBundle?.selectedAppIds?.length === 0,
        disableContinueBtn: false,
        disableSaveBtn: false,
        customizeModeuleEnabled: false,
      });
      this.navigateToStep2 = false;
    } else if (!this.createProjectEnable && ide === 2 && isFormValid) {
      this.navigateToStep2 = true;
      this.customizeHandler();
    } else if (ide === 2 && this.selectedBundle?.selectedAppIds?.length && isFormValid) {
      stepIndicatorState.currentStep = 2;
      stepIndicatorState.step2Progress = stepIndicatorState.step2Progress === Status.Complete ? stepIndicatorState.step2Progress : Status.Inprogress;
      this.setState({
        stepIndicatorState,
        disableCreateBtn: true,
        disableSaveBtn: false,
      });
      this.navigateToStep2 = true;
    } else if (ide === 2) {
      this.navigateToStep2 = true;
      this.continueHandler();
    } else if (ide === 3 && this.selectedBundle?.selectedAppIds?.length && isFormValid) {
      stepIndicatorState.currentStep = 3;
      stepIndicatorState.step3Progress = Status.Inprogress;
      this.setState({ stepIndicatorState });
    }
  };

  isPolicyOfProject_valid = () => {
    const { formState } = this.state;
    //check client engagement project have pricing information filled
    return formState.projectCategory.fieldVal === EProjectCategory.CLI_ENG ? formState.isPricingIncluded.fieldVal != null : true;
  };

  handleAppSelect = (selectedAppIds, currentAppCards) => {
    this.appCards = currentAppCards.filter((appCard) => this.getSelectedAppCards(appCard, selectedAppIds));
    this.handleButtonState();
    this.setStepIndicatorState();
  };

  handleButtonState = () => {
    if (!this.selectedBundle) {
      return;
    }
    const { selected, selectedAppIds = [] } = this.selectedBundle;

    this.setState({
      disableCreateBtn: !(selected && selectedAppIds.length),
    });
  };

  hideToast = () => {
    this.setState({ showError: false });
  };

  filterAppCardsByAppIds = (appClassIds, record?: Project): AppClass[] => {
    const selectedAppCard: AppClass[] = [];
    if (this.appCards && this.appCards.length) {
      appClassIds &&
        appClassIds
          .map((m) => m.id)
          .forEach((app) => {
            const appData = this.appCards.find((f) => f.id === app);
            if (appData) {
              selectedAppCard.push(appData);
            }
          });
    } else if (!this.createProjectEnable && record && record.id) {
      record.appDetails &&
        record.appDetails.forEach((app) => {
          const appData = this.props.apps.find((f) => f.id === app.appClassId);
          if (appData) {
            selectedAppCard.push(appData);
          }
        });
    }

    return selectedAppCard;
  };

  getContinueState = () => {
    const { stepIndicatorState, disableCreateBtn, disableContinueBtn } = this.state;

    if (stepIndicatorState.currentStep === 2) {
      return !!disableCreateBtn;
    }

    return !!disableContinueBtn;
  };

  getShowContinueButtonState = () => {
    const { stepIndicatorState } = this.state;

    if (!this.createProjectEnable && !this.isProjectDrafted) {
      return stepIndicatorState.currentStep !== 2;
    }

    return stepIndicatorState.currentStep !== 3;
  };

  onAppToggle = (bundleCard: BundleClass) => {
    if (!this.createProjectEnable) {
      const { stepIndicatorState, isDirty } = this.state;

      if (!_.isEqual(this.selectedBundle?.selectedAppIds, bundleCard.selectedAppIds) && !isDirty) {
        this.setState({ isDirty: true });
      }
      if (this.selectedBundle) {
        this.selectedBundle.selectedAppIds = bundleCard.selectedAppIds;
      }
      stepIndicatorState.step2Progress = bundleCard.selectedAppIds?.length ? Status.Complete : Status.Inprogress;
      if (this.isProjectDrafted) {
        this.setState({
          stepIndicatorState,
          disableCreateBtn: !!!bundleCard.selectedAppIds?.length,
          disableContinueBtn: !!!bundleCard.selectedAppIds?.length,
        });
      }
    }
  };

  setIsDirty = () => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
  };

  handleSearchChange = (query, pageNumber) => {
    this.makeSearchProjectCall(query, pageNumber);
  };

  makeSearchProjectCall = (query, pageNumber = 1) => {
    const { paceFieldState } = this.state;
    const payload = {
      totalRecords: 0,
      pageSize: PAGE_SIZE + 1,
      pageNumber: pageNumber,
      sortColumn: 'projectName',
      sortDirection: 'asc',
      filters: [
        {
          filterColumn: 'string',
          filterText: query,
        },
      ],
    };

    paceFieldState.searchQuery = query;
    this.props.searchProjects(payload);
  };

  handleProjectSelection = (selectedArray) => {
    const { paceFieldState, formState } = this.state;
    if (!selectedArray.length) {
      paceFieldState.selectedValue = [];
      paceFieldState.isRequired = true;
      paceFieldState.isvalid = false;
      if (this.projectEditEnable && this.createProjectEnable) {
        formState.projectName.fieldVal = '';
      }
    } else {
      paceFieldState.selectedValue = [
        {
          paceId: selectedArray[0].paceId,
          projectName: selectedArray[0].projectName,
        },
      ];
      if ((this.projectEditEnable && this.createProjectEnable) || this.isProjectDrafted) {
        formState.projectName.fieldVal = selectedArray[0].projectName.substr(0, 15);
      }
      formState.projectName.isvalid = true;
      paceFieldState.isRequired = false;
      paceFieldState.isvalid = true;
    }

    this.setState({ formState, paceFieldState }, () => this.checkDirtyFields());
  };

  handleSearchUsersChange = (query, skipToken, pageNumber) => {
    this.makeSearchUsersCall(query, skipToken, pageNumber);
  };

  makeSearchUsersCall = (query, skipToken = null, pageNumber = 1) => {
    const { approverFieldState } = this.state;
    const payload = {
      pageSize: PAGE_SIZE + 1,
      pageNumber: pageNumber,
      sortColumn: APPROVER_SORT_COLUMN,
      skipToken: skipToken,
      filters: [
        {
          filterText: query,
        },
      ],
    };

    approverFieldState.searchQuery = query;
    this.props.searchUsers(payload);
  };

  handleUsersSelection = (selectedArray) => {
    const { approverFieldState, formState } = this.state;
    if (!selectedArray.length) {
      approverFieldState.selectedValue = [];
      approverFieldState.isRequired = true;
      approverFieldState.isvalid = false;
    } else {
      approverFieldState.selectedValue = [
        {
          id: selectedArray[0].id,
          displayName: selectedArray[0].displayName,
          mail: selectedArray[0].mail,
        },
      ];
      approverFieldState.isRequired = false;
      approverFieldState.isvalid = true;
    }

    this.setState({ formState, approverFieldState }, () => this.checkDirtyFields());
  };

  handleConfirmPaceProject = () => {
    this.setState({ isPaceProjectModalOpen: false });
    this.saveOrCreateProject(false, true);
  };

  getProjectCategoriesApproverField = () => {
    return [EProjectCategoryKey.CLIDEMO.toString(), EProjectCategoryKey.INTPRJ.toString(), EProjectCategoryKey.TRNG.toString()];
  };

  isPortalAdminUser = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole = (accessRight?.portalRoles && accessRight.portalRoles.length > 0 && accessRight.portalRoles[0]) || '';

    return isPortalAdmin(portalRole, sspRoles);
  };

  isPpeddApproverFeatureEnabled() {
    const { activeFeatures, record } = this.props;
    return isFeatureEnabled(ProjectActiveFeatures.PPEDD_APPROVER, activeFeatures, record);
  }

  getFilteredProjectCategories = () => {
    const { projectCategories } = this.props;
    const { formState } = this.state;
    const selectedProjectCategoryKey = projectCategories.find((t) => t.id === formState.projectCategory.fieldVal)?.key;

    if (!this.isPortalAdminUser() && selectedProjectCategoryKey !== EProjectCategoryKey.STDDEMO) {
      return projectCategories.filter((t) => t.key !== EProjectCategoryKey.STDDEMO);
    }

    return projectCategories;
  };

  getStepProgress(appDtls: AppDetail[], step: number) {
    const { record } = this.props;

    let currentStep = step.toString();
    if (!this.isProjectDrafted && record && record.id) {
      currentStep = record?.stepProgress;
    } else if (step === 1 && appDtls.length !== 0) {
      currentStep = ProjectSteps.Step_2;
    } else if (step === 2 && appDtls.length === 0) {
      currentStep = ProjectSteps.Step_1;
    }

    return currentStep;
  }

  renderProjectAreas = () => {
    const { projectAreas } = this.props;
    return projectAreas.filter((item) => item.isProjectCreationEnabled);
  };

  handleChangeProjectCategory = () => {
    this.setState({ isChangeCategoryLoading: true });
    setTimeout(() => {
      const { projectCategories } = this.props;
      const clientProjectCategory = projectCategories.find((projectCategory) => projectCategory.key === EProjectCategoryKey.CLIENG.toString());
      if (clientProjectCategory) {
        this.onFormChange(clientProjectCategory.id, FormFields.projectCategory);
        const { paceFieldState } = this.state;
        paceFieldState.setFocus = true;
        this.setState({ paceFieldState });
      }
      this.setState({ isChangeCategoryLoading: false });
    }, 3000);
  }

  updatePageBrTitle() {
    const { record } = this.props;
    if (record && record.name) {
      setPageBrTitle(record.name);
    }
  }

  resetPageBrTitle() {
    setPageBrTitle();
  }

  render() {
    const {
      formState,
      stepIndicatorState,
      stepIndicatorState: { currentStep },
      customizeModeuleEnabled,
      disableSaveBtn,
      showError,
      isDirty,
      paceFieldState,
      isPaceProjectModalOpen,
      approverFieldState,
      showServiceLineModal,
      showOnceConfirmModal,
      projectPermittedServiceLines,
      userServiceLineId,
      isChangeCategoryLoading
    } = this.state;
    const { appCategories, projectTypes, sectors, serviceLines, clientSizes, dataLocations, errorOnCreateOrUpdate, record, history, projectAreas, projectRegions, projectCountries, projectCategories, projectIcons } = this.props;
    const allLookupdataLoaded =
      projectTypes?.length && sectors?.length && serviceLines?.length && clientSizes?.length && dataLocations?.length && projectAreas?.length && projectRegions?.length && projectCountries?.length && projectCategories?.length;
    const projectDetail = this.getProjectDetail();
    const selectedAppCard = this.filterAppCardsByAppIds(this.selectedBundle?.selectedAppIds, record);
    const disableUpdatebtn =
      (!this.createProjectEnable && this.selectedBundle?.selectedAppIds?.length === 0) ||
      !this.checkClientProjectStatus() ||
      (this.isPpeddApproverFeatureEnabled() && !this.validatePpeddApprover()) ||
      !this.isPolicyOfProject_valid() ||
      isDontDeleteTagPresent(formState.projectDesc.fieldVal) ||
      validateFormInputForSpecialChar(formState.projectDesc.fieldVal);
    const isProjectInEditMode = !this.createProjectEnable && !this.isProjectDrafted;
    const isProjectDataLoaded = (record && record.appPlatformDetails && record.infrastructureStatusId && record?.projectStatus.id) ? true : false;
    return (
      <CrashBoundary>
        <div className='create-project'>
          {(!customizeModeuleEnabled || !this.createProjectEnable) && (
            <StepIndicator
              stepIndicatorState={stepIndicatorState}
              handleStepClick={this.handleStepClick}
              createProjectEnable={this.createProjectEnable}
              projectName={formState.projectName.fieldVal}
              showContinueBtn={this.getShowContinueButtonState()}
              onContinueBtnClick={this.continueHandler}
              showBackBtn={currentStep > 1}
              onBackBtnClick={this.backHandler}
              disableAll={!allLookupdataLoaded}
              disableContinueBtn={this.getContinueState()}
              isProjectDrafted={this.isProjectDrafted}
            />
          )}
          {currentStep === 1 && (
            <CrashBoundary>
              
              {isProjectInEditMode && <Loader isLoading={!isProjectDataLoaded} /> }
              <ProjectForm
                formState={formState}
                projectTypes={projectTypes}
                sectors={sectors}
                serviceLines={projectPermittedServiceLines}
                clientSizes={clientSizes}
                dataLocations={dataLocations}
                projectAreas={this.renderProjectAreas()}
                projectRegions={projectRegions}
                projectCountries={projectCountries}
                projectCategories={this.getFilteredProjectCategories()}
                onFormChange={this.onFormChange}
                allLookupdataLoaded={allLookupdataLoaded}
                noOfUsers={(!this.isProjectDrafted && record?.numberOfUsers) || 0}
                projectApps={(this.selectedBundle?.selectedAppIds && this.selectedBundle?.selectedAppIds?.length > 0 && this.selectedBundle.selectedAppIds?.length.toString()) || ''}
                paceFieldState={paceFieldState}
                projectIcons={projectIcons}
                approverFieldState={approverFieldState}
                userServiceLineId={userServiceLineId}
              />
            </CrashBoundary>
          )}
          {currentStep === 2 && !customizeModeuleEnabled && (
            <BundleAppSelect projectDetail={projectDetail} onBundleChange={this.bundleChangeHandler} customizeHandler={this.customizeHandler} onAppSelect={this.handleAppSelect} navigateToStep2handler={this.navigateToStep2} />
          )}
          {
            // custom screen component
            (currentStep === 2 || !this.createProjectEnable) && currentStep !== 3 && customizeModeuleEnabled && this.selectedBundle && this.selectedBundle.id && (
              <CustomizeBundle
                bundleCard={this.selectedBundle}
                saveReturnCustomizationModule={this.saveReturnCustomizationModule}
                cancelHandler={this.disableCustomizationModule}
                continueHandler={this.continueHandler}
                projectEditEnable={this.projectEditEnable}
                createProjectEnable={this.createProjectEnable}
                saveReturnProjectDetails={this.saveReturnProjectDetails}
                isProjectDrafted={this.isProjectDrafted}
                onAppToggle={this.onAppToggle}
                setIsDirty={this.setIsDirty}
                appDetail={record?.appDetails}
                getEditAppClass={this.getEditAppClass}
                record={record}
              />
            )
          }
          {currentStep === 3 && <ConfirmAndCreateProject projectName={projectDetail.projectName} projectType={projectDetail.projectType} bundle={this.selectedBundle} apps={selectedAppCard} appCategories={appCategories} />}
          {(!customizeModeuleEnabled || currentStep === 3) && (
            <ProjectFooter
              saveBtnText={t.save_as_draft}
              showCreateBtn={currentStep === 3}
              createHandler={this.createHandler}
              saveHandler={this.saveHandler}
              cancelHandler={this.cancelHandler}
              disableSaveBtn={disableSaveBtn}
              showSaveBtn={(this.createProjectEnable || this.isProjectDrafted) && currentStep !== 3}
              showUpdateBtn={isProjectInEditMode}
              updateHandler={this.updateHandler}
              disableUpdatebtn={disableUpdatebtn}
              isCreateProjectDisabled={this.props.isCreateProjectDisabled}
              isMaintanenceFlagLoaded={this.props.isMaintanenceFlagLoaded}
            />
          )}
          {errorOnCreateOrUpdate && errorOnCreateOrUpdate !== t.error_message_400 && showError && <AppNotification message={t.something_went_wrong} onCloseNotifcation={this.hideToast} variant='error' />}
          {isPaceProjectModalOpen && (
            <AppModal
              showModal={isPaceProjectModalOpen}
              onModalClose={() => this.setState({ isPaceProjectModalOpen: false })}
              onConfirm={this.handleConfirmPaceProject}
              cancelBtnText={t.cancel}
              confirmBtnText={t.confirm}
              title={t.pace_project_confirm_title}
            >
              <div className='modal-content'>{t.pace_project_confirm_content}</div>
            </AppModal>
          )}
          <AppModal
            showModal={showServiceLineModal && showOnceConfirmModal}
            title={t.project_service_line}
            onConfirm={() =>
              this.setState({
                ...this.state,
                showServiceLineModal: false,
                showOnceConfirmModal: false,
              })
            }
            confirmBtnText={t.ok}
            showCloseModelIcon={false}
          >
            <div className='modal-content'>
              <div className='sub-text'>
                {t.different_service_line_popup_tooltip} <span className='app-title-bold'>({this.getServiceLineName()})</span>
              </div>
            </div>
          </AppModal>
          <RouteLeavingGuard when={isDirty} navigate={(path) => history.push(path)} shouldBlockNavigation={() => isDirty} />
          <Loader isLoading={isChangeCategoryLoading} />
        </div>
      </CrashBoundary>
    );
  }
}

const mapStateToProps = ({
  meReducer,
  projectTypesReducer,
  sectorsReducer,
  clientSizesReducer,
  dataLocationsReducer,
  projectCategoriesReducer,
  projectStatusReducer,
  adminMaintenance,
  createProjectReducer: { createProjectData, updateProjectData, errorOnCreateOrUpdate, loading },
  projects,
  meta,
  searchProjectsReducer,
  searchUsersReducer,
}) => {
  return {
    projectTypes: projectTypesReducer.projectTypes,
    sectors: sectorsReducer.sectors,
    clientSizes: clientSizesReducer.clientSizes,
    dataLocations: dataLocationsReducer.dataLocations,
    projectAreas: dataLocationsReducer.projectAreas,
    projectRegions: dataLocationsReducer.projectRegions,
    projectCountries: dataLocationsReducer.projectCountries,
    projectCategories: projectCategoriesReducer.projectCategories,
    projectStatus: meta.projectStatuses || projectStatusReducer.projectStatus,
    createProjectData: createProjectData,
    updateProjectData: updateProjectData,
    errorOnCreateOrUpdate: errorOnCreateOrUpdate,
    loading: loading,
    bundles: meta.bundles,
    record: projects.record,
    masterBundles: meta.bundles,
    appPlatforms: meta.appPlatforms,
    apps: meta.apps,
    appCategories: meta.appCategories,
    searchLoader: searchProjectsReducer.searchLoader,
    searchProjectsData: searchProjectsReducer.searchProjectsData,
    projectIcons: meta.icons,
    accessRight: meReducer.accessRight,
    currentUser: meReducer.currentUser,
    searchUsersData: searchUsersReducer.searchUsersData,
    searchUsersLoader: searchUsersReducer.searchUsersLoader,
    skipToken: searchUsersReducer.skipToken,
    sspRoles: meta.userRoles,
    serviceLines: meta.serviceLines,
    activeFeatures: projects.activeFeatures,
    isCreateProjectDisabled: adminMaintenance.isCreateProjectDisabled,
    isMaintanenceFlagLoaded: adminMaintenance.isMaintanenceFlagLoaded
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProjectTypes: () => {
      dispatch(getProjectTypesAction());
    },
    getSectors: () => {
      dispatch(getSectorsAction());
    },
    getClientSizes: () => {
      dispatch(getClientSizesAction());
    },
    getDataLocations: () => {
      dispatch(getDataLocationsAction());
    },
    getProjectCategories: () => {
      dispatch(getProjectCategoriesAction());
    },
    getProjectStatus: () => {
      dispatch(getProjectStatusAction());
    },
    createProject: (payload) => {
      dispatch(createProjectAction(payload));
    },
    updateProject: (payload) => {
      dispatch(updateProjectAction(payload));
    },
    clearProjectData: () => {
      dispatch(clearProjectDataAction());
    },
    getBundleCards: (): BundleClass[] => {
      return dispatch(actionstype.getBundles());
    },
    getProjectDetails: (payload) => {
      return dispatch(getProjectDetails(payload));
    },
    flushCreatedBundle: () => {
      return dispatch(flushCreatedBundle());
    },
    flushProjectDetails: () => {
      return dispatch(flushProjectDetails());
    },
    getUpdatedBundles: (payload): BundleClass[] => {
      return dispatch(getUpdatedBundles(payload));
    },
    getAppPlatforms: (): AppPlatformClass[] => {
      return dispatch(actionstype.getAppPlatforms());
    },
    getAppCards: (): AppClass[] => {
      return dispatch(actionstype.getApps());
    },
    getMyAccessRight: (payload) => {
      dispatch(getMyAccessRight(payload));
    },
    getAppCategories: (): AppCategory[] => {
      return dispatch(actionstype.getAppCategories());
    },
    fire_getCountriesCodeAction: () => {
      dispatch(getCountriesCodeAction());
    },
    searchProjects: (payload) => {
      dispatch(searchProjectsAction(payload));
    },
    clearSearchedProjects: (payload) => {
      dispatch(clearSearchedProjectsAction(payload));
    },
    getProjectIcons: () => {
      dispatch(actionstype.getProjectIcons());
    },
    updateProjectDetailsForEMGAction: (payload) => {
      dispatch(updateProjectDetailsForEMG(payload));
    },
    searchUsers: (payload) => {
      dispatch(searchUsersAction(payload));
    },
    getActiveFeatures: (payload) => {
      dispatch(getProjectActiveFeatures(payload));
    },
    showActionToast: (payload) => dispatch(showActionToast(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
