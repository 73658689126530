import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import eyLogoWithCE from '../../assets/images/ey_logo.svg';
import en_translation from "../../localization/en/translation.json";
import DropDownMenu, { IDropDownProps } from '../dropDownMenu/dropDownMenu';
import GetHelp from '../getHelp/getHelp';
import { getMe } from '../../store/actions/user-actions';
import { IAccessRight, IUser } from '../../models/user';
import { ISSPPlatformRole } from '../../models/IRoles';
import { authProvider } from '../../utils/authProvider';
import * as HeaderConstants from './headerConstant';
import './header.scss';
import NotificationBell from "../notificationBell";
import { UserTypes } from '../../utils/Constants';

interface IHeaderProps {
    getCurrentUser: () => any;
    currentUser: IUser;
    accessRight: IAccessRight;
    sspRoles: ISSPPlatformRole[];
}

class Header extends Component<IHeaderProps> {

    userProfileMenuOptions: IDropDownProps;

    constructor(props: IHeaderProps) {
        super(props);

        this.userProfileMenuOptions = {
            controlId: "user-drop-down-menu",
            menuOptions: [],
            onOptionClick: this.onOptionClick
        };
    }

    componentDidMount() {
        const { getCurrentUser, currentUser } = this.props;

        !currentUser.givenName && getCurrentUser();
    }

    userDisplayNameHTML = () => {
        const { currentUser } = this.props;
        return (
            <div>
                <span className="font-bold">
                    {currentUser?.givenName}&nbsp;{currentUser?.surname}
                </span>
                <br />
                <span className="color-grey"> {currentUser?.mail} </span>
            </div>
        );
    };

    ipAssetManagerHtml = () => {
        return (
            <div>
                <span className="font-bold">{en_translation.header_ip_asset_manager}</span>
            </div>
        );
    };

    userLogOutHTML = () => {
        return (
            <div>
                <span className="font-bold">{en_translation.header_user_logout}</span>
            </div>
        );
    };

    userAdminConsoleHTML = () => {
        return (
            <div>
                <span className="font-bold">{en_translation.header_admin_console}</span>
            </div>
        );
    };

    handleAdminConsoleClick = () => {
        window.open(`${process.env.REACT_APP_URL}/admin/projects`, "_blank");
    }

    handleIpAssetManagerClick = () => {
        window.open(`${process.env.REACT_APP_IP_ASSET_MANAGER_URL}`, "_blank");
    }

    onLogOutClick = () => {
        authProvider.logout();
    }

    setUserProfileDropDownMenuItems = () => {
        const { currentUser, accessRight, sspRoles } = this.props;
        this.userProfileMenuOptions.controlTitle = currentUser?.givenName;
        this.userProfileMenuOptions.menuOptions.length = 0;
        this.userProfileMenuOptions.imageData = currentUser?.photo;

        this.userProfileMenuOptions.menuOptions.push(
            {
                id: "user-profile-menu-id",
                titleHTML: this.userDisplayNameHTML,
                actionName: HeaderConstants.PROFILE_ACTION,
                title: "",
            },
            {
                id: "user-log-out-id",
                titleHTML: this.userLogOutHTML,
                actionName: HeaderConstants.LOGOUT_ACTION,
                title: "",
            }
        );

        if (accessRight.portalRoles && sspRoles) {
            const portalAdminId = sspRoles.find(role => role.key === 'POR-ADMIN')?.id;
            const adminConsoleUserId = sspRoles.find(role => role.key === 'POR-ADMIN-CONSL')?.id;
            const isAdminConsoleUser = accessRight.portalRoles.find(role => role === portalAdminId || role === adminConsoleUserId);

            if (isAdminConsoleUser) {

                const adminConsoleOption = {
                    id: "user-admin-console-id",
                    titleHTML: this.userAdminConsoleHTML,
                    actionName: HeaderConstants.ADMIN_CONSOLE_ACTION,
                    title: "",
                }

                this.userProfileMenuOptions.menuOptions.splice(1, 0, adminConsoleOption);
            }
        }

        if (currentUser.userType?.toLowerCase() === UserTypes.Internal.toLowerCase() && accessRight.hasIpAssetManagerAccess) {
            const ipAssetManagerOption = {
                id: "ip-asset-manager-id",
                titleHTML: this.ipAssetManagerHtml,
                actionName: HeaderConstants.IP_ASSET_MANAGER_ACTION,
                title: "",
            }

            this.userProfileMenuOptions.menuOptions.splice(1, 0, ipAssetManagerOption);
        }
    };

    tooltipHTML = () => (
        <div>
            {en_translation.header_help_contact_text} <br />
            {en_translation.header_help_contact_number}
        </div>
    );

    onOptionClick = option => {
        if (option === HeaderConstants.ADMIN_CONSOLE_ACTION) {
            this.handleAdminConsoleClick();
        } else if (option === HeaderConstants.IP_ASSET_MANAGER_ACTION) {
            this.handleIpAssetManagerClick();
        }
        else if (option === HeaderConstants.LOGOUT_ACTION) {
            this.onLogOutClick();
        }
    }

    render() {
        const { currentUser } = this.props;
        this.setUserProfileDropDownMenuItems();

        return (
            <header className="header">
                <Link to="/">
                    <img src={eyLogoWithCE} className="logo m-l-20" alt="logo" />
                </Link>
                <div className="content m-t-10">
                    <NotificationBell />
                    <div className="help-icon">
                        <GetHelp currentUser={currentUser} />
                    </div>
                    <div className="greyed-outline">
                        <DropDownMenu
                            controlId={this.userProfileMenuOptions.controlId}
                            controlTitle={this.userProfileMenuOptions.controlTitle}
                            menuOptions={this.userProfileMenuOptions.menuOptions}
                            imageData={this.userProfileMenuOptions.imageData}
                            onOptionClick={this.onOptionClick}
                        />
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.meReducer.currentUser,
        accessRight: state.meReducer.accessRight,
        sspRoles: state.meta.userRoles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrentUser: () => {
            dispatch(getMe())
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);