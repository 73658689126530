import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  CLOSE_NOTIFICATION_ACTION,
  READ_NOTIFICATION_ACTION,
} from "../../store/actions/me-actions";
import {
  CloseNote,
  ExpandDetails,
  NotificationDetails,
  NotificationHeader,
  NotificationItem,
  UnreadIndicator,
} from "./parts";
import { DefaultTheme, makeStyles } from "@material-ui/styles";

const Header = (props) => <NotificationHeader {...props} />;

export const Notification = ({
  notificationId,
  version,
  read,
  content,
  tagBackground,
  tagSVGIcon,
  headerBackGround,
}) => {
  const dispatch = useDispatch();
  const [openNote, setOpenNote] = useState<boolean>();
  const [close, setClose] = useState<boolean>();
  const currentComponentRef = useRef<HTMLElement | null>(null);
  const contentRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (openNote && contentRef.current && currentComponentRef.current) {
      // TODO- need to rewrite into material ui transition component to do animation upon property changes
      requestAnimationFrame(() => {
        (contentRef.current as unknown as HTMLElement).innerHTML = content;
        (contentRef.current as unknown as HTMLElement).style.marginLeft = "0";
        (currentComponentRef.current as unknown as HTMLElement).scrollIntoView({
          inline: "nearest",
          block: "nearest",
        });
      });
    }
  }, [currentComponentRef, close, contentRef, openNote, content]);

  const onCloseItem = (e) => {
    setClose(true);
    dispatch(CLOSE_NOTIFICATION_ACTION({ notificationId }));
    e.stopPropagation();
  };
  const onReadContent = () => {
    setOpenNote(!openNote);
    dispatch(READ_NOTIFICATION_ACTION({ notificationId }));
  };
  return (
    <NotificationItem
      id={version}
      read={read}
      openNote={openNote}
      close={close}
      ref={(ref) => (currentComponentRef.current = ref)}
    >
      <div style={{ display: "flex", flexDirection: "row", marginBlock: 3 }}>
        <Tag
          openNote={openNote}
          background={tagBackground}
          svgIcon={tagSVGIcon}
        />
        <Header
          title={`${version}`}
          openNote={openNote}
          onClick={onReadContent}
          background={headerBackGround}
        >
          {version} &nbsp;
          {!read && <UnreadIndicator />}
          <CloseNote read={read} onClick={onCloseItem} />
          <ExpandDetails openNote={openNote} />
        </Header>
      </div>
      {openNote && (
        <NotificationDetails ref={(ref) => (contentRef.current = ref)} />
      )}
    </NotificationItem>
  );
};
const useTag = makeStyles<
  DefaultTheme,
  { openNote?: boolean; background?: string },
  "root"
>({
  root: ({ openNote, background }) => ({
    background: background,
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    paddingBlock: "8px",
    paddingInline: "20px",
    marginTop: openNote ? "3px" : "0",
  }),
});

const Tag = (props) => {
  return <span className={useTag(props).root}>{props.svgIcon}</span>;
};
