import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDownMenu, { IDropDownProps } from '../dropDownMenu/dropDownMenu';
import eyLogoWithCE from '../../assets/images/ey_logo.svg';
import en_translation from "../../localization/en/translation.json";
import { getMe } from '../../store/actions/user-actions';
import { IUser } from '../../models/user';
import { authProvider } from '../../utils/authProvider';
import * as HeaderConstants from '../header/headerConstant';
import './headerAdmin.scss';
import NotificationBell from "../notificationBell";

interface IHeaderProps {
  getCurrentUser: () => any;
  currentUser: IUser;
}

class HeaderAdmin extends Component<IHeaderProps> {

  userProfileMenuOptions: IDropDownProps;

  constructor(props: IHeaderProps) {
    super(props);

    this.userProfileMenuOptions = {
      controlId: "user-drop-down-menu",
      menuOptions: [],
      onOptionClick: this.onOptionClick
    };
  }


  componentDidMount() {
    const { getCurrentUser, currentUser } = this.props;

    !currentUser.givenName && getCurrentUser();
  }

  userDisplayNameHTML = () => {
    const { currentUser } = this.props;
    return (
      <div>
        <span className="font-bold">
          {currentUser?.givenName}&nbsp;{currentUser?.surname}
        </span>
        <br />
        <span className="color-grey"> {currentUser?.mail} </span>
      </div>
    );
  };

  userLogOutHTML = () => {
    return (
      <div>
        <span className="font-bold">{en_translation.header_user_logout}</span>
      </div>
    );
  };

  onLogOutClick = () => {
    authProvider.logout();
  }

  setUserProfileDropDownMenuItems = () => {
    const { currentUser } = this.props;
    this.userProfileMenuOptions.controlTitle = currentUser?.givenName;
    this.userProfileMenuOptions.menuOptions.length = 0;
    this.userProfileMenuOptions.imageData = currentUser?.photo;
    this.userProfileMenuOptions.menuOptions.push(
      {
        id: "user-profile-menu-id",
        titleHTML: this.userDisplayNameHTML,
        title: "",
        actionName: HeaderConstants.PROFILE_ACTION,
      },
      {
        id: "user-log-out-id",
        titleHTML: this.userLogOutHTML,
        title: "",
        actionName: HeaderConstants.LOGOUT_ACTION,
      }
    );
  };

  onOptionClick = option => {
    if (option === HeaderConstants.LOGOUT_ACTION) {
      this.onLogOutClick();
    }
  }

  tooltipHTML = () => (
    <div>
      {en_translation.header_help_contact_text} <br />
      {en_translation.header_help_contact_number}
    </div>
  );

  render() {
    this.setUserProfileDropDownMenuItems();
    return (
      <header className="header">
        <Link to="/">
          <img src={eyLogoWithCE} className="logo m-l-20" alt="logo" />
        </Link>
        <span className="header-admin-title">{en_translation.admin_header_title}</span>
        <div className="content m-t-10">
          <NotificationBell/>
          <div className="greyed-outline">
            <DropDownMenu
              controlId={this.userProfileMenuOptions.controlId}
              controlTitle={this.userProfileMenuOptions.controlTitle}
              menuOptions={this.userProfileMenuOptions.menuOptions}
              imageData={this.userProfileMenuOptions.imageData}
              onOptionClick={this.onOptionClick}
            />
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.meReducer.currentUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCurrentUser: () => { dispatch(getMe()) },
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
