import React, { FC } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import { AppIconButton } from "../../theme";
import "./appNotification.scss";

interface IProps {
  onCloseNotifcation?: () => void,
  message: string,
  variant: string
}

const AppNotification: FC<IProps> = ({ message, variant, onCloseNotifcation }) => {

  const variantIcon = (variant:string) => {
    switch (variant) {
      case "error":
        return <ErrorIcon />;
      case "info":
        return <InfoIcon />;
      default:
        return <CheckCircleIcon />;
    }
  }

  return (
    <div className="app-notification">
      <div className="notification-wrapper">
        <div className="notification-text">
          <span className={`notification-icon ${variant}`}>
            {variantIcon(variant)}
          </span>
          {message}
        </div>
        <AppIconButton aria-label="close" className="" onClick={onCloseNotifcation}>
          <CloseIcon />
        </AppIconButton>
      </div>
    </div>
  );
};

export default AppNotification;
