import React, { useState } from 'react';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';
import AppIcon from '../../theme/appIcon/appIcon';
import { angleDown, bell, cogs } from '../../theme/icons';
import tr from "../../localization/en/translation.json";
import './manageWorkflowsHeader.scss';
import { TAB_NAME, generalSubTabs } from '../../containers/projectWorkflow/projectWorkflowConstants';

const SubNavLink = ({ link, projectId, icon }) => (
  <NavLink to={`/project/${projectId}/${link.url}`} className='sub-nav-link' activeClassName='selected'>
    <AppIcon icon={icon} /> {link.name}
  </NavLink>
);

const SubMenuDropDown = ({ links, projectId }) => (
  <div className='dropdown-content'>
    {links.map(link => (
      <SubNavLink key={link.key} link={link} projectId={projectId} icon={link.icon} />
    ))}
  </div>
);

const DropdownItem = ({ match, links, text, icon, projectId }) => {
  const isMatched = useRouteMatch(match);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <li
      className={`dropdown ${isOpen && (isMatched || isOpen) ? 'open' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`nav-link ${isMatched ? 'selected' : ''}`}>
        <AppIcon icon={icon} />
        <span className='nav-text'>{text}</span>
        <AppIcon icon={angleDown} className='arrow-icon' />
      </div>
      {(isOpen && (isMatched || isOpen)) && <SubMenuDropDown links={links} projectId={projectId} />}
    </li>
  );
};

const ManageWorkflowsHeader: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <div className='nav-menu'>
      <nav>
        <ul>
          <DropdownItem
            match={`/project/${id}/manage-workflows/workflows/*`}
            links={generalSubTabs.filter(link => link.url.startsWith(`${TAB_NAME.MANAGE_WORKFLOWS_WORKFLOWS}`))}
            text={tr.admin_project_more_menu_workflows}
            icon={cogs}
            projectId={id}
          />
          <DropdownItem
            match={`/project/${id}/manage-workflows/email-communications/*`}
            links={generalSubTabs.filter(link => link.url.startsWith(`${TAB_NAME.MANAGE_WORKFLOWS_EMAIL_NOTIFICATIONS}`))}
            text={tr.project_email_notifications}
            icon={bell}
            projectId={id}
          />
        </ul>
      </nav>
    </div>
  );
};

export default ManageWorkflowsHeader;
