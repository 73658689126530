import React from "react";

export const StopWatchSVG = () => (
  <svg
    className={"MuiSvgIcon-root"}
    style={{ color: "white", width: "40px", height: "40px" }}
    viewBox="0 0 24 24"
  >
    <path d="M19.414 8.902c.104-.048.206-.108.293-.195l.5-.5c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0l-.5.5-.115.173c-1.387-1.312-3.188-2.19-5.189-2.41l.011-.056v-1h1c.55 0 1-.45 1-1s-.45-1-1-1h-4c-.55 0-1 .45-1 1s.45 1 1 1h1v1l.012.057c-4.506.492-8.012 4.307-8.012 8.943 0 4.971 4.029 9 9 9s9-4.029 9-9c0-1.894-.588-3.648-1.586-5.098zm-7.414 12.098c-3.859 0-7-3.14-7-7s3.141-7 7-7 7 3.14 7 7-3.141 7-7 7zM13 13v-2c0-.55-.45-1-1-1s-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1h-2zM12 8c-3.312 0-6 2.688-6 6s2.688 6 6 6 6-2.688 6-6-2.688-6-6-6zm0 11c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z" />
  </svg>
);
export const NewspaperSVG = () => (
  <svg
    className={"MuiSvgIcon-root"}
    style={{ color: "white", width: "33px", height: "33px" }}
    viewBox="0 0 24 24"
  >
    <path d="M20,11H4V8H20M20,15H13V13H20M20,19H13V17H20M11,19H4V13H11M20.33,4.67L18.67,3L17,4.67L15.33,3L13.67,4.67L12,3L10.33,4.67L8.67,3L7,4.67L5.33,3L3.67,4.67L2,3V19A2,2 0 0,0 4,21H20A2,2 0 0,0 22,19V3L20.33,4.67Z" />
  </svg>
);

export const SurveySVG = () => (
  <svg
    className={"MuiSvgIcon-root"}
    style={{ color: "white", width: "33px", height: "33px" }}
    viewBox="0,0,512,512"
  >
    <path d="m425.5,85.5h-61.9c-1.8-13.9-3.1-24.2-3.1-24.2-1.3-10.2-10-17.9-20.4-17.9h-63.5v-12c0-11.3-9.2-20.4-20.5-20.4-11.3,0-20.5,9.1-20.5,20.4v12h-63.6c-10.4,0-19.1,7.7-20.4,17.9l-3,24.2h-62c-11.3,0-20.5,9.1-20.5,20.4v374.6c0,11.3 9.2,20.4 20.5,20.4h338.9c11.3,0 20.5-9.1 20.5-20.4v-374.6c0.1-11.2-9.1-20.4-20.5-20.4zm-235.4-1.2h131.9l6.7,53.2h-145.3l6.7-53.2zm214.9,375.9h-297.8v-333.8h36.3l-3.6,29c-0.7,5.8 0,22.9 20.4,22.9h191.7c0.1,0 0.1,0 0.2,0 11.4,0 20.5-9.1 20.5-20.4 0-0.7-1.7-14.5-3.9-31.5h36.2v333.8z" />
  </svg>
);
export const AdhocNewsSVG = () => (
  <svg
    className={"MuiSvgIcon-root"}
    style={{ color: "white", width: "33px", height: "33px" }}
    viewBox="0,0,512,512"
  >
    <path d="M456 32h-304C121.1 32 96 57.13 96 88v320c0 13.22-10.77 24-24 24S48 421.2 48 408V112c0-13.25-10.75-24-24-24S0 98.75 0 112v296C0 447.7 32.3 480 72 480h352c48.53 0 88-39.47 88-88v-304C512 57.13 486.9 32 456 32zM464 392c0 22.06-17.94 40-40 40H139.9C142.5 424.5 144 416.4 144 408v-320c0-4.406 3.594-8 8-8h304c4.406 0 8 3.594 8 8V392zM264 272h-64C186.8 272 176 282.8 176 296S186.8 320 200 320h64C277.3 320 288 309.3 288 296S277.3 272 264 272zM408 272h-64C330.8 272 320 282.8 320 296S330.8 320 344 320h64c13.25 0 24-10.75 24-24S421.3 272 408 272zM264 352h-64c-13.25 0-24 10.75-24 24s10.75 24 24 24h64c13.25 0 24-10.75 24-24S277.3 352 264 352zM408 352h-64C330.8 352 320 362.8 320 376s10.75 24 24 24h64c13.25 0 24-10.75 24-24S421.3 352 408 352zM400 112h-192c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h192c17.67 0 32-14.33 32-32v-64C432 126.3 417.7 112 400 112z" />
  </svg>
);
