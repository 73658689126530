// eslint-disable-next-line
export default {
    // FontFamily variables -
    fontFamilyBold: "EYInterstate-Bold, sans-serif",
    fontFamilyLight: "EYInterstate-Light, sans-serif",

    // Color variables -
    white: "white",
    black: "black",
    textButtonColor: "#585860",
    buttonDisabledBgColor: "#eaeaf2",
    buttonPrimaryBgColor: "#ffe600",
    buttonDisabledColor: "#747480",
    buttonHoverBgColor: "#2e2e38",
    buttonSecondaryColor: "#2e2e38",
    tuna: "rgb(46,46,56)",
    thunderbird: "rgb(185, 37, 28)",
    gray: "rgb(128, 128, 128)",

    // FontSize variables -
    fontSize12: "0.75rem",
    fontSize13: "0.813rem",
    fontSize14: "0.875rem",
    fontSize16: "1rem",
}
