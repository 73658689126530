export const  getAnnouncementChunks = (state, nextAnnouncements) => {
  const { chunk } = state.announcementPaginationData;
  const newState = { ...state };

  newState.announcements = [...newState.announcements, ...nextAnnouncements];
  newState.announcementPaginationData = {
    isAllAnnoucementsFetched: !nextAnnouncements.length,
    chunk: chunk + 1
  }

  return newState
}

export const  flushAnnouncements = (state) => {
  const newState = { ...state };

  newState.announcements = [];
  newState.announcementPaginationData = {
    isAllAnnoucementsFetched: false,
    chunk: 1
  }

  return newState
}
