import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APP_STATE } from "../../../store/reducers";
import { Box } from "@material-ui/core";
import List from "../../editableGrid/list/list";
import { columnConfig } from "./surveyConfig";
import { AppIcon, AppIconButton, AppModal } from "../../../theme";
import { refresh, trash, add } from "../../../theme/icons";
import lang from "../../../localization/en/translation.json";
import { getAdminConsoleAccess } from "../../../utils/helper-utility";
import {
  ROW_MIN_HEIGHT,
  DEFAULT_SORT,
  MORE_MENU_ACTION_NAME,
} from "./adminSurveysConstants";
import { GridApi } from "ag-grid-community";
import { MoreMenuOption } from "../../moreMenu/moreMenuOptions";
import MoreMenu from "../../moreMenu/moreMenu";
import {
  GetAdminSurvey,
  deleteSurveyConfirm,
  addAdminSurvey,
  UpdateAdminSurvey,
  getSurveyDurationQuarter,
} from "../../../store/actions/admin-general-actions";
import { SurveyTableFields } from "./adminSurveysConstants";
import { AdminSurveyFormModal, Data } from "./adminSurveyModal";
import { showActionToast } from "../../../store/actions/notification-actions";
import { renderDateCell } from "../../adminWorkflows/Executions/renderDateCell";
import { UserTypes } from "../../../utils/Constants";
import _ from "lodash";
import "./adminSurvey.scss";
import { RenderEmailCell } from "../renderEmailCell";

const AdminSurveys = () => {
  const listRef = useRef<{ api: GridApi }>();
  const [selectedSurvey, setSelectedSurvey] = useState<Data | any>({});
  const [selectedsurveyRow, setSelectedSurveyRow] = useState<Data | any>({});
  const [showAddOrEditModal, setShowAddOrEditModal] = useState<{} | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dropdownItemList, setDropdownItemList] = useState<[] | any>([]);
  const [dropdownFYear, setDropdownFYear] = useState<[] | any>([]);
  const [dropdownDurationList, setDropdownDurationList] = useState<[] | any>(
    []
  );
  const [_userTypes, setUserTypes] = useState([]);

  const isAdminConsoleUserOnly = useSelector<APP_STATE, boolean>(
    ({ meReducer, meta }) => {
      const sspRoles = meta.userRoles;
      const accessRight = meReducer.accessRight;
      const portalRole =
        (accessRight?.portalRoles &&
          accessRight.portalRoles.length > 0 &&
          accessRight.portalRoles[0]) ||
        "";
      const _result = getAdminConsoleAccess(portalRole, sspRoles);
      return _result.portalAdminConsole && !_result.portalAdmin;
    }
  );
  const dispatch = useDispatch();

  let survey: any = useSelector<APP_STATE>(
    ({ adminGeneralReducer }) => adminGeneralReducer.survey
  );

  const dropdownItemsForDuration: any = useSelector<APP_STATE>(
    ({ adminGeneralReducer }) => adminGeneralReducer.surveyDurationDropdown
  );

  useEffect(() => {
    dispatch(GetAdminSurvey());
  }, [dispatch, isAdminConsoleUserOnly]);

  useEffect(() => {
    closeAddUpdateModal();
  }, [survey]);

  useEffect(() => {
    if (selectedSurvey?.fiscalQuarter && selectedSurvey?.fiscalYear) {
      const surveyItem = { ...selectedSurvey };
      const durationList = dropdownItemList?.find(
        (x) =>
          x.fiscalQuarter.toString() === surveyItem?.fiscalQuarter.toString() &&
          x.fiscalYear.toString() === surveyItem?.fiscalYear.toString()
      );
      if (durationList) {
        const dropdownOptionsForduration: { value: number; label: string }[] =
          [];
        durationList.durations.forEach((key, val) => {
          dropdownOptionsForduration.push({
            value: key.value,
            label: `${key.name}  ${key.description}`,
          });
        });
        setDropdownDurationList(dropdownOptionsForduration);
      }
    }
  }, [selectedSurvey, dropdownItemList]);

  useEffect(() => {
    if (dropdownItemsForDuration?.length) {
      setDropdownItemList(_.cloneDeep(dropdownItemsForDuration));
      const dropdownFiscalDuration = _.cloneDeep(dropdownItemsForDuration);
      const dropdownOptionsForYear: { value: string; label: string }[] = [];
      dropdownFiscalDuration.forEach((key, val) => {
        dropdownOptionsForYear.push({
          value: `${key.fiscalYear}-${key.fiscalQuarter}`,
          label: key.description,
        });
      });
      setDropdownFYear(dropdownOptionsForYear);
    }
  }, [dropdownItemsForDuration]);

  const handleAddRow = (id?: string) => {
    setShowAddOrEditModal(true);
    dispatch(getSurveyDurationQuarter(id));
  };

  const onActionStart = (actionName: string) => {
    const rows: any = listRef.current?.api?.getSelectedRows();
    const surveyRow = _.cloneDeep(rows[0]);
    setUserTypes(surveyRow.userTypes);
    surveyRow[
      SurveyTableFields.fiscalYearQuarter
    ] = `${surveyRow.fiscalYear}-${surveyRow.fiscalQuarter}`;
    setSelectedSurvey(surveyRow);
    setSelectedSurveyRow(surveyRow);
    if (actionName === MORE_MENU_ACTION_NAME.UPDATE) {
      handleAddRow(surveyRow.id);
    } else if (actionName === MORE_MENU_ACTION_NAME.DELETE) {
      setDeleteModal(true);
    }
  };

  const RenderMoreMenu = ({ disable, onActionStart }) => {
    const options: MoreMenuOption[] = [
      {
        id: "124",
        title: lang.update,
        icon: refresh,
        actionName: MORE_MENU_ACTION_NAME.UPDATE,
        isDisabled: isAdminConsoleUserOnly,
      },
      {
        id: "125",
        title: lang.delete,
        icon: trash,
        actionName: MORE_MENU_ACTION_NAME.DELETE,
        isDisabled: isAdminConsoleUserOnly,
      },
    ];

    return (
      <MoreMenu
        controlId="more-menu-apps"
        optionClickFn={onActionStart}
        options={options}
        vertical
        leftPlacement
        disabled={disable}
      />
    );
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setSelectedSurvey({});
  };

  const deleteSurvey = () => {
    if (selectedSurvey) {
      dispatch(deleteSurveyConfirm(selectedSurvey));
      closeDeleteModal();
    }
  };

  const closeAddUpdateModal = () => {
    setShowAddOrEditModal(false);
    setSelectedSurvey({});
  };

  const handleAddUpdateForm = (key, value) => {
    let updatedItem;
    if (key === SurveyTableFields.fiscalYear) {
      updatedItem = {
        ...selectedSurvey,
        [SurveyTableFields.fiscalYear]: value.split("-")[0],
        [SurveyTableFields.fiscalQuarter]: value.split("-")[1],
        [SurveyTableFields.fiscalYearQuarter]: value,
        [SurveyTableFields.activeDuration]: "",
      };
    } else if (key === UserTypes.Internal) {
      updatedItem = {
        ...selectedSurvey,
        userTypes: !!value
          ? [..._userTypes, key]
          : _userTypes?.filter((value) => value !== key),
      };
    } else if (key === UserTypes.External) {
      updatedItem = {
        ...selectedSurvey,
        userTypes: !!value
          ? [..._userTypes, key]
          : _userTypes?.filter((value) => value !== key),
      };
    } else {
      updatedItem = {
        ...selectedSurvey,
        [key]: value,
      };
    }
    setUserTypes(updatedItem?.userTypes || []);
    setSelectedSurvey(updatedItem);
  };

  const submitAddUpdateForm = () => {
    if (checkforDuplicateSurvey()) {
      dispatch(
        showActionToast({
          message: lang.admin_survey_duplicate_failure,
          type: "error",
        })
      );
      return;
    }
    if (selectedSurvey?.id) {
      dispatch(UpdateAdminSurvey(selectedSurvey));
    } else {
      dispatch(addAdminSurvey(selectedSurvey));
    }
  };

  const checkforDuplicateSurvey = () => {
    return survey.some((item) =>
      item?.id !== selectedSurvey?.id
        ? item.fiscalQuarter.toString() ===
            selectedSurvey?.fiscalQuarter?.toString() &&
          item.fiscalYear.toString() === selectedSurvey?.fiscalYear?.toString()
        : false
    );
  };

  const AddSurveyButton = ({ disable, onClick }) => (
    <div className="add-btn">
      <span id={"add_new_survey"} onClick={!disable && onClick}>
        <AppIconButton
          aria-label="add"
          disabled={disable}
          title={"Add new survey"}
          className="add-btn-icon"
        >
          <AppIcon icon={add} className="add-icon" />
        </AppIconButton>
        <span className={`label-icon ${disable ? "label-disable" : ""}`}>
          {lang.admin_cps_project_add_row}
        </span>
      </span>
    </div>
  );

  return (
    <>
      <span>{lang.admin_survey_release_tab_info}</span>
      <Box className={"admin-portal-survey"} sx={{ paddingTop: 20 }}>
        <AddSurveyButton
          disable={isAdminConsoleUserOnly}
          onClick={() => {
            handleAddRow();
          }}
        />
        <Box className={`admin-portal-survey-table`}>
          <List
            columnConfig={columnConfig}
            firstColumnBorderRight={true}
            rowData={survey}
            paginate={false}
            withStickyScroll
            frameworkComponents={{
              dateCellRender: renderDateCell,
              renderEmailCell: RenderEmailCell,
              moreMenuCellRenderer: () => (
                <RenderMoreMenu
                  disable={isAdminConsoleUserOnly}
                  onActionStart={onActionStart}
                />
              ),
            }}
            handleGridReady={(gridApi) => {
              listRef.current = gridApi;
            }}
            defaultSort={[DEFAULT_SORT]}
            getRowHeight={() => ROW_MIN_HEIGHT}
          />
          <AppModal
            showModal={deleteModal}
            onModalClose={closeDeleteModal}
            onConfirm={deleteSurvey}
            cancelBtnText={lang.cancel}
            confirmBtnText={lang.delete}
            title={lang.admin_cps_modal_delete_title}
          >
            <div className="modal-content deleteModal">
              <div className="app-title-bold">
                <span className="app-modal-delete-body">
                  {lang.admin_apps_modal_delete_body}{" "}
                </span>
                <span className="red-highlighted">{lang.delete} </span>
                <span>
                  {selectedSurvey && selectedSurvey[SurveyTableFields.name]}?{" "}
                </span>
              </div>
            </div>
          </AppModal>
          {showAddOrEditModal && (
            <AdminSurveyFormModal
              setOpenModal={closeAddUpdateModal}
              initial={selectedSurvey}
              original={selectedsurveyRow}
              handleAddUpdateForm={handleAddUpdateForm}
              formSubmit={submitAddUpdateForm}
              dropdownFYear={dropdownFYear}
              dropdownDuration={dropdownDurationList}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminSurveys;
