import axios from 'axios';

export const REQUEST_TYPE = {
	GET: "get",
	PUT: "put",
	DELETE: "delete",
	POST: "post",
	PATCH: "patch"
}

export const API_URL = process.env.REACT_APP_API_URL;

export const header =  {
	       headers: {
	           "Content-type": "multipart/form-data",
	       }
	   };

export const createAxiosRequest = (type, url, payload = {}) => {
	return axios[type](url, type === REQUEST_TYPE.DELETE? {data: payload}:payload).then((response) => {
		return response.data
	}).catch((error) => {
		throw error;
	});
}


export const createAxiosRequestMultiPartData = (type, url, payload = {}) => {
	return axios[type](url, payload, header).then((response) => {
		return response;
	}).catch((error) => {
		throw error;
	});
}

