import * as type from "../action-types";
import { setSelectedIds, addOwnBundle, flushOwnBundle, getUpdatedBundles, getOwnBundleId } from "../operations/createProjectOperations";

const initialState = {
  createProjectData: [],
  updateProjectData: [],
  loading: false,
  deletedProject: {},
  errorOnCreateOrUpdate: null,
  bundles: [],
  isOwnBundleCreated: false,
  ownBundleId: "",
  closeProject: {}
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_PROJECT:
      return { ...state, loading: true, errorOnCreateOrUpdate: null };
    case type.CREATE_PROJECT_SUCCESS:
      return { ...state, createProjectData: action.payload.data, loading: false, errorOnCreateOrUpdate: null };
    case type.CREATE_PROJECT_FAILURE:
      return { ...state, createProjectData: [], loading: false, errorOnCreateOrUpdate: action.payload };
    case type.UPDATE_PROJECT:
      return { ...state, loading: true, errorOnCreateOrUpdate: null };
    case type.UPDATE_PROJECT_SUCCESS:
      return { ...state, updateProjectData: action.payload.data, loading: false, errorOnCreateOrUpdate: null };
    case type.UPDATE_PROJECT_FAILURE:
      return { ...state, updateProjectData: [], loading: false, errorOnCreateOrUpdate: action.payload };
    case type.DELETE_DRAFT_PROJECT_SUCCESS:
      return { ...state, deletedProject: action.payload };
    case type.CLEAR_PROJECT_DATA:
      return { ...state, createProjectData: [], errorOnCreateOrUpdate: null };
    case type.FLUSH_DELETED_PROJECT:
      return { ...state, deletedProject: {} };
    case type.GET_UPDATED_BUNDLES:
      return{ ...state, bundles: getUpdatedBundles(action.payload), ownBundleId: getOwnBundleId(action.payload) }
    case type.UPDATE_ACTIVE_APPS:
        return { ...state, bundles: setSelectedIds(state.bundles, action.payload), loading: false };
    case type.FLUSH_UPDATED_BUNDLES:
        return { ...state, bundles: [], loading: false };
    case type.CREATE_OWN_BUNDLE:
        return { ...state, bundles: addOwnBundle(state.bundles, action.payload), isOwnBundleCreated: true, loading: false}
    case type.FLUSH_CREATED_BUNDLE:
        return { ...state, bundles: flushOwnBundle(state.bundles), isOwnBundleCreated: false, loading: false}
    case type.CLOSE_PROJECT_SUCCESS:
          return { ...state, closeProject: action.payload };
    case type.FLUSH_CLOSE_PROJECT:
          return { ...state, closeProject: {}}
    default:
      return state;
  }
};
