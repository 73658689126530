export const columnConfig = [
  {
    headerName: ' ',
    cellRenderer: 'moreMenuCellRenderer',
    field: 'iconButton',
    minWidth: 43,
    width: 43,
    pinned: 'left',
    lockPosition: true,
    sortable: false,
    cellClass: "update-client-size-cell-renderer"
  },
  {
    headerName: 'Client size',
    field: 'name',
   // sortable: true,
    width: 300,
    sort: "asc",
    filter: "customFilter",
    lockPosition: true
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 550,
    cellRenderer: 'clientSizeDescriptionCellRender',
    cellClass: "description-cell-renderer",
    wrapText: true,
    lockPosition: true,
  },
]