import React from 'react';
import Switch from '@material-ui/core/Switch';
import "./appToggleSwitch.scss";

interface IProps {
    checked?: boolean
    onChange: Function
    id?: string
}

const AppToggleSwitch: React.FC<IProps> = ({checked = false, onChange, id="app-toggle-switch"}) => {

  return (
    <span className="app-toggle-switch">
        <Switch 
            checked={checked}
            onChange={(e) => onChange(e)}
            id={id}
        />
    </span>
  );
}

export default AppToggleSwitch