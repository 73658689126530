import React, {useCallback, useState} from "react";
import AppModal from "../../theme/appModal/appModal";
import {Box} from "@material-ui/core";
import AppSelect from "../../theme/appSelect/appSelect";
import AppInput from "../../theme/appInput/appInput";
import content from "../../localization/en/translation.json";
import {patchProject} from "../../store/apis/projectApi";
import {useDispatch} from "react-redux";
import {showActionToast} from "../../store/actions/notification-actions";

const SharePointSettings = ({sharePointBaseUrl, isEditable, projectId, onClose, sync}: {sharePointBaseUrl?: string, projectId: string,isEditable?: boolean, onClose: ()=>void, sync?: boolean})=>{
    const dispatch = useDispatch();
    const [_sync, setSync] = useState(sync);
    const onSave = useCallback(()=>{
        (async()=>{
            try{
                await patchProject({projectId, isSharePointSyncEnabled: _sync});
                dispatch(showActionToast({type: "success", message: "SharePoint settings are updated!"}));
                onClose();
            }catch (e){
                dispatch(showActionToast({type: "error", message: "SharePoint settings update is failed!"}));
            }
        })()
    },[dispatch, onClose, projectId, _sync]);
    return (
        <AppModal size={"md"} title={content.sharePointSettingTitle} showModal={true} showCloseBtnToModal={true}
                  confirmBtnText={content.save} cancelBtnText={content.cancel} disabledValue={!isEditable} onConfirm={onSave} onModalClose={onClose}>
          <>
              <Box sx={{padding: 10, paddingTop: 20, paddingBottom: 20, display: "flex"}}>
                <Box width={"50%"}>
              <AppSelect style={{width: "100%"}} label={content.sharePointSettingSyncField}
                         options={[{label:"Yes", value: true}, {label:"No", value: false}]}
                         disabled={!isEditable}
                         onChange={(e)=> setSync(e.target.value as boolean)}
                         value={String(_sync)}/>
                    </Box>
                  <Box width={"50%"} sx={{pl: 3, fontSize:"0.9rem", fontFamily:"EYInterstate-Bold"}}><strong>{content.sharePointSettingSyncFieldDesc}</strong></Box>
              </Box>
              <Box sx={{padding: 10, paddingTop: 20, paddingBottom: 20, display: "flex"}}>
                  <Box  width={"50%"}>
                    <AppInput disabled={true} label={content.sharePointSettingSiteURLField} value={sharePointBaseUrl} />
                  </Box>
                  <Box width={"50%"} sx={{pl: 3, fontSize:"0.9rem", fontFamily:"EYInterstate-Bold"}}><strong>{content.sharePointSettingSiteURLFieldDesc}</strong></Box>
              </Box>
          </>
        </AppModal>
    );
}

export default SharePointSettings;