import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import en_translation from "../../localization/en/translation.json";
import MoreMenu from "../moreMenu/moreMenu";
import { MoreMenuOption } from "../moreMenu/moreMenuOptions";
import { AppCategory } from "./models/appCategory";
import { AppClass } from "./models/appClass";
import { MenuActions, MAX_TITLE_WIDTH } from "./AppCardConstants";
import { getIconPath } from "../../utils/helper-utility";
import { IUser } from "../../models/user";
import { WrikeData } from "../../models/IHelp";
import { IMyProjectRights } from "../../models/IUserAccess";
import { EProjectStatuses, UserTypes } from "../../utils/Constants";
import { showPreviewModal } from "../../store/actions/preview-video-actions";
import { AppButton, AppToggleSwitch, AppTooltip, AppIcon } from "../../theme";
import { cross } from "../../theme/icons";
import "./appCard.scss";

interface IProps {
  data: AppClass;
  appCategories: AppCategory[];
  moreMenuEnabled: boolean;
  showDemoBtn: boolean;
  descriptionEnabled: boolean;
  onPublishStatusUpdate?: Function;
  footerEnabled?: boolean;
  moreMenuOption?: MoreMenuOption[];
  handleMoreMenuOptionClick?: Function;
  removeEnabled?: boolean;
  handleRemove?: Function;
  onActiveCard?: Function;
  isActive?: boolean;
  isDemoDisabled?: boolean;
  bundleId?: string;
  onDetailsClick?: Function;
  showPublishStatus?: boolean;
  appStatus?: string;
  isProjectAdmin?: boolean;
  isEyUser?: boolean;
  projectUserName?: string;
  projectEmailAddress?: string;
  showPreviewModal: Function;
  currentUser?: IUser;
  workplanSyncEnabled?: boolean;
  onAppCardMenuclick?: Function;
  myProjectRightsData?: IMyProjectRights;
  onWorkPlanSyncClick?: Function;
  isPortalAdmin?: boolean;
  isToggleSyncApiAvailable: boolean;
  moreMenuSettingsOptionEnabled?: boolean;
}

interface IState {
  showDetails: boolean;
  showTitleTooltip: boolean;
}

class AppCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showDetails: false,
      showTitleTooltip: false,
    };
  }

  getOptions = () => {
    const {
      isProjectAdmin,
      data: { notRetricted, isPublished },
      currentUser,
      workplanSyncEnabled,
      isToggleSyncApiAvailable,
    } = this.props;
    const isInternalUser=currentUser?.userType?.toLowerCase()===
    UserTypes.Internal.toLowerCase();
    
    const options: MoreMenuOption[] = [
      {
        id: "",
        actionName: MenuActions.OPEN,
        title: en_translation.open,
        isDisabled:
          (!isPublished && 
          !isInternalUser) ||
          !notRetricted,
      },
    ];
    if (!isPublished) {
      options.push({
        id: "",
        actionName: MenuActions.PUBLISH,
        title: en_translation.publish, 
        isDisabled: (!isInternalUser || !isProjectAdmin) || !notRetricted,
      });
    } else {
      options.push({
        id: "",
        actionName: MenuActions.UNPUBLISH,
        title: en_translation.unPublish,
        isDisabled: (!isInternalUser || !isProjectAdmin) || !notRetricted,
      });
    }

    if (this.props.moreMenuSettingsOptionEnabled) {
      options.push({
        id: "",
        actionName: MenuActions.SETTINGS,
        title: en_translation.settings,
        isDisabled: (!isInternalUser || !isProjectAdmin) || !notRetricted,
      });
    }

    const isWorkPlanSyncOptionAvailable = this.getWorkPlanSyncOptionStatus();
    if (isWorkPlanSyncOptionAvailable && isToggleSyncApiAvailable) {
      options.push({
        id: "",
        actionName: MenuActions.WORKPLAN_SYNC,
        title: en_translation.work_plan_sync,
        isDisabled: !workplanSyncEnabled,
      });
    }

    return this.props.moreMenuOption || options;
  };

  handleToggleSwitch = ({ target: { checked } }) => {
    const { onActiveCard, data } = this.props;

    if (onActiveCard) {
      onActiveCard(data, checked);
    }
  };

  handleCrossIconClick = (event) => {
    const {
      data: { id },
      handleRemove,
    } = this.props;
    if (handleRemove) {
      handleRemove(id);
    }
    event.stopPropagation();
  };

  handleMoreMenuOptionClick = (actionName: string) => {
    const {
      isProjectAdmin,
      isEyUser,
      data: { notRetricted, isAppLoaded },
      onWorkPlanSyncClick,
    } = this.props;

    if (notRetricted && isAppLoaded) {
      if (
        !isEyUser &&
        !isProjectAdmin &&
        (actionName === MenuActions.PUBLISH ||
          actionName === MenuActions.UNPUBLISH)
      ) {
        return;
      }

      if (
        (actionName === MenuActions.PUBLISH ||
          actionName === MenuActions.UNPUBLISH) &&
        this.props.onPublishStatusUpdate
      ) {
        this.props.onPublishStatusUpdate(this.props.data.id);
      }

      this.handleMoreMenuOption(actionName);
      if (actionName === MenuActions.WORKPLAN_SYNC) {
        onWorkPlanSyncClick && onWorkPlanSyncClick();
      }
    }
  };

  handleMoreMenuOption = (actionName: string) => {
    const {
      data: { isPublished },
      currentUser,
    } = this.props;

    if (
      (isPublished ||
        currentUser?.userType?.toLowerCase()!== UserTypes.External.toLowerCase()) &&
      this.props.handleMoreMenuOptionClick
    ) {
      this.props.handleMoreMenuOptionClick(
        actionName,
        this.props.data.id,
        this.props.data.appPlatformClassId
      );
    }
  };

  handleDemoClick = () => {
    const {
      data: { demoSiteURL },
      showPreviewModal,
    } = this.props;
    if (demoSiteURL && _.includes(demoSiteURL, ".pdf")) {
      window.open(demoSiteURL, "_blank");
    } else if (demoSiteURL) {
      showPreviewModal(demoSiteURL);
    }
  };

  handleShowDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  checkEllipsis = ({ target }) => {
    const ctx = document.createElement("canvas").getContext("2d");

    if (!ctx) {
      return;
    }
    const text = ctx.measureText(target.innerText);
    this.setState({ showTitleTooltip: text.width > MAX_TITLE_WIDTH });
  };

  handleMenuClick = (isExpanded) => {
    const { data, onAppCardMenuclick } = this.props;

    if (!isExpanded || data.key !== WrikeData.KEY) {
      return;
    }

    const isWorkPlanSyncOptionAvailable = this.getWorkPlanSyncOptionStatus();

    if (isWorkPlanSyncOptionAvailable) {
      onAppCardMenuclick && onAppCardMenuclick();
    }
  };

  getWorkPlanSyncOptionStatus = () => {
    const { myProjectRightsData, isPortalAdmin, data } = this.props;

    if (
      !isPortalAdmin ||
      data.key !== WrikeData.KEY ||
      !myProjectRightsData?.users
    ) {
      return false;
    }

    const wrikePlatform = myProjectRightsData.users[0].appPlatforms?.find((p) =>
      p.apps.find((a) => a === WrikeData.KEY)
    );

    if (!wrikePlatform) {
      return false;
    }
    // @ts-ignore
    return !!wrikePlatform.roles.find((r: Array[]) =>
      r.includes(WrikeData.EY_SUPER_USER_ROLE)
    );
  };

  getCardBodyClass = (applicationStatus) => {
    const {
      moreMenuEnabled,
      data: { isPublished, notRetricted, isAppLoaded },
      removeEnabled,
      currentUser,
    } = this.props;
    return `app-card-body ${removeEnabled ? "app-card-body-cross" : ""}${
      moreMenuEnabled &&
      (applicationStatus === EProjectStatuses.PND ||
        applicationStatus === EProjectStatuses.DRFT)
        ? "pending-state"
        : ""
    } ${
      notRetricted &&
      applicationStatus !== EProjectStatuses.PND &&
      applicationStatus !== EProjectStatuses.DRFT &&
      (isPublished ||
        currentUser?.userType?.toLowerCase() !== UserTypes.External.toLowerCase()) 
        ? "not-pending-state"
        : ""
    }
                    ${isAppLoaded ? "" : "skeleton-loader"}`;
  };

  getApplicationStatus = (appStatus) => {
    if (
      appStatus === EProjectStatuses.PND ||
      appStatus === EProjectStatuses.EROR
    ) {
      return EProjectStatuses.PND;
    } else if (appStatus === EProjectStatuses.DRFT) {
      return EProjectStatuses.DRFT;
    } else if (appStatus === EProjectStatuses.RDY) {
      return EProjectStatuses.RDY;
    } else {
      return EProjectStatuses.PND;
    }
  };

  renderStatusPill = (applicationStatus) => {
    const {
      showPublishStatus,
      moreMenuEnabled,
      data: { isPublished, notRetricted, isAppLoaded },
      projectUserName,
      projectEmailAddress,
    } = this.props;

    return (
      <>
        {notRetricted &&
          moreMenuEnabled &&
          applicationStatus !== EProjectStatuses.DRFT &&
          applicationStatus !== EProjectStatuses.PND &&
          showPublishStatus &&
          !isPublished && (
            <span className="data-location-tooltip">
              <AppTooltip
                placement="top"
                title={`${en_translation.unpublished_pill_msg1} ${projectUserName} (${projectEmailAddress}) ${en_translation.unpublished_pill_msg2}`}
              >
                <div className="app-status-pill">
                  {en_translation.unPublished}
                </div>
              </AppTooltip>
            </span>
          )}
        {isAppLoaded &&
          moreMenuEnabled &&
          (applicationStatus === EProjectStatuses.PND ||
            applicationStatus === EProjectStatuses.DRFT) && (
            <span
              className={`app-status-pending ${
                applicationStatus === EProjectStatuses.DRFT
                  ? "app-status-draft"
                  : ""
              }`}
            >
              {applicationStatus === EProjectStatuses.DRFT &&
                en_translation.draft}
              {applicationStatus === EProjectStatuses.PND && (
                <span className="data-location-tooltip">
                  <AppTooltip
                    placement="top"
                    title={`${en_translation.pending_pill_msg1} ${projectUserName} (${projectEmailAddress}) ${en_translation.pending_pill_msg2}`}
                  >
                    <div>{en_translation.pending}</div>
                  </AppTooltip>
                </span>
              )}
            </span>
          )}
        {!notRetricted &&
          isAppLoaded &&
          applicationStatus !== EProjectStatuses.PND &&
          applicationStatus !== EProjectStatuses.DRFT && (
            <span className="data-location-tooltip">
              <AppTooltip
                placement="top"
                title={`${en_translation.restricted_pill_msg1} ${projectUserName} (${projectEmailAddress}) ${en_translation.restricted_pill_msg2}`}
              >
                <div className="app-status-pill">
                  {en_translation.restricted}
                </div>
              </AppTooltip>
            </span>
          )}
      </>
    );
  };

  renderCardContent = (iconPath, iconBackgroundColor, appCategory) => {
    const {
      moreMenuEnabled,
      data: { name },
      removeEnabled,
    } = this.props;

    return (
      <div className="content">
        <div className="icon" style={iconBackgroundColor}>
          {iconPath && (
            <img src={iconPath} className="img-app-card" alt="icon" />
          )}
        </div>
        <div
          className={`content-data ${
            removeEnabled ? "content-data-cross" : ""
          }`}
        >
          <AppTooltip
            hide={!this.state.showTitleTooltip}
            title={name}
            placement="top"
          >
            <div
              className={`app-title ${
                moreMenuEnabled || removeEnabled ? "add-spacing" : ""
              }`}
              onMouseEnter={this.checkEllipsis}
            >
              {name}
            </div>
          </AppTooltip>
          <div className="app-category">{appCategory?.name}</div>
        </div>
      </div>
    );
  };

  renderCardHeader = (applicationStatus) => {
    const { moreMenuEnabled, removeEnabled } = this.props;

    return (
      <div className="top-section">
        {applicationStatus !== EProjectStatuses.DRFT &&
          applicationStatus !== EProjectStatuses.PND &&
          moreMenuEnabled && (
            <div className="more-menu">
              <MoreMenu
                controlId="more-menu-apps"
                optionClickFn={this.handleMoreMenuOptionClick}
                options={this.getOptions()}
                onMenuClick={this.handleMenuClick}
                stopEventBubbling
              />
            </div>
          )}
        {removeEnabled && (
          <div className="more-menu cross-menu">
            <AppIcon
              onClick={(event) => this.handleCrossIconClick(event)}
              icon={cross}
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      isDemoDisabled,
      descriptionEnabled,
      isActive,
      footerEnabled,
      onDetailsClick,
      data,
      data: { appCategoryId, description, id, demoSiteURL },
      appStatus,
    } = this.props;
    const appCategory = this.props.appCategories.find(
      (f) => f.id === appCategoryId
    );
    const iconPath = getIconPath(appCategory);
    const iconBackgroundColor = { background: appCategory?.colorCode };
    const { showDetails } = this.state;
    const applicationStatus = this.getApplicationStatus(appStatus);
    return (
      <div className="app-card">
        <div
          className={this.getCardBodyClass(applicationStatus)}
          onClick={() => this.handleMoreMenuOptionClick("open")}
        >
          {this.renderCardHeader(applicationStatus)}
          {this.renderCardContent(iconPath, iconBackgroundColor, appCategory)}
          {this.renderStatusPill(applicationStatus)}

          {(footerEnabled && (
            <div
              className={`footer-btns ${
                !demoSiteURL || demoSiteURL.length === 0 ? "preview-btn" : ""
              }`}
            >
              {demoSiteURL !== null && demoSiteURL.length !== 0 && (
                <AppButton
                  disabled={isDemoDisabled}
                  onClick={this.handleDemoClick}
                  color="secondary"
                >
                  {en_translation.preview}
                </AppButton>
              )}
              <section className="active-section">
                <AppToggleSwitch
                  checked={isActive}
                  onChange={this.handleToggleSwitch}
                  id={`${this.props.bundleId}---${id}`}
                />
              </section>
            </div>
          )) ||
            null}
        </div>
        {descriptionEnabled && (
          <div className="app-description">
            {!onDetailsClick && (
              <div className="content">
                {!showDetails
                  ? _.trim(description.substring(0, 55))
                  : description}
                ...
                <span
                  className="show-details-btn"
                  onClick={this.handleShowDetails}
                >
                  {!showDetails
                    ? en_translation.show_more
                    : en_translation.show_less}
                </span>
              </div>
            )}
            {onDetailsClick && (
              <div className="content details">
                {description.substring(0, 55)}...
                <AppButton onClick={() => onDetailsClick(data)} variant="text">
                  {en_translation.details}
                </AppButton>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
const mapStoreToProps = ({ projects }) => {
  return {
    isToggleSyncApiAvailable: projects.isToggleSyncApiAvailable,
  };
};

const mapDispatchToProps = {
  showPreviewModal,
};

export default connect(mapStoreToProps, mapDispatchToProps)(AppCard);
