export const ProjectStatus = {
    DRAFT: "draft"
};

export const MenuActions = {
    DELETE: "Delete",
    LOCK: "Lock",
    MARK: "Mark as close"
};

export const VISIBLE_PROJECT_TITLE_WIDTH = 138;
