import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import {
  deleteDraftProjectRequest,
  flushDeletedProject,
  flushUpdatedBundles,
  flushCreatedBundle,
  closeProjectRequest,
  flushCloseProject,
} from "../../store/actions/create-project-actions";
import { getProjectStatusAction } from "../../store/actions/project-status-actions";
import { getProjectRegions } from "../../store/actions/data-locations-actions";
import { getSectorsAction } from "../../store/actions/sectors-actions";
import { getProjectCategoriesAction } from "../../store/actions/project-categories-actions";
import ProjectCard from "../../components/projectCard/projectCard";
import AppNotification from "../../components/appNotification/appNotification";
import en_translation from "../../localization/en/translation.json";
import userImage from "../../assets/images/user_image.png";
import { Project } from "../../components/createProject/models/project";
import { IUser, IAccessRight } from "../../models/user";
import { lookupModel } from "../createProject/lookupModel";
import { getMe } from "../../store/actions/user-actions";
import {
  checkCanCreateProject,
  isProjectAdmin,
} from "../../utils/helper-utility";
import { ISSPPlatformRole } from "../../models/IRoles";
import { EProjectStatuses } from "../../utils/Constants";
import {
  getProjectIcons,
  getAppPlatforms,
} from "../../store/actions/meta-actions";
import { IProjectIcon } from "../../models/IProjectIcon";
import * as ProjectIconConstant from "../../components/projectIcon/constants";
import {
  USER_ACTIONS,
  gridAppPlatformKeys,
  UserType,
} from "../manageUsers/listUsers/listUserConstants";
import { IDefaultProject } from "../../models/IProjectDefaultRoles";
import { AppPlatformClass } from "../../components/appCard/models/appPlatformClass";
import { enrollToProjectRequest } from "../../store/actions/default-project-actions";
import { AppButton, AppTooltip } from "../../theme";
import "./home.scss";
import { FeedbackType } from "../../components/CloseProjectPopup/CloseProjectPopup";
import { CrashBoundary } from "../../components/CrashBoundary";
import { getProjectsSummary } from "../../store/actions/project-actions";

type AppProps = RouteComponentProps<any> & {
  deletedProject: { success: boolean; message: string };
  onDeleteDraft: () => void;
  deleteDraftProject: any;
  flushDeletedProject: () => void;
  flushUpdatedBundles: () => void;
  flushCreatedBundle: () => void;
  getProjectStatus(): void;
  getProjectList: Function;
  projectList: Project[];
  getCurrentUser: () => any;
  currentUser: IUser;
  closeProject: Function;
  closeProjectSuccess: { data: boolean; success: boolean };
  accessRight: IAccessRight;
  sspPlatformRoles: ISSPPlatformRole[];
  projectStatus: lookupModel[];
  projectRegions: lookupModel[];
  projectCategories: lookupModel[];
  sectors: lookupModel[];
  flushCloseProject: () => void;
  getProjectRegions: Function;
  getSectors: Function;
  getProjectIconRequest: Function;
  getProjectCategories: Function;
  projectIcons: IProjectIcon[];
  defaultProject: IDefaultProject;
  getAppPlatformsRequest: Function;
  appPlatforms: AppPlatformClass[];
  enrollToProjectRequestAction: Function;
  isCreateProjectDisabled: boolean;
  isMaintanenceFlagLoaded: boolean;
};
interface IState {
  isMaintanenceLoading: boolean;
}

class Home extends Component<AppProps, IState> {
  deleteDraftName = "";
  closeProjectName = "";

  constructor(props: AppProps) {
    super(props);

    this.state = {
      isMaintanenceLoading: true,
    };
  }

  componentDidMount() {
    const {
      appPlatforms,
      getAppPlatformsRequest,
      projectIcons,
      projectStatus,
      getProjectStatus,
      getProjectList,
      getProjectIconRequest,
    } = this.props;
    getProjectList();
    !projectStatus.length && getProjectStatus();
    !appPlatforms.length && getAppPlatformsRequest();
    !projectIcons.length && getProjectIconRequest();
  }

  componentDidUpdate(prevProps) {
    const {
      appPlatforms,
      currentUser,
      deletedProject,
      flushDeletedProject,
      getProjectList,
      closeProjectSuccess,
      flushCloseProject,
      defaultProject,
      isMaintanenceFlagLoaded,
    } = this.props;

    if (prevProps.deletedProject !== deletedProject && deletedProject.success) {
      getProjectList();
      setTimeout(flushDeletedProject, 3000);
    }

    if (
      prevProps.closeProjectSuccess !== closeProjectSuccess &&
      closeProjectSuccess.success
    ) {
      getProjectList();
      setTimeout(flushCloseProject, 3000);
    }

    if (prevProps.defaultProject !== defaultProject) {
      this.enrollForDemoProjectRoles(
        prevProps,
        defaultProject,
        currentUser,
        appPlatforms
      );
    }
    if (isMaintanenceFlagLoaded && this.state.isMaintanenceLoading) {
      this.setState({ isMaintanenceLoading: false });
    }
  }

  enrollForDemoProjectRoles = (
    prevProps,
    defaultProject,
    currentUser,
    appPlatforms
  ) => {
    const { enrollToProjectRequestAction, projectList } = this.props;

    const {
      defaultRoles = null,
      loading = false,
      error = false,
      projectId = null,
      enrollProjectId = null,
    } = defaultProject;

    if (
      defaultRoles &&
      prevProps.defaultProject.defaultRoles !== defaultRoles &&
      projectId &&
      prevProps.defaultProject.loading &&
      !loading &&
      !error &&
      !enrollProjectId
    ) {
      // we got the default roles.. let enroll the user

      const sspDefaultRoles = defaultRoles.defaultUserRoles?.find(
        (item) => item.key.toLowerCase() === gridAppPlatformKeys.SSP
      );
      const appPlatformRoles = defaultRoles.defaultUserRoles?.filter(
        (item) => item.key.toLowerCase() !== gridAppPlatformKeys.SSP
      );
      const project = projectList.find((item) => item.id === projectId);

      let appPlatformDefaultRoles: any = [];
      appPlatformRoles?.forEach((item) => {
        const appPlatform = appPlatforms.find(
          (appPlt) => appPlt.key === item.key
        );

        if (appPlatform) {
          const apps =
            project?.appDetails
              ?.filter((app) => app.appPlatformId === appPlatform.id)
              .map((app) => app.appClassName) || [];
          appPlatformDefaultRoles.push({
            appPlatformId: appPlatform.id || "",
            appPlatformName: appPlatform.name,
            oldRoles: [],
            apps: apps,
            newRoles: item.roles.map((role) => role.role),
          });
        }
      });

      let userPayload = {
        userId: currentUser.id,
        mail: currentUser.mail,
        appPlatforms: appPlatformDefaultRoles,
        action: USER_ACTIONS.UPDATE,
        [gridAppPlatformKeys.SSP]: {
          oldRoles: [],
          newRoles: sspDefaultRoles?.roles.map((item) => item.role) || [],
        },
      };

      let payload = {
        projectId,
        users: [
          {
            ...userPayload,
          },
        ],
      };

      enrollToProjectRequestAction({
        projectId,
        data: payload,
      });
    }
  };

  deleteDraftProjectID = (id: string, name: string) => {
    this.deleteDraftName = name;
    this.props.deleteDraftProject(id);
  };

  closeProjectID = (id: string, name: string, feedback: FeedbackType) => {
    this.closeProjectName = name;
    this.props.closeProject({ id, feedback });
  };

  handleCreateProject = () => {
    const {
      history,
      flushUpdatedBundles,
      flushCreatedBundle,
      isCreateProjectDisabled,
    } = this.props;
    if (!isCreateProjectDisabled) {
      flushUpdatedBundles();
      flushCreatedBundle();
      history.push("/create");
    }
  };

  checkProjectAdmin(record) {
    const { accessRight, sspPlatformRoles } = this.props;
    return isProjectAdmin(record?.id || "", accessRight, sspPlatformRoles);
  }

  checkAllPlatformStatus = (project: Project): boolean => {
    const { projectStatus } = this.props;
    let showClosebtn = true;

    if (project.appPlatformDetails) {
      for (const platform of project.appPlatformDetails) {
        if (
          platform.statusId !==
            projectStatus.find((status) => status.key === EProjectStatuses.EROR)
              ?.id &&
          platform.statusId !==
            projectStatus.find((status) => status.key === EProjectStatuses.RDY)
              ?.id
        ) {
          showClosebtn = false;
          break;
        }
      }
    }
    return showClosebtn;
  };

  checkInfrapipelineStatus = (project: Project): boolean => {
    const { projectStatus } = this.props;
    return (
      project.infrastructureStatusId ===
        projectStatus.find((status) => status.key === EProjectStatuses.RDY)
          ?.id ||
      project.infrastructureStatusId ===
        projectStatus.find((status) => status.key === EProjectStatuses.EROR)?.id
    );
  };

  render() {
    const {
      deletedProject,
      projectList = [],
      flushDeletedProject,
      currentUser,
      accessRight,
      closeProjectSuccess,
      flushCloseProject,
      projectIcons,
      defaultProject,
      isCreateProjectDisabled,
    } = this.props;
    const { isMaintanenceLoading } = this.state;
    const canCreateProject = checkCanCreateProject(accessRight);
    const filteredProjects = projectList.filter((item) => {
      const projectAccess = accessRight.projects?.find(
        (project) => project.projectId === item.id
      );
      return (
        (projectAccess && !projectAccess.deleted) ||
        (item.isDemoProject &&
          currentUser?.userType?.toLocaleLowerCase() === UserType?.Internal)
      );
    });
    return (
      <CrashBoundary>
        <div className="home">
          <div className="head-section">
            <div className="app-row details-row">
              <section className="app-col-xs-12 app-col-lg-4 app-col-xl-4">
                {currentUser.id && (
                  <div className="image-cropper">
                    {currentUser?.photo ? (
                      <img
                        src={"data:image/png;base64," + currentUser?.photo}
                        className="rounded"
                        alt={`${currentUser?.givenName} ${currentUser?.surname}`}
                        title={`${currentUser?.givenName} ${currentUser?.surname}`}
                      />
                    ) : (
                      <img src={userImage} className="rounded" alt="user" />
                    )}
                  </div>
                )}
                {currentUser.id && (
                  <h3 className="welcome">{en_translation.home_welcome_msg}</h3>
                )}
                <h2 className="welcome user-name">
                  {currentUser?.givenName}&nbsp;{currentUser?.surname}
                </h2>
                {currentUser.id && canCreateProject && (
                  <div className="btn-align app-row">
                    <div className="project_settings btn app-col-xs-12 app-col-lg-6 app-col-xl-6">
                      <AppTooltip
                        placement="bottom"
                        hide={!isCreateProjectDisabled}
                        title={
                          en_translation.admin_portal_maintanence_flag_enabled_create_project_disabled_tooltip
                        }
                      >
                        <AppButton
                          onClick={this.handleCreateProject}
                          size="large"
                          className={
                            isMaintanenceLoading
                              ? "skeleton-loader"
                              : isCreateProjectDisabled
                              ? "manage-user-btn-disabled"
                              : ""
                          }
                        >
                          {en_translation.home_btn_create_project}
                        </AppButton>
                      </AppTooltip>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
          <div className="tiles-section">
            {filteredProjects.map((project: Project) => {
              const isProjectAdmin = this.checkProjectAdmin(project);
              const showCloseBtn: boolean =
                this.checkAllPlatformStatus(project) &&
                this.checkInfrapipelineStatus(project);
              const icon = projectIcons.find(
                (item) => project.iconId && item.id === project.iconId
              );

              return (
                <ProjectCard
                  key={project.id}
                  onDeleteDraft={this.deleteDraftProjectID}
                  onCloseProject={this.closeProjectID}
                  record={project}
                  isProjectAdmin={isProjectAdmin}
                  accessRight={accessRight}
                  showCloseBtn={showCloseBtn}
                  regionName={project.regionName || ""}
                  categoryName={project.projectCategoryName || ""}
                  sectorName={project.sectorName || ""}
                  icon={
                    icon ||
                    projectIcons.find(
                      (item) => item.key === ProjectIconConstant.SOCPUB
                    )
                  }
                  redirectToProjectDetails={
                    defaultProject.redirectToProject &&
                    defaultProject.enrollProjectId === project.id
                  }
                />
              );
            })}
          </div>
          {deletedProject.success && (
            <AppNotification
              message={
                deletedProject.message ||
                `${this.deleteDraftName} ${en_translation.project_deleted}`
              }
              onCloseNotifcation={flushDeletedProject}
              variant="success"
            />
          )}
          {closeProjectSuccess.success && (
            <AppNotification
              message={`${this.closeProjectName} ${en_translation.close_project_message}`}
              variant="success"
              onCloseNotifcation={flushCloseProject}
            />
          )}
        </div>
      </CrashBoundary>
    );
  }
}

const mapStateToProps = ({
  createProjectReducer,
  projects,
  meReducer,
  meta,
  projectStatusReducer,
  dataLocationsReducer,
  projectCategoriesReducer,
  sectorsReducer,
  defaultProject,
  adminMaintenance,
}) => {
  return {
    deletedProject: createProjectReducer.deletedProject,
    projectList: projects.list,
    currentUser: meReducer.currentUser,
    closeProjectSuccess: createProjectReducer.closeProject,
    accessRight: meReducer.accessRight,
    sspPlatformRoles: meta.userRoles,
    projectStatus: meta.projectStatuses || projectStatusReducer.projectStatus,
    projectRegions: dataLocationsReducer.projectRegions,
    projectCategories: projectCategoriesReducer.projectCategories,
    sectors: sectorsReducer.sectors,
    projectIcons: meta.icons,
    appPlatforms: meta.appPlatforms,
    defaultProject,
    isCreateProjectDisabled: adminMaintenance.isCreateProjectDisabled,
    isMaintanenceFlagLoaded: adminMaintenance.isMaintanenceFlagLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteDraftProject: (payload) => {
      dispatch(deleteDraftProjectRequest(payload));
    },
    flushDeletedProject: () => {
      dispatch(flushDeletedProject());
    },
    getProjectList: () => {
      dispatch(getProjectsSummary());
    },
    getCurrentUser: () => {
      dispatch(getMe());
    },
    flushUpdatedBundles: () => {
      dispatch(flushUpdatedBundles());
    },
    flushCreatedBundle: () => {
      dispatch(flushCreatedBundle());
    },
    closeProject: (payload) => {
      dispatch(closeProjectRequest(payload));
    },
    flushCloseProject: () => {
      dispatch(flushCloseProject());
    },
    getProjectStatus: () => {
      dispatch(getProjectStatusAction());
    },
    getProjectRegions: () => {
      dispatch(getProjectRegions());
    },
    getSectors: () => {
      dispatch(getSectorsAction());
    },
    getProjectCategories: () => {
      dispatch(getProjectCategoriesAction());
    },
    getProjectIconRequest: () => {
      dispatch(getProjectIcons());
    },
    getAppPlatformsRequest: () => {
      dispatch(getAppPlatforms());
    },
    enrollToProjectRequestAction: (payload) => {
      dispatch(enrollToProjectRequest(payload));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
